import React, { useEffect } from 'react';
import './Merch.css';

function Merch({ merchBg, btnContent, setActivePage }) {
    return (
        <div id="merch" className="app_container merch_sec position-relative w-100">
            <a href='https://chameleon-collective.square.site/' target='_blank' className="merch_btn position-absolute">
                {btnContent}
            </a>
        </div>
    )
}

export default Merch;