import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux';
import {
    findAllForDressingRoom,
    findAllTrait,
    getAllOverlay,
    getAllOverlayType,
    getAllPreviewNftList,
    getAllWishList,
    getOverlayByType
} from '../../redux/actions/dressingCham';
import { getTokenExcluded } from '../../redux/actions/potion';
import Accordion from 'react-bootstrap/Accordion';
import DressingPreviewSec from '../../components/DressingPreviewSec/DressingPreviewSec';
import {toast} from 'react-toastify';
import Footer from '../../components/Footer/Footer';
import {useHistory} from 'react-router-dom/cjs/react-router-dom.min';
import AddNewImage from '../../assets/Img/add-image.png';
import './DressingRoom.css'
import { FaEthereum, FaTimes } from 'react-icons/fa';
import { MdAdd } from 'react-icons/md';
import { setWalletAddress, setWalletConnected } from '../../redux/actions/miscellaneous';
import { useAccount } from 'wagmi';

function DressingRoom({ setAccountNo, setUserId, setActivePage, userId, }) {
    const [profilePic] = useState('img/profile-pic.png');
    const [BgImgUrl] = useState('img/bg-1.jpg');
    const [activeAvailableTraitAccordian, setActiveAvailableTraitAccordian] = useState('');
    const [localAllOverlay, setLocalAllOverlay] = useState([]);
    const [localAllTraits, setLocalAllTraits] = useState([]);
    const [localAllTraitsForDressingRoom, setLocalAllTraitsForDressingRoom] = useState([]);
    const [localChams, setLocalChams] = useState([]);
    const [selectedChamsForDressing, setSelectedChamsForDressing] = useState([]);
    const [selectedOverlaysForDressing, setSelectedOverlaysForDressing] = useState([]);
    const [selectedAvailableTraitsForDressing, setSelectedAvailableTraitsForDressing] = useState([]);
    const [dressingPreview, setDressingPreview] = useState([]);
    const [nftType, setNftType] = useState('ORIGINAL');
    const [showZoomNftPopup, setShowZoomNftPopup] = useState(false)
    const [showZoomNftPopupId, setShowZoomNftPopupId] = useState('')
    const [showZoomTraitPopup, setShowZoomTraitPopup] = useState(false)
    const [showZoomTraitPopupImg, setShowZoomTraitPopupImg] = useState('')

    const { address, isConnected } = useAccount()

    const history = useHistory();
    const dispatch = useDispatch();

    const user = useSelector(state => state.users.user);
    const getTokenExcludedStatus = useSelector(state => state.potion.getTokenExcludedStatus);
    // const allChamOverlayTypes = useSelector(state => state.dressingCham.allChamOverlayTypes);
    const chameleonNft = useSelector(state => state.potion.chameleonNft);
    // const allPreviewNft = useSelector(state => state.dressingCham.allPreviewNft);
    const allOverlays = useSelector(state => state.dressingCham.allOverlays);
    const allTraits = useSelector(state => state.dressingCham.allTraits);
    const allTraitsForDressingRoom = useSelector(state => state.dressingCham.allTraitsForDressingRoom);
  
    useEffect(() => {
        dispatch(getTokenExcluded())
    }, []);
  
    useEffect(() => {
        if(getTokenExcludedStatus == 401) {
            history.push('/signup')
        }else if(getTokenExcludedStatus == 200){
            dispatch(getAllOverlayType())
            dispatch(getAllOverlay())
            // dispatch(getAllWishList())
            dispatch(findAllTrait())
            dispatch(findAllForDressingRoom())
        }else
            return;
    }, [getTokenExcludedStatus]);

    // useEffect(() => {
    //     // dispatch(getAllPreviewNftList())
        
    // }, []);
  
    // useEffect(() => {
    //     dispatch(getOverlayByType({type: activeOverlayAccordian}))
    // }, [activeOverlayAccordian]);
    
    useEffect(() => {
        setLocalAllOverlay(allOverlays)
    }, [allOverlays]);
  
    useEffect(() => {
        setLocalChams(chameleonNft)
    }, [chameleonNft]);
  
    useEffect(() => {
        setLocalAllTraits(allTraits)
    }, [allTraits]);
  
    useEffect(() => {
        setLocalAllTraitsForDressingRoom(allTraitsForDressingRoom)
    }, [allTraitsForDressingRoom]);

    // method for selecting form local state of local overlays
    const selectOverlaysForDressing = (e) => {
        const {name, checked} = e.target;
        if(selectedChamsForDressing[0]){
            let tempLocalAllOverlay = localAllOverlay.map((overlay) => 
                overlay.id == name ? {...overlay, isChecked: checked} : overlay 
            );
            setLocalAllOverlay(tempLocalAllOverlay);
            // logic for setDressingPreview array
            let tempOverlaySelectedObj = tempLocalAllOverlay?.filter((overlay) => { return overlay.id == name })[0]
            if(checked) {
                let tempOverlayArr = selectedOverlaysForDressing?.filter((item) => { return item.overlayName === tempOverlaySelectedObj.type })
                let tempOverlayIndex = selectedOverlaysForDressing?.findIndex((item) =>  item.overlayName === tempOverlaySelectedObj.type )
                if(tempOverlayArr.length == 0) {
                    let tempOverlay = {"overlayName": tempOverlaySelectedObj.type, "selected": [tempOverlaySelectedObj]}
                    let tempSelectedOverlaysForDressing = selectedOverlaysForDressing;
                    tempSelectedOverlaysForDressing.push(tempOverlay);
                    setSelectedOverlaysForDressing(tempSelectedOverlaysForDressing)
                }else  {
                    let tempOverlay = tempOverlayArr[0];
                    let selected = tempOverlay.selected;
                    selected.push(tempOverlaySelectedObj);
                    tempOverlay["selected"] = selected;
                    let tempSelectedOverlaysForDressing = selectedOverlaysForDressing;
                    tempSelectedOverlaysForDressing[tempOverlayIndex] = tempOverlay 
                    setSelectedOverlaysForDressing(tempSelectedOverlaysForDressing)
                }
            }else {
                let tempOverlayArr = selectedOverlaysForDressing?.filter((item) => { return item.overlayName === tempOverlaySelectedObj.type })
                let tempOverlayIndex = selectedOverlaysForDressing?.findIndex((item) =>  item.overlayName === tempOverlaySelectedObj.type )
                if(tempOverlayArr.length > 0) {
                    let tempOverlay = tempOverlayArr[0];
                    let selected = tempOverlay.selected;
                    tempOverlay["selected"] = selected?.filter((item) => {return item.id != tempOverlaySelectedObj.id});
                    let tempSelectedOverlaysForDressing = selectedOverlaysForDressing;
                    tempSelectedOverlaysForDressing[tempOverlayIndex] = tempOverlay 
                    setSelectedOverlaysForDressing(tempSelectedOverlaysForDressing)
                }
            }
            let finalArr = [];
            if (selectedOverlaysForDressing.length > 1){
                for (var i = 1; i < selectedOverlaysForDressing.length; i++){
                    if (i == 1){
                        finalArr = findCart(selectedOverlaysForDressing[i-1].selected, selectedOverlaysForDressing[i].selected,selectedOverlaysForDressing[i-1].selected.length, selectedOverlaysForDressing[i].selected.length, true);
                    }else if((i) < selectedOverlaysForDressing.length){
                        let tempFinalArr = JSON.parse(JSON.stringify(finalArr));
                        finalArr = findCart(tempFinalArr,selectedOverlaysForDressing[i].selected,tempFinalArr.length,selectedOverlaysForDressing[i].selected.length,false)
                    }
                }
            }else if (selectedOverlaysForDressing.length == 1){
                for(var i = 0; i < selectedOverlaysForDressing[0].selected.length; i++){
                    finalArr.push([selectedOverlaysForDressing[0].selected[i]])
                }
                // finalArr = JSON.parse(JSON.stringify(selectedOverlaysForDressing[0].selected));
            }

            // console.log('finalArr :', finalArr)

            let tempDressingPreview = [];
            for (var i = 0; i < finalArr.length; i++) {
                let preview = { nft: selectedChamsForDressing[0], overlays: finalArr[i] }
                tempDressingPreview.push(preview)
                // // console.log('preview :', preview)
            }
            // console.log('tempDressingPreview :', tempDressingPreview)

            setDressingPreview(tempDressingPreview);
        }else {
            toast.warning( "Please select a chameleon to proceed" , {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            },);
        }
        
    }

    // method for selecting form local state of local all available traits
    const selectAvailableTraitsForDressing = (e) => {
        const {name, checked} = e.target;
        if(selectedChamsForDressing[0]) {
            // Start validation of single selection if previous is selected
            if (checked) {
                let tempOverlaySelectedObjCheck = localAllTraitsForDressingRoom?.filter((overlay) => {
                    return overlay.chamTraitTypeId == name
                })[0]
                let tempSelectedAvailableTraitsForDressing2 = JSON.parse(JSON.stringify(selectedAvailableTraitsForDressing));
                let tempOverlayArrtemp = tempSelectedAvailableTraitsForDressing2?.filter((item) => {
                    return item.overlayName === tempOverlaySelectedObjCheck.trait
                })
                if (tempOverlayArrtemp.length > 0) {
                    for (let i = 0; i < tempSelectedAvailableTraitsForDressing2.length; i++) {
                        let temp = tempSelectedAvailableTraitsForDressing2[i];
                        if (temp.overlayName != tempOverlaySelectedObjCheck.trait && temp.selected.length > 1) {
                            toast.warning("Please choose ONLY 1 " + temp.overlayName + " to proceed", {
                                position: "bottom-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: false,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            },);
                            return;
                        }
                    }
                }
            }
            // END
            let tempLocalAllTraitsForDressingRoom = localAllTraitsForDressingRoom.map((overlay) => 
                overlay.chamTraitTypeId == name ? {...overlay, isChecked: checked} : overlay 
            );
            setLocalAllTraitsForDressingRoom(tempLocalAllTraitsForDressingRoom);
            // logic for setDressingPreview array
            let tempOverlaySelectedObj = tempLocalAllTraitsForDressingRoom?.filter((overlay) => { return overlay.chamTraitTypeId == name })[0]
            let tempSelectedAvailableTraitsForDressing = JSON.parse(JSON.stringify(selectedAvailableTraitsForDressing));

            if(checked) {
                let tempOverlayArr = tempSelectedAvailableTraitsForDressing?.filter((item) => { return item.overlayName === tempOverlaySelectedObj.trait })
                let tempOverlayIndex = tempSelectedAvailableTraitsForDressing?.findIndex((item) =>  item.overlayName === tempOverlaySelectedObj.trait )
                if(tempOverlayArr.length == 0) {
                    let tempOverlay = {"overlayName": tempOverlaySelectedObj.trait, "selected": [tempOverlaySelectedObj]}
                    tempSelectedAvailableTraitsForDressing.push(tempOverlay);
                    setSelectedAvailableTraitsForDressing(tempSelectedAvailableTraitsForDressing)
                }else  {
                    let tempOverlay = tempOverlayArr[0];
                    let selected = tempOverlay.selected;
                    selected.push(tempOverlaySelectedObj);
                    tempOverlay["selected"] = selected;
                    tempSelectedAvailableTraitsForDressing[tempOverlayIndex] = tempOverlay 
                    setSelectedAvailableTraitsForDressing(tempSelectedAvailableTraitsForDressing)
                }
            }else {
                let tempOverlayArr = tempSelectedAvailableTraitsForDressing?.filter((item) => { return item.overlayName === tempOverlaySelectedObj.trait })
                let tempOverlayIndex = tempSelectedAvailableTraitsForDressing?.findIndex((item) =>  item.overlayName === tempOverlaySelectedObj.trait )
                if(tempOverlayArr.length > 0) {
                    let tempOverlay = tempOverlayArr[0];
                    let selected = tempOverlay.selected;
                    tempOverlay["selected"] = selected?.filter((item) => {return item.chamTraitTypeId != tempOverlaySelectedObj.chamTraitTypeId});
                    if (tempOverlay["selected"].length > 0){
                        tempSelectedAvailableTraitsForDressing[tempOverlayIndex] = tempOverlay
                    }else{
                        tempSelectedAvailableTraitsForDressing = JSON.parse(JSON.stringify(tempSelectedAvailableTraitsForDressing?.filter((item) => {return item.overlayName != tempOverlay.overlayName})));
                    }
                    setSelectedAvailableTraitsForDressing(tempSelectedAvailableTraitsForDressing)
                }
            }
            // debugger;
            let finalArr = [];
            if (tempSelectedAvailableTraitsForDressing.length > 1){
                for (var i = 1; i < tempSelectedAvailableTraitsForDressing.length; i++){
                    if (i == 1){
                        finalArr = findCart(tempSelectedAvailableTraitsForDressing[i-1].selected, tempSelectedAvailableTraitsForDressing[i].selected,tempSelectedAvailableTraitsForDressing[i-1].selected.length, tempSelectedAvailableTraitsForDressing[i].selected.length, true);
                    }else if((i) < tempSelectedAvailableTraitsForDressing.length){
                        let tempFinalArr = JSON.parse(JSON.stringify(finalArr));
                        finalArr = findCart(tempFinalArr,tempSelectedAvailableTraitsForDressing[i].selected,tempFinalArr.length,tempSelectedAvailableTraitsForDressing[i].selected.length,false)
                    }
                }
            }else if (tempSelectedAvailableTraitsForDressing.length == 1){
                for(var i = 0; i < tempSelectedAvailableTraitsForDressing[0].selected.length; i++){
                    finalArr.push([tempSelectedAvailableTraitsForDressing[0].selected[i]])
                }
                // finalArr = JSON.parse(JSON.stringify(selectedAvailableTraitsForDressing[0].selected));
            }

            // console.log('AvailableTraits finalArr :', finalArr)
            setSelectedAvailableTraitsForDressing(tempSelectedAvailableTraitsForDressing)

            // console.log('finalArr :', finalArr)
            let tempDressingPreview = [];
            for (var i = 0; i < finalArr.length; i++) {
                let nft = JSON.parse(JSON.stringify(selectedChamsForDressing[0]));
                // console.log('nft :', nft)
                // console.log('finalArr[i] :', finalArr[i])
                let attributes = JSON.parse(JSON.stringify(nft['attributes']));
                // console.log('attributes :', attributes)
                let filtered =attributes;
                for (var j = 0; j < finalArr[i].length; j++) {
                    filtered = filtered?.filter((item) => {return item.traitType != finalArr[i][j].trait});

                    // OR relation of trait handling for hat 'OR' Helmet, Mask 'OR' Prop, Clothes 'OR' Armor, 
                    if(finalArr[i][j].trait === 'Hat'){
                        filtered = filtered?.filter((item) => {return item.traitType != 'Helmet'});
                    }
                    if(finalArr[i][j].trait === 'Helmet'){
                        filtered = filtered?.filter((item) => {return item.traitType != 'Hat'});
                    }
                    if(finalArr[i][j].trait === 'Prop'){
                        filtered = filtered?.filter((item) => {return item.traitType != 'Mask'});
                    }
                    if(finalArr[i][j].trait === 'Mask'){
                        filtered = filtered?.filter((item) => {return item.traitType != 'Prop'});
                    }
                    if(finalArr[i][j].trait === 'Armor'){
                        filtered = filtered?.filter((item) => {return item.traitType != 'Clothes'});
                    }
                    if(finalArr[i][j].trait === 'Clothes'){
                        filtered = filtered?.filter((item) => {return item.traitType != 'Armor'});
                    }
                }
                // console.log('filtered :', filtered)
                nft['attributes'] =filtered;
                let preview = { nft: nft, overlays: finalArr[i] }
                tempDressingPreview.push(preview)
                // // console.log('preview :', preview)
            }
            // console.log('AvailableTraits tempDressingPreview :', tempDressingPreview)

            setDressingPreview(tempDressingPreview);
        }else {
            toast.warning( "Please select a chameleon to proceed" , {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            },);
        }
        
    }

    function findCart(arr1, arr2, n, n1, isFirst) {
        let mergedArr = [];
        for (let i = 0; i < n; i++) {
            for (let j = 0; j < n1; j++) {
                if (isFirst){
                    let tempArr = [arr1[i], arr2[j]];
                    mergedArr.push(tempArr) ;
                }else{
                    let tempArr = JSON.parse(JSON.stringify(arr1[i]));
                    tempArr.push(arr2[j]);
                    mergedArr.push(tempArr) ;
                }

            }
        }
        return mergedArr;
    }

    const selectChamsForDressing = (e) => {
        const {name, checked} = e.target;
        let tempLocalChams = localChams.map((nft) => 
            nft.tokenId == name ? {...nft, isChecked: checked} : {...nft, isChecked: false} 
        );
        setLocalChams(tempLocalChams);
    }
    // update selected chams local state 
    useEffect(() => {
        let tempSelectedChamsForDressing = localChams?.filter((overlay) => { return overlay.isChecked } );
        setSelectedChamsForDressing(tempSelectedChamsForDressing);
    }, [localChams]);
    
    useEffect(() => {
        dispatch(setWalletConnected({isWalletConnected: isConnected}));
        dispatch(setWalletAddress({walletAddress: address}));
    }, []);
    
    useEffect(() => {
        setActivePage('dressing-room-cham');
        return () => {
            setActivePage('');
        }
    }, []);

    return (
        <>
            <div className='cham-dressing-page row w-100 bg_white m-0'>
                <div className="col-2 px-0 cham-slider-wrapper">
                    <div className="all-cham-slider-wrapper">
                        {/* <button className="btn btn-danger w-100 mb-4" onClick={() => {history.push('/cham-creation')}}>Creacte New Chameleon</button> */}
                        <button className="btn btn-primary w-100 header-btn-styles cursor-default rounded-0" disabled>Available Chameleons</button>
                        <div className="all-cham-slider-alt d-flex flex-column align-items-center bg_white w-100 p-2 py-xxl-4 position-relative">
                            {localChams.length && 
                                localChams?.map((nft, i) => {
                                    return(
                                        <div key={i} className={"all-cham-slider-items mb-2 position-relative"}>
                                            <img src={nft.image && nft.image} alt="" className="w-100" />
                                            <div className="form-check cham-thumbnails-check-box position-absolute">
                                                <input type="checkbox" 
                                                    className=" form-check-input custom-checkbox cursor-pointer" 
                                                    id="materialUnchecked"
                                                    name={nft.tokenId} 
                                                    checked={nft.isChecked || false}
                                                    onChange={(e) => selectChamsForDressing(e)}
                                                />
                                            </div>
                                            <div className="cham-thumbnails-token-no position-absolute">
                                                {nft.token}
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            {/* {allPreviewNft.length && 
                                allPreviewNft?.map((nft, i) => {
                                    return(
                                        <div key={i} className="all-cham-slider-items mb-2">
                                            <img src={nft.imageUrl && nft.imageUrl} alt="" className="w-100" />
                                        </div>
                                    )
                                })
                            } */}
                        </div>
                        {/* <div className="csaiw-wrappper position-absolute w-100">
                            <div 
                                className={"cham-slider-add-img-wrapper mb-4 cursor-pointer position-relative w-100 h-100"}
                                onClick={() => {history.push('/cham-creation')}}
                                >
                                <div className="cham-slider-add-img position-absolute">
                                    <img src={AddNewImage} alt="" className="w-100" />
                                    <span className="tooltiptext">Creacte New Chameleon</span>
                                </div>
                            </div>
                        </div> */}
                        <button 
                            className="btn btn-primary w-100 header-btn-styles cursor-pointer rounded-0 mt-auto align-items-center" 
                            onClick={() => {history.push('/cham-creation')}}
                            // disabled
                        >
                            <MdAdd size={20} color="#fff" className='mb-1' /> Add Chameleon
                        </button>
                    </div>
                </div>
                <div className="col-3 w-100 px-0">
                    <div className="d-flex flex-column w-100">
                        <div className="overlay-custom-accordin-wrapper">
                            <button className="btn btn-primary w-100 header-btn-styles cursor-default rounded-0" disabled>Available Traits (click to enlarge)</button>
                            <div className='overlay-custom-accordin-container'>
                                <Accordion defaultActiveKey={['0']}>
                                    {localAllTraits.length &&
                                        localAllTraits?.sort((a, b) => {return a.sequence - b.sequence}).map((Trait, i) => {
                                            return(
                                                <Accordion.Item eventKey={i} key={i} >
                                                    <Accordion.Header onClick={() => setActiveAvailableTraitAccordian(Trait.trait)}>
                                                        <div className="overlay-custom-accordin-btn">
                                                            {Trait.trait}
                                                        </div>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className="row overlay-thumbnails-wrapper">
                                                            {localAllTraitsForDressingRoom.length && Trait.trait === activeAvailableTraitAccordian &&
                                                                localAllTraitsForDressingRoom?.map((dressingTrait, i) => {
                                                                    return(
                                                                        <>
                                                                            {dressingTrait.trait === activeAvailableTraitAccordian &&
                                                                                <div className="col-4 p-2">
                                                                                    <div className="overlay-thumbnails position-relative cursor-pointer">
                                                                                        <img 
                                                                                            src={dressingTrait.imageUrl && dressingTrait.imageUrl} 
                                                                                            alt="" 
                                                                                            className="w-100" 
                                                                                            onClick={() => {setShowZoomTraitPopup(true);setShowZoomTraitPopupImg(dressingTrait.imageUrl)}}
                                                                                        />
                                                                                        <div className="form-check overlay-thumbnails-check-box position-absolute">
                                                                                            <input type="checkbox" 
                                                                                                className=" form-check-input custom-checkbox cursor-pointer" 
                                                                                                id="materialUnchecked"
                                                                                                name={dressingTrait.chamTraitTypeId} 
                                                                                                checked={dressingTrait.isChecked || false}
                                                                                                onChange={(e) => selectAvailableTraitsForDressing(e)}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <span className='overlay-thumbnails-price font-sm'>
                                                                                        <FaEthereum size={12} className='mb-1'/> 
                                                                                        {dressingTrait.price} 
                                                                                    </span>
                                                                                </div>
                                                                            }
                                                                        </>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            )
                                        })
                                    }
                                </Accordion>
                            </div>
                        </div>
                        {/* <div className="overlay-custom-accordin-wrapper mt-4">
                            <button className="btn btn-info w-100 header-btn-styles cursor-default">New Traits</button>
                            <Accordion defaultActiveKey={['0']}>
                                {allChamOverlayTypes.length &&
                                    allChamOverlayTypes?.map((OverlayTypes, i) => {
                                        return(
                                            <Accordion.Item eventKey={i} key={i} >
                                                <Accordion.Header onClick={() => setActiveOverlayAccordian(OverlayTypes.traitType)}>
                                                    <div className="overlay-custom-accordin-btn">
                                                        {OverlayTypes.traitType}
                                                    </div>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <div className="row overlay-thumbnails-wrapper">
                                                        {localAllOverlay.length && OverlayTypes.traitType === activeOverlayAccordian &&
                                                            localAllOverlay?.map((overlays, i) => {
                                                                return(
                                                                    <>
                                                                        {overlays.type === activeOverlayAccordian &&
                                                                            <div className="col-4">
                                                                                <div className="overlay-thumbnails position-relative">
                                                                                    <img src={overlays.imageUrl && overlays.imageUrl} alt="" className="w-100" />
                                                                                    <div class="form-check overlay-thumbnails-check-box position-absolute">
                                                                                        <input type="checkbox" 
                                                                                            class=" form-check-input custom-checkbox cursor-pointer" 
                                                                                            id="materialUnchecked"
                                                                                            name={overlays.id} 
                                                                                            checked={overlays.isChecked || false}
                                                                                            onChange={(e) => selectOverlaysForDressing(e)}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        )
                                    })
                                }
                            </Accordion>
                        </div> */}
                    </div>
                </div>
                <div className="col-7 px-0">
                    <div className="cham-overlay-preview-wrapper d-flex flex-column position-relative bg_white w-100">
                    <button className="btn btn-primary w-100 header-btn-styles cursor-default rounded-0" disabled>Preview (click to enlarge)</button>
                        <div className="cham-overlay-preview-wrapper-container p-2">
                            <DressingPreviewSec 
                                dressingPreview={dressingPreview} 
                                nftType={nftType} 
                                setShowZoomNftPopup={setShowZoomNftPopup}
                                setShowZoomNftPopupId={setShowZoomNftPopupId}
                            />
                        </div>
                    </div>
                </div>
                {showZoomNftPopup && showZoomNftPopupId &&
                    <div className="popup_wrapper d-flex justify-content-center align-item-center">
                        <div className='dressing-preview-zoom-popup popup_container mt-5'>
                            {dressingPreview.length ? 
                                dressingPreview?.map((preview, i) => {
                                    return(
                                        <div className="">
                                            {(showZoomNftPopupId == i+1) &&
                                                <div key={i} className="dressing-preview-section-cham position-relative w-100">
                                                    <div className="dressing-cham-preview position-relative w-100">
                                                        {preview.nft.attributes.length ?
                                                            preview.nft.attributes?.map((traitLayer, i) => {
                                                                return (
                                                                    <>
                                                                        {(i === 0) ? 
                                                                            <div className={`dressing-cham-preview-layered-bg`}>
                                                                                <img src={traitLayer.url && traitLayer.url} alt="" className="w-100" />
                                                                            </div>
                                                                            :
                                                                            null
                                                                        }
                                                                        <div className={`dressing-cham-preview-layered position-absolute w-100 ${"dressing-cham-"+(traitLayer.traitType).split(' ').join('_')}`}>
                                                                            <img src={traitLayer.url && traitLayer.url} alt="" className="w-100" />
                                                                        </div>
                                                                    </>
                                                                )
                                                            })
                                                            :
                                                            null
                                                        }
                                                        <div 
                                                            className="dressing-preview-zoom-popup-close d-flex justify-content-end p-2 cursor-pointer position-absolute"
                                                            onClick={() => {setShowZoomNftPopup(false)}}
                                                        >
                                                            <FaTimes size={24}/>
                                                        </div>
                                                    </div>
                                                    {preview.overlays.length ?
                                                        preview.overlays?.map((overlay) => {
                                                            return(
                                                                <div key={i} className={`dressing-preview-section-overlay position-absolute dressing-cham-Overlay  ${"dressing-cham-"+(overlay.trait).split(' ').join('_')}`}>
                                                                    <img 
                                                                        src={overlay.imageUrl && overlay.imageUrl} 
                                                                        alt="" 
                                                                        className="w-100"
                                                                    />
                                                                </div>
                                                            )
                                                        })
                                                        :
                                                        null
                                                    }
                                                </div>
                                            }
                                        </div>
                                    )
                                })
                                :
                                null
                            }
                        </div>
                    </div>
                }
                {showZoomTraitPopup && 
                    <div className="popup_wrapper d-flex justify-content-center align-item-center">
                        <div className='dressing-preview-zoom-popup popup_container mt-5'>
                            <div className="">
                                <div className="dressing-preview-section-cham position-relative w-100">
                                    <div className="dressing-cham-preview position-relative w-100">
                                        <img src={showZoomTraitPopupImg && showZoomTraitPopupImg} alt="" className="w-100" />
                                        <div 
                                            className="dressing-preview-zoom-popup-close d-flex justify-content-end p-2 cursor-pointer position-absolute"
                                            onClick={() => {setShowZoomTraitPopup(false)}}
                                        >
                                            <FaTimes size={24}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
            <Footer fullWidth/>
        </>
    )
}

export default DressingRoom
