import * as type from '../types';

const initialState = {
    status: '',
    addToCartStatus: '',
    loading: false,
    overlaysLoading: false,
    afterPaymentLoading: false,
    afterPaymentStatus: false,
    afterPaymentFailedMsg: '',
    allChamTraitTypes: [],
    ChamTraitsByTypes: [],
    allPreviewNft: [],
    allChamOverlayTypes: [],
    ChamOverlaysByTypes: [],
    allOverlays: [],
    allWishlist: [],
    allTraits: [],
    allTraitsForDressingRoom: [], 
    allInventoryTraitsTypes: [], 
    allAvailableInventory: [], 
    isPendingOrder: false, 
    orderDetails: '', 
    traitList: '', 
    applyInventoryStatus: '', 
    deleteWishlistLoading: false, 
    allWishlistLoading: false, 
    orderDetail: null,
    error: null,
}

export default function dressingCham(state = initialState, action) {
    switch (action.type) {
        case type.FIND_ALL_CHAM_TRAIT_TYPES:
            return {
                ...state,
                loading: true,
            }
        case type.FIND_ALL_CHAM_TRAIT_TYPES_SUCCESS:
            return {
                ...state,
                loading: false,
                allChamTraitTypes: action.payload.traitTypes,
                error: null,
            }
        case type.FIND_ALL_CHAM_TRAIT_TYPES_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        case type.FIND_CHAM_TRAITS_BY_TRAIT_TYPE:
            return {
                ...state,
                loading: true,
            }
        case type.FIND_CHAM_TRAITS_BY_TRAIT_TYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                ChamTraitsByTypes: action.payload.traits,
                error: null,
            }
        case type.FIND_CHAM_TRAITS_BY_TRAIT_TYPE_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        case type.SAVE_PREVIEW_NFT:
            return {
                ...state,
                loading: true,
            }
        case type.SAVE_PREVIEW_NFT_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
            }
        case type.SAVE_PREVIEW_NFT_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        case type.GET_ALL_PREVIEW_NFT_LIST:
            return {
                ...state,
                loading: true,
            }
        case type.GET_ALL_PREVIEW_NFT_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                allPreviewNft: action.payload.allPreviewNft,
                error: null,
            }
        case type.GET_ALL_PREVIEW_NFT_LIST_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        case type.GET_ALL_OVERLAY_TYPE:
            return {
                ...state,
                loading: true,
            }
        case type.GET_ALL_OVERLAY_TYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                allChamOverlayTypes: action.payload.OverlayTypes,
                error: null,
            }
        case type.GET_ALL_OVERLAY_TYPE_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        case type.GET_OVERLAY_BY_TYPE:
            return {
                ...state,
                overlaysLoading: true,
            }
        case type.GET_OVERLAY_BY_TYPE_SUCCESS:
            return {
                ...state,
                overlaysLoading: false,
                ChamOverlaysByTypes: action.payload.Overlays,
                error: null,
            }
        case type.GET_OVERLAY_BY_TYPE_FAILED:
            return {
                ...state,
                overlaysLoading: false,
                error: action.payload,
            }
        case type.GET_ALL_OVERLAY:
            return {
                ...state,
                overlaysLoading: true,
            }
        case type.GET_ALL_OVERLAY_SUCCESS:
            return {
                ...state,
                overlaysLoading: false,
                allOverlays: action.payload.allOverlays,
                error: null,
            }
        case type.GET_ALL_OVERLAY_FAILED:
            return {
                ...state,
                overlaysLoading: false,
                error: action.payload,
            }
        case type.ADD_TO_WISHLIST:
            return {
                ...state,
                loading: true,
            }
        case type.ADD_TO_WISHLIST_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
            }
        case type.ADD_TO_WISHLIST_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        case type.GET_ALL_WISHLIST:
            return {
                ...state,
                allWishlistLoading: true,
            }
        case type.GET_ALL_WISHLIST_SUCCESS:
            return {
                ...state,
                allWishlistLoading: false,
                allWishlist: action.payload.wishlist,
                isPendingOrder: action.payload.isPendingOrder,
                orderDetails: action.payload.orderDetails,
                traitList: action.payload.traitList,
                error: null,
            }
        case type.GET_ALL_WISHLIST_FAILED:
            return {
                ...state,
                allWishlistLoading: false,
                error: action.payload,
            }
        case type.DELETE_WISHLIST:
            return {
                ...state,
                deleteWishlistLoading: true,
            }
        case type.DELETE_WISHLIST_SUCCESS:
            return {
                ...state,
                deleteWishlistLoading: false,
                error: null,
            }
        case type.DELETE_WISHLIST_FAILED:
            return {
                ...state,
                deleteWishlistLoading: false,
                error: action.payload,
            }
        case type.WISHLIST_SELECTED_FOR_PRINT:
            return {
                ...state,
                loading: true,
            }
        case type.WISHLIST_SELECTED_FOR_PRINT_SUCCESS:
            return {
                ...state,
                loading: false,
                addToCartStatus: action.payload.status,
                orderDetails: action.payload.orderDetails,
                traitList: action.payload.traitList,
                error: null,
            }
        case type.WISHLIST_SELECTED_FOR_PRINT_FAILED:
            return {
                ...state,
                loading: false,
                addToCartStatus: action.payload.status,
                error: action.payload,
            }
        case type.AFTER_PAYMENT:
            return {
                ...state,
                afterPaymentLoading: true,
            }
        case type.AFTER_PAYMENT_SUCCESS:
            return {
                ...state,
                afterPaymentLoading: false,
                afterPaymentStatus: action.payload.status,
                orderDetail: action.payload.orderDetail,
                afterPaymentFailedMsg: action.payload.failedMsg,
                error: null,
            }
        case type.AFTER_PAYMENT_FAILED:
            return {
                ...state,
                afterPaymentLoading: false,
                afterPaymentStatus: action.payload.status,
                error: action.payload,
            }
        case type.AFTER_PAYMENT_RESET:
            return {
                ...state,
                afterPaymentLoading: false,
                afterPaymentStatus: '',
                afterPaymentFailedMsg: '',
                error: '',
            }
        case type.DELETE_ORDER:
            return {
                ...state,
                loading: true,
            }
        case type.DELETE_ORDER_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
            }
        case type.DELETE_ORDER_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        case type.FIND_ALL_TRAITS:
            return {
                ...state,
                loading: true,
            }
        case type.FIND_ALL_TRAITS_SUCCESS:
            return {
                ...state,
                loading: false,
                allTraits: action.payload.allTraits,
                error: null,
            }
        case type.FIND_ALL_TRAITS_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        case type.FIND_ALL_FOR_DRESSING_ROOM:
            return {
                ...state,
                loading: true,
            }
        case type.FIND_ALL_FOR_DRESSING_ROOM_SUCCESS:
            return {
                ...state,
                loading: false,
                allTraitsForDressingRoom: action.payload.allTraitsForDressingRoom,
                error: null,
            }
        case type.FIND_ALL_FOR_DRESSING_ROOM_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        case type.FIND_ALL_INVENTORY_TRAIT_TYPES:
            return {
                ...state,
                loading: true,
            }
        case type.FIND_ALL_INVENTORY_TRAIT_TYPES_SUCCESS:
            return {
                ...state,
                loading: false,
                allInventoryTraitsTypes: action.payload.allInventoryTraitsTypes,
                error: null,
            }
        case type.FIND_ALL_INVENTORY_TRAIT_TYPES_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        case type.FIND_ALL_AVAILABLE_INVENTORY:
            return {
                ...state,
                loading: true,
            }
        case type.FIND_ALL_AVAILABLE_INVENTORY_SUCCESS:
            return {
                ...state,
                loading: false,
                allAvailableInventory: action.payload.allAvailableInventory,
                error: null,
            }
        case type.FIND_ALL_AVAILABLE_INVENTORY_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        case type.APPLY_INVENTORY:
            return {
                ...state,
                loading: true,
            }
        case type.APPLY_INVENTORY_SUCCESS:
            return {
                ...state,
                loading: false,
                applyInventoryStatus: action.payload.status,
                error: null,
            }
        case type.APPLY_INVENTORY_FAILED:
            return {
                ...state,
                loading: false,
                applyInventoryStatus: action.payload.status,
                error: action.payload.massage,
            }
        case type.RESET_DRESSING_CHAM:
            return {
                ...state,
                status: null,
                addToCartStatus: '',
                afterPaymentStatus: '',
                error: null,
            }
        default:
            return state;
    }
}