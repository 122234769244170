import React, { useEffect, useState } from 'react';
import { FaCheck, FaEthereum, FaRegCopy } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { afterPayment, afterPaymentReset, deleteOrder, deleteWishlist, getAllPreviewNftList, getAllWishList, resetDressingCham, wishlistSelectedForPrint } from '../../redux/actions/dressingCham';
import { getTokenByUser } from '../../redux/actions/users';
import { toast } from 'react-toastify';
import { PulseLoader } from 'react-spinners';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Footer from '../../components/Footer/Footer';
import { BsEmojiFrown } from 'react-icons/bs';
import { TiDelete } from 'react-icons/ti';
import { utils, BigNumber, ethers } from 'ethers';
import {nftContract, signer,iface,provider,ethereum} from '../../chamPotionVenderWeb3';
import './CompareWishlist.css';

function CompareWishlist() {
    const [localAllWishlist, setLocalAllWishlist] = useState([]);
    const [wishListCart, setWishListCart] = useState([]);
    const [isAddToCartModalOpen, setIsAddToCartModalOpen] = useState(false);
    const [isPendingOrderModalOpen, setIsPendingOrderModalOpen] = useState(false);  
    const [canCheckout, setCanCheckout] = useState(false); 
    const [orderIdForCheckout, setOrderIdForCheckout] = useState('');  
    const [localCheckoutTxnHash, setLocalCheckoutTxnHash] = useState();
    const [isAccCodeCopied, setIsAccCodeCopied] = useState(false);
    const [checkoutLoading, setCheckoutLoading] = useState(false);
    
    const dispatch = useDispatch();
    const history = useHistory();

    const user = useSelector(state => state.users.user);
    // const allPreviewNft = useSelector(state => state.dressingCham.allPreviewNft);
    // const chameleonNft = useSelector(state => state.users.chameleonNft);
    const allWishlist = useSelector(state => state.dressingCham.allWishlist);
    const addToCartStatus = useSelector(state => state.dressingCham.addToCartStatus);
    const afterPaymentLoading = useSelector(state => state.dressingCham.afterPaymentLoading);
    const afterPaymentStatus = useSelector(state => state.dressingCham.afterPaymentStatus);
    // const orderDetail = useSelector(state => state.dressingCham.orderDetail);
    const orderDetails = useSelector(state => state.dressingCham.orderDetails);
    const traitList = useSelector(state => state.dressingCham.traitList);
    const isPendingOrder = useSelector(state => state.dressingCham.isPendingOrder);
    const pendingOrder = useSelector(state => state.dressingCham.pendingOrder);
    const afterPaymentFailedMsg = useSelector(state => state.dressingCham.afterPaymentFailedMsg);
    const deleteWishlistLoading = useSelector(state => state.dressingCham.deleteWishlistLoading);
    const allWishlistLoading = useSelector(state => state.dressingCham.allWishlistLoading);

    useEffect(() => {
        dispatch(getTokenByUser(user.userId))
    }, [user, user.userId]);

    useEffect(() => {
        // dispatch(getAllPreviewNftList())
        dispatch(getAllWishList())
        return () => {
            dispatch(resetDressingCham())
            dispatch(afterPaymentReset())
        }
    }, []);
    
    useEffect(() => {
        setLocalAllWishlist(allWishlist)
    }, [allWishlist]);

    const addWishlistToCart = (name, checked) => {
        // const {} = e.target;
        let tempLocalAllWishlist = localAllWishlist.map((wishlistItem) => 
            wishlistItem.wishlistId == name ? {...wishlistItem, isChecked: checked} : wishlistItem 
        );
        // console.log("tempLocalAllWishlist", tempLocalAllWishlist)
        setLocalAllWishlist(tempLocalAllWishlist);
    }

    useEffect(() => {
        const tempWishListCart = localAllWishlist?.filter((item) => {return item.isChecked})?.map((item) => {return item.wishlistId})
        setWishListCart(tempWishListCart)
    }, [localAllWishlist]);

    useEffect(() => {
        if(addToCartStatus === 200){
            setCanCheckout(true)
            setOrderIdForCheckout(orderDetails.orderId)
        }else if(addToCartStatus === 304){
            setIsAddToCartModalOpen(false)
            setIsPendingOrderModalOpen(true)
        }else {
            setIsPendingOrderModalOpen(false)
        }
    }, [addToCartStatus]);

    useEffect(() => {
        if(afterPaymentStatus === 200){
            history.push('/inventory')
        }else {
            return
        }
    }, [afterPaymentStatus]);

    function fallbackCopyTextToClipboard(text) {
        var textArea = document.createElement("textarea");
        textArea.value = text;
        
        // Avoid scrolling to bottom
        textArea.style.top = "0";
        textArea.style.left = "0";
        textArea.style.position = "fixed";
      
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
      
        try {
            var successful = document.execCommand('copy');
            var msg = successful ? 'successful' : 'unsuccessful';
            // console.log('Fallback: Copying text command was ' + msg);
        } catch (err) {
            console.error('Fallback: Oops, unable to copy', err);
        }
      
        document.body.removeChild(textArea);
    }

    function copyTextToClipboard(text) {
        if (!navigator.clipboard) {
          fallbackCopyTextToClipboard(text);
          return;
        }
        navigator.clipboard.writeText(text).then(function() {
            setIsAccCodeCopied(true);
            setTimeout(() => {
                setIsAccCodeCopied(false);
            }, 2500);
            toast.success( "Copied" + text , {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            },);
        }, function(err) {
          console.error('Async: Could not copy text: ', err);
        });
    }

    // mint handler 
    const metaMaskCheckoutHandler = async (mintType) => {
        try {
            setCheckoutLoading(true);
            // at a later point in Time
            await ethereum.enable();
            const sender = await  signer.getAddress()

            let totalPrice = ( 10000 * orderDetails.orderAmount) / 10000;
            // console.log("totalPrice :",totalPrice)
            let t1 = (1000 * totalPrice );
            let totalPrice1 = (1000000000000000 * t1).toString()
            const finalPrice = BigNumber.from(totalPrice1.toString());
            // console.log('finalPrice big no. :', finalPrice._hex)

            const transactionParameters = {
                nonce: '0x00', // ignored by MetaMask
                // gasPrice: '0x09184e72a000', // customizable by user during MetaMask confirmation.
                // gas: '0x2710', // customizable by user during MetaMask confirmation.
                to: orderDetails.paymentAccount, // Required except during contract publications.
                from: sender, // must match user's active address.
                value: finalPrice._hex, // Only required to send ether to the recipient from the initiating external account.
                // data:
                //   '0x7f7465737432000000000000000000000000000000000000000000000000000000600057', // Optional, but used for defining smart contract creation and interaction.
                chainId: '0x1', // Used to prevent transaction reuse across blockchains. Auto-filled by MetaMask.
            };

            let tempBalance = await provider.getBalance(signer.getAddress());
            // console.log("tempBalance :", ethers.utils.formatEther(tempBalance))

            if(ethers.utils.formatEther(tempBalance)) {

                let transactionHash = await ethereum.request({
                    method: 'eth_sendTransaction',
                    params: [transactionParameters],
                });
                // console.log("transactionHash : ", transactionHash)
                if(transactionHash) {
                    setTimeout(function () {
                        setCheckoutLoading(false);
                        dispatch(afterPayment({orderId: orderIdForCheckout , txnHash: transactionHash }))
                    }, 10000);
                }
            }else{
                alert("Oops!! Insufficient balance")
                setCheckoutLoading(false);
            }
        }catch (err) {
            setCheckoutLoading(false);
            // console.log(err);
            alert(err.message);
        }
    }

    return (
        <>
        <div className='compare-wishlist-page bg_white p-4'>
            <div className={`${isAddToCartModalOpen ? "wishlist-add-to-cart-modal-wrapper d-flex justify-content-center align-items-center position-fixed" : "d-none"}`}>
                <div className="discard-order-modal">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLongTitle">Confirmation</h5>
                            <button type="button" className="close" onClick={() => {
                                setIsAddToCartModalOpen(false);
                                dispatch(resetDressingCham());
                            }}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <span className="p-4">
                                Proceed to Checkout
                            </span>
                        </div>
                        <div className="modal-footer">
                            <button 
                                type="button" 
                                className="btn btn-outline-danger" 
                                onClick={() => {setIsAddToCartModalOpen(false);dispatch(resetDressingCham());}}
                            >
                                Discard
                            </button>
                            <button
                                type="button" 
                                className="btn btn-outline-primary"
                                onClick={() => {dispatch(wishlistSelectedForPrint(wishListCart));setIsAddToCartModalOpen(false);}}
                            >
                                Buy Now
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${isPendingOrderModalOpen ? "wishlist-add-to-cart-modal-wrapper d-flex justify-content-center align-items-center position-fixed" : "d-none"}`}>
                <div className="discard-order-modal">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLongTitle">Confirmation</h5>
                        </div>
                        <div className="modal-body">
                            <span className="p-4">
                                Your last order with Order Id {orderDetails ? orderDetails.orderId : '00:00'} has not been completed. 
                                <br />
                                Please Complete or Discard this order before placing a new order
                            </span>
                        </div>
                        <div className="modal-footer">
                            <button 
                                type="button" 
                                className="btn btn-outline-danger" 
                                onClick={() => {dispatch(deleteOrder({orderId: orderDetails.orderId}));setIsPendingOrderModalOpen(false)}}
                            >
                                Discard Order
                            </button>
                            <button
                                type="button" 
                                className="btn btn-outline-primary"
                                onClick={() => {setCanCheckout(true);setOrderIdForCheckout(orderDetails.orderId);setIsPendingOrderModalOpen(false)}}
                            >
                                Complete Order
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <h1 className="compare-wishlist-page-heading text-info mb-4">{!canCheckout ? "WishList" : "Checkout"}</h1>
            {!canCheckout && isPendingOrder ?
                <div className="d-flex justify-content-between align-items-center bg-warning rounded px-4 py-2 mb-4">
                    <h4 className="">
                        You previous order is pending !!!
                    </h4>
                    <div className="d-flex align-items-center">
                        <button 
                            type="button" 
                            className="btn btn-outline-danger mx-4" 
                            onClick={() => {dispatch(deleteOrder({orderId: orderDetails.orderId}));}}
                        >
                            Discard Order
                        </button>
                        <button
                            type="button" 
                            className="btn btn-outline-primary"
                            onClick={() => {setCanCheckout(true);setOrderIdForCheckout(orderDetails.orderId);}}
                        >
                            Complete Order
                        </button>
                    </div>
                </div>
                :
                null
            }
            {!canCheckout ?
                <>
                    <div className="row g-4">
                        {localAllWishlist.length ?
                            localAllWishlist?.map((wishlistItem, i) => {
                                return(
                                    <div key={i} className="col-3">
                                        <div className="d-flex flex-column">
                                            <div className="compare-wishlist-nft-img position-relative">
                                                {wishlistItem.wishlistJson.attributes.length ? 
                                                    wishlistItem.wishlistJson.attributes?.map((nftLayer, i) => {
                                                        return (
                                                            <>
                                                                {/* fixed bg layer for propper grid view */}
                                                                {(nftLayer.trait_type === "Background") ? 
                                                                    <div className={`dressing-cham-preview-layered-bg`}>
                                                                        <img src={nftLayer.url && nftLayer.url} alt="" className="w-100" />
                                                                    </div>
                                                                    :
                                                                    null
                                                                }
                                                                <div className={`dressing-cham-preview-layered position-absolute w-100 ${"dressing-cham-"+(nftLayer.trait_type).split(' ').join('_')}`}>
                                                                    <img src={nftLayer.url && nftLayer.url} alt="" className="w-100" />
                                                                </div>
                                                            </>
                                                        )
                                                    })
                                                    :
                                                    null
                                                }
                                                <div 
                                                    className="Remove-from-wishlist-icon position-absolute cursor-pointer"
                                                    onClick={() => {
                                                        !allWishlistLoading && !deleteWishlistLoading && dispatch(deleteWishlist({wishlistId: wishlistItem.wishlistId}))
                                                    }}
                                                >
                                                    <TiDelete color='red' size={40}/>
                                                    <span className="tooltiptext">Remove</span>
                                                </div>
                                                <div className="form-check compare-wishlist-check-box position-absolute">
                                                    <input type="checkbox" 
                                                        className=" form-check-input custom-checkbox cursor-pointer" 
                                                        id="materialUnchecked"
                                                        name={wishlistItem.wishlistId && wishlistItem.wishlistId} 
                                                        checked={wishlistItem.isChecked || false}
                                                        onChange={(e) => addWishlistToCart(e.target.name, e.target.checked)}
                                                    />
                                                </div>
                                            </div> 
                                            <div className="d-flex w-100">
                                                <button className="btn btn-outline-warning compare-wishlist-page-buy-btn mb-4 w-50" disabled>
                                                    {wishlistItem.price ? wishlistItem.price : "0.0"} ETH
                                                </button>
                                                {wishlistItem.isChecked ?
                                                    <button 
                                                        className="btn btn-outline-danger compare-wishlist-page-buy-btn mb-4 w-50"
                                                        onClick={() => addWishlistToCart(wishlistItem.wishlistId, false)}
                                                    >
                                                        Remove 
                                                    </button>
                                                    :
                                                    <button 
                                                        className="btn btn-outline-info compare-wishlist-page-buy-btn mb-4 w-50"
                                                        onClick={() => addWishlistToCart(wishlistItem.wishlistId, true)}
                                                    >
                                                        Add to Cart
                                                    </button>

                                                }
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                            :
                            <h1 className="compare-wishlist-page-heading text-secondary mb-4">
                                <BsEmojiFrown className='text-  ' />
                                &nbsp;&nbsp;&nbsp;
                                Wishlist Empty
                                &nbsp;&nbsp;&nbsp;
                                <BsEmojiFrown className='text-  ' />
                            </h1>
                        }
                    </div>
                    <div className="d-flex justify-content-between aligne-items-center">
                        <button 
                            className="btn btn-outline-secondary compare-wishlist-page-buy-btn"
                            onClick={() => {
                                history.push('/dressing-room-cham')
                            }}
                        >
                            Back
                        </button>
                        <button 
                            className="btn btn-outline-primary compare-wishlist-page-buy-btn mx-2"
                            onClick={() => {
                                setIsAddToCartModalOpen(true);
                            }}
                            disabled={wishListCart.length === 0}
                        >
                            Buy Now
                        </button>
                    </div>
                </>
                :
                <div className="row">
                    <div className="col-12 p-4">
                        <table className="table">
                            <thead>
                                <tr>
                                <th scope="col">Trait Image</th>
                                <th scope="col">Trait type</th>
                                <th scope="col">Trait name</th>
                                <th scope="col">Quantity</th>
                                <th scope="col">Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                {traitList.length > 0 &&
                                    traitList?.map((item, i) => {
                                        return(
                                            <tr>
                                                <th scope="row">
                                                    <div className="wl-img-checkout mx-auto">
                                                        <img src={item.imageUrl} alt="" className="w-100" />
                                                    </div>
                                                </th>
                                                <td>{item.trait}</td>
                                                <td>{item.traitName}</td>
                                                <td>{item.qty}</td>
                                                <td>{item.price * item.qty}</td>
                                            </tr>
                                        )
                                    })
                                }
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>Total :</td>
                                    <td><b>{ orderDetails.orderAmount } ETH</b></td>
                                </tr>
                            </tbody>
                        </table>
                        {afterPaymentStatus === 302 && afterPaymentFailedMsg ?
                            <div className="d-flex justify-content-center aligne-items-center">
                                <h3 className="text-info">{afterPaymentFailedMsg && afterPaymentFailedMsg}</h3>
                            </div>
                            :
                            <div className="d-flex justify-content-center aligne-items-center">
                                <button 
                                    type="button" 
                                    className="btn btn-primary btn-lg d-flex justify-content-center align-item-center " 
                                    onClick={() => {!afterPaymentLoading && !checkoutLoading && metaMaskCheckoutHandler();}}
                                    // disabled={!localCheckoutTxnHash || !orderIdForCheckout}
                                >
                                    { afterPaymentLoading || checkoutLoading ? 
                                        <PulseLoader loading={afterPaymentLoading || checkoutLoading} size={16} color='#d5d5d5'/> : 
                                        <>
                                            Checkout
                                            &nbsp;&nbsp;
                                            <FaEthereum size={20}/>
                                        </> 
                                    }
                                </button>
                            </div>
                        }
                    </div>
                    {/* <div className="col-6">
                        <div className="d-flex flex-column align-items-start p-3">
                            <div className="pb-2">
                                1. Total <b>{ orderDetails.orderAmount } ETH</b> to be paid
                            </div>
                            <div className="pb-2 d-flex flex-column align-items-start">
                                2. Metamask wallet in which payment will be collected -
                                <br />
                                <div className="d-flex">
                                    <span className="text-primary mx-4"> {orderDetails.paymentAccount} </span>
                                    {!isAccCodeCopied ?
                                        <span className="text-info cursor-pointer" onClick={() => copyTextToClipboard(orderDetails.paymentAccount)}>
                                            <FaRegCopy size={24} /> copy
                                        </span>
                                        :
                                        <span className="text-info cursor-pointer" >
                                            <FaCheck size={24} /> copied
                                        </span>
                                    }
                                </div>
                            </div>
                            <div className="pb-2">
                                3. Please pay the amount using metamask's send eth feature to above mentioned address
                            </div>
                            <div className="pb-2 mb-2">
                                4. Please enter below the Transaction Hash of your payment
                            </div>
                            <div className="form-outline row d-flex justify-content-start align-items-left mb-4 w-100">
                                <div className="col-md-2 d-flex align-items-center">
                                    <label className="form-label m-0" for="form3Example5">Txn Hash : <span className="text-danger">*</span></label>
                                </div>
                                <div className="col-md-10 d-flex">
                                    <input 
                                        type="text" 
                                        id="form3Example5" 
                                        className="form-control form-control-lg"
                                        value={localCheckoutTxnHash || null}
                                        placeholder={"Enter the transaction hash of the payment you made"}
                                        onChange={(e) => setLocalCheckoutTxnHash(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="d-flex justify-content-end aligne-items-center">
                                
                            </div>
                        </div>
                    </div> */}
                </div>
            }
        </div>
        <Footer fullWidth/>
        </>
    )
}

export default CompareWishlist
