import React, { useEffect, useRef, useState } from 'react';
import { FaTimes } from "react-icons/fa";
import './RoadmapPopup.css';

function RoadmapPopup({ Title, Subtitle, show, onClose, onOpen }) {
      const [showPopup, setShowPopup] = useState(show);

      useEffect(() => {
        setShowPopup(show);
      }, [show]);
    
      useEffect(() => {
        if (showPopup) {
          if (onOpen) onOpen(showPopup);
        } else {
          if (onClose) onClose(showPopup);
        }
      }, [showPopup]);
      

      const node = useRef();
      const onClickAway = (e) => {
          if(node.current.contains(e.target)){
            return;
          }else {
              setShowPopup(false)
          }
      } 
    
      useEffect(() => {
        document.addEventListener("mousedown", onClickAway);
        return () => {
          document.removeEventListener("mousedown", onClickAway);
        };
      }, []);

    return (
        <div className={`${showPopup ? "d-block" : "d-none"}`}>
            <div ref={node} className="modal_content ">
                <div className="modal_header position-relative p-4">
                    <h5 className="modal_title" >{Title}</h5>
                    <h6>{Subtitle}</h6>
                    <button className="close_btn position-absolute d-flex" onClick={() => setShowPopup(false)}>
                        <FaTimes color={"#fff"}/>
                    </button>
                </div>
                <div className="modal_body">
                    <ul>
                        <li>OG Member Access</li>
                        <li>Partner Program</li>
                        <li>Whitelist for SPC Holders</li>
                        <li>Whitelist 2.0</li>
                        <li>Meetups</li>
                        <li>Vendor Benefits </li>
                        <li>Bars, shows, other places of business</li>
                        <li>Business Connections</li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default RoadmapPopup
