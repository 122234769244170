import { all } from 'redux-saga/effects';
import potionSaga from './potionSaga';
import chamagotchiSaga from './chamagotchiSaga';
import usersSaga from './usersSaga';
import dressingChamSaga from './dressingChamSaga';

export default function* rootSaga() {
    yield all([
        usersSaga(),
        chamagotchiSaga(),
        potionSaga(),
        dressingChamSaga(),
    ])
}