import { combineReducers } from "redux";
import users from "./users";
import chamagotchi from "./chamagotchi";
import potion from "./potion";
import dressingCham from "./dressingCham";
import miscellaneous from "./miscellaneous";

const appReducer = combineReducers({
    users: users,
    chamagotchi: chamagotchi,
    potion: potion,
    dressingCham: dressingCham,
    miscellaneous: miscellaneous,
  });
  
const rootReducers = (state, action) => {
    if (action.type === 'USER_LOGOUT') {
        state = undefined;
        localStorage.removeItem("chameleon_access_token")
    }
    return appReducer(state, action);
};
export default rootReducers;