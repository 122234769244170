import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FcEditImage, } from "react-icons/fc";
import { FaChevronDown, } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';
import { PulseLoader } from 'react-spinners';
import { userLogout } from '../../redux/actions/users';
import { getRemainingTimeUntilMsTimestamp } from "../ChamagotchiNftCardWrapper/utils/CountdownTimerUtils";
import { getCaptcha, resetChamagotchi } from '../../redux/actions/chamagotchi';
import { base_url } from '../../constant';
import './ChamagotchiNftCard.css';


function ChamagotchiNftCard({ 
    nftImgUrl, 
    tokanNo, 
    chamagotchiTokenId, 
    accountCode, 
    index, 
    isShowMore, 
    setShowNftPopup, 
    setPopupNftImgUrl, 
    setPopupNftTokanNo,
    setNextFeedEndTime, 
    nextFeedEndTime, 
    isLive,
    setIsLive,
    deadTime,
    setDeadTime,
    canEdit,
    setChamagotchiIdForEdit,
    setAccountAdd,
    setActivePage,
    airdropTxnId,
    setAirdropTxnId,
    setAccountNo,
    setUserId, }) {
    const [isFav, setIsFav] = useState(false);
    const [isChamagotchiMoreOpen, setIsChamagotchiMoreOpen] = useState(false);
    const [feedAuthLoading, setFeedAuthLoading] = useState(false);

    var defaultRemainingTime = {
        seconds: '00',
        minutes: '00',
        hours: '00',
        days: '00',
    };
    const [remainingTime, setRemainingTime] = useState(defaultRemainingTime);
    const countdownTimestampMs = nextFeedEndTime ? new Date(nextFeedEndTime).getTime()  : new Date().getTime();

    const history = useHistory();
    const dispatch = useDispatch();
    const captchaCode = useSelector(state => state.chamagotchi.captchaCode);
    const captchaBase64 = useSelector(state => state.chamagotchi.captchaBase64);

    useEffect(() => {
        const intervalId = setInterval(() => {
            updateRemainingTime(countdownTimestampMs);
        }, 1000);
        return () => clearInterval(intervalId);
    },[countdownTimestampMs]);

    function updateRemainingTime(countdown) {
        setRemainingTime(getRemainingTimeUntilMsTimestamp(countdown));
    }

    function checkAuth() {
        setFeedAuthLoading(true);
        axios({
            method: 'get',
            url: `${base_url}/api/auth/authenticate`,
            headers: { "Content-Type": "application/json", "Authorization": "Bearer "+localStorage.getItem("chameleon_access_token") },
        })
        .then(res => {
          if(res.data === true) {
            setFeedAuthLoading(false);
            setShowNftPopup(true);
            setPopupNftImgUrl(nftImgUrl);
            setPopupNftTokanNo(tokanNo);
            setNextFeedEndTime(nextFeedEndTime);
            // setErrorMsg(errorMsg);
            setIsLive(isLive);
            setDeadTime(deadTime);
        }else {
            setFeedAuthLoading(false);
            toast.info( "Session Expired. Please Login Again" , {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            },);
            setAccountNo(null);
            setUserId(null);
            setActivePage('');
            dispatch(resetChamagotchi());
            dispatch(userLogout());
        }
    })
    .catch(err => {
            setFeedAuthLoading(false);
            toast.info( "Session Expired. Please Login Again" , {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            },);
            setAccountNo(null);
            setUserId(null);
            setActivePage('');
            dispatch(resetChamagotchi());
            dispatch(userLogout());
        })
    }

    return (
        <div  className={`${(!isShowMore) ? ( index >= 5 )? "d-none" : "nft_card d-flex flex-column p-2 position-relative" : "nft_card d-flex flex-column p-2 position-relative"}`}>
            <img 
                src={nftImgUrl ? nftImgUrl : 'img/blank-chamagotchi.jpg'}
                alt="nft-img" 
                onClick={() => {
                    checkAuth();
                    dispatch(getCaptcha());
                }}
            />
            {(canEdit) ?
                <span className="edit_chamagotchi position-absolute">
                    <a href="#" data-toggle="tooltip" data-placement="right" title="click to edit">
                        <FcEditImage size={40} onClick={() => {setChamagotchiIdForEdit(tokanNo); setAccountAdd(accountCode); setAirdropTxnId(airdropTxnId); history.push('./design-your-pet'); setActivePage('design-your-pet')}}/>
                    </a>
                </span>
                :
                null
            }
            <span className="chamagotchi_more position-absolute">
                <FaChevronDown size={24} onClick={() => {setIsChamagotchiMoreOpen(!isChamagotchiMoreOpen)}}/>
            </span>
            <spsn 
                className={`${isChamagotchiMoreOpen ? "chamagotchi_more_options position-absolute px-4 p-1" : "d-none"}`}
                onClick={() => history.push(`/chamagotchi-details/${tokanNo}`)}
            >
                Details
            </spsn>
            <div className="d-flex justify-content-between align-item-center p-2 text-secondary">
                <span className="tokan_no">{(chamagotchiTokenId != null) ? chamagotchiTokenId : 'NA'}</span>
                <div className="clock flex-grow">
                    <span className='time'>
                        {remainingTime.hours}:
                        {remainingTime.minutes}:
                        {remainingTime.seconds}
                    </span>
                </div>
                <span className="add_to_fav" onClick={() => setIsFav(!isFav)}>
                    {(!isFav) ?
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-20 w-20" fill="none" viewBox="0 0 24 24" stroke="#808080">
                            <path stroke-linecap="round" strokeLinejoin="round" stroke-width="2" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                        </svg>
                        :
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-20 w-20" viewBox="0 0 20 20" fill="red">
                            <path fill-rule="evenodd" d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z" clip-rule="evenodd" />
                        </svg>
                    }
                </span>
            </div>
            {(isLive) ?
                <div 
                    className="feed_btn"
                    onClick={() => {
                        checkAuth();
                        dispatch(getCaptcha());
                    }}
                >
                    { feedAuthLoading ? 
                        <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                            <PulseLoader loading={feedAuthLoading} color='#01BFD9'/>
                        </div>
                        :
                        "Feed"
                    }
                </div>
                :
                <div 
                    className="feed_btn_disable"
                    onClick={() => {
                        
                    }}
                >
                   { feedAuthLoading ? 
                        <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                            <PulseLoader loading={feedAuthLoading} color='#01BFD9'/>
                        </div>
                        :
                        "Feed"
                    }
                </div>
            }
        </div>
    )
}

export default ChamagotchiNftCard
