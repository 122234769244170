import * as type from '../types';

const initialState = {
    status: '',
    loading: false,
    mapChamWithPotionLoading: false,
    chamTraitsLoading: false,
    chamTraitsForSwapLoading: false,
    potionApplyStatus: '',
    onAfterMintPotionStatus: '',
    onAfterBurnStatus: '',
    getTokenExcludedStatus: '',
    tokensWithoutPotion: [],
    tokensMintedForPotion: [],
    chameleonPotions: [],
    potions: [],
    myPotionList: [],
    chameleonNft: [],
    chameleonTraits: [],
    tokenDetail: null,
    chamTraits: null,
    tokenDetailForSwap: null,
    chamTraitsForSwap: null,
    TokenStatusMsg: null,
    TokenStatusMsgLoading: false,
    error: null,
    message: '',
}

export default function potion(state = initialState, action) {
    switch (action.type) {
        case type.GET_TOKEN_WITHOUT_POTION:
            return {
                ...state,
                loading: true,
            }
        case type.GET_TOKEN_WITHOUT_POTION_SUCCESS:
            return {
                ...state,
                loading: false,
                tokensWithoutPotion: action.payload.tokens,
            }
        case type.GET_TOKEN_WITHOUT_POTION_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        case type.GET_ALL_POTIONS:
            return {
                ...state,
                loading: true,
            }
        case type.GET_ALL_POTIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                potions: action.payload.potions,
            }
        case type.GET_ALL_POTIONS_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        case type.ON_AFTER_MINT_POTIONS:
            return {
                ...state,
                loading: true,
            }
        case type.ON_AFTER_MINT_POTIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                onAfterMintPotionStatus: action.payload.status,
            }
        case type.ON_AFTER_MINT_POTIONS_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        case type.MAP_CHAMELEON_WITH_POTION:
            return {
                ...state,
                mapChamWithPotionLoading: true,
            }
        case type.MAP_CHAMELEON_WITH_POTION_SUCCESS:
            return {
                ...state,
                mapChamWithPotionLoading: false,
            }
        case type.MAP_CHAMELEON_WITH_POTION_FAILED:
            return {
                ...state,
                mapChamWithPotionLoading: false,
                error: action.payload,
            }
        case type.GET_LIST_OF_POTION_MINT_TOKENS:
            return {
                ...state,
                loading: true,
            }
        case type.GET_LIST_OF_POTION_MINT_TOKENS_SUCCESS:
            return {
                ...state,
                loading: false,
                tokensMintedForPotion: action.payload.tokenList,
            }
        case type.GET_LIST_OF_POTION_MINT_TOKENS_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        case type.GET_LIST_OF_CHAMELEON_POTIONS:
            return {
                ...state,
                loading: true,
            }
        case type.GET_LIST_OF_CHAMELEON_POTIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                chameleonPotions: action.payload.tokenList,
            }
        case type.GET_LIST_OF_CHAMELEON_POTIONS_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        case type.ON_AFTER_BURN:
            return {
                ...state,
                loading: true,
            }
        case type.ON_AFTER_BURN_SUCCESS:
            return {
                ...state,
                loading: false,
                onAfterBurnStatus: action.payload.status,
            }
        case type.ON_AFTER_BURN_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        case type.GET_TOKEN_DETAIL:
            return {
                ...state,
                chamTraitsLoading: true,
            }
        case type.GET_TOKEN_DETAIL_SUCCESS:
            return {
                ...state,
                chamTraitsLoading: false,
                tokenDetail: action.payload.tokenDetail,
                chamTraits: action.payload.chamTraits,
            }
        case type.GET_TOKEN_DETAIL_FAILED:
            return {
                ...state,
                chamTraitsLoading: false,
                error: action.payload,
            }
        case type.GET_TOKEN_DETAIL_FOR_SWAP:
            return {
                ...state,
                chamTraitsForSwapLoading: true,
            }
        case type.GET_TOKEN_DETAIL_FOR_SWAP_SUCCESS:
            return {
                ...state,
                chamTraitsForSwapLoading: false,
                tokenDetailForSwap: action.payload.tokenDetailForSwap,
                chamTraitsForSwap: action.payload.chamTraitsForSwap,
            }
        case type.GET_TOKEN_DETAIL_FOR_SWAP_FAILED:
            return {
                ...state,
                chamTraitsForSwapLoading: false,
                error: action.payload,
            }
        case type.GET_MY_POTION:
            return {
                ...state,
                loading: true,
            }
        case type.GET_MY_POTION_SUCCESS:
            return {
                ...state,
                loading: false,
                myPotionList: action.payload.myPotionList,
            }
        case type.GET_MY_POTION_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        case type.POTION_APPLY:
            return {
                ...state,
                loading: true,
            }
        case type.POTION_APPLY_SUCCESS:
            return {
                ...state,
                loading: false,
                potionApplyStatus: action.payload.status,
            }
        case type.POTION_APPLY_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        case type.GET_TOKEN_EXCLUDED:
            return {
                ...state,
                loading: true,
            }
        case type.GET_TOKEN_EXCLUDED_SUCCESS:
            return {
                ...state,
                loading: false,
                chameleonNft: action.payload.chameleonNft,
                getTokenExcludedStatus: action.payload.status,
                chameleonTraits: action.payload.chameleonTraits,
            }
        case type.GET_TOKEN_EXCLUDED_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        case type.IS_TOKEN_ALTERED:
            return {
                ...state,
                TokenStatusMsgLoading: true,
            }
        case type.IS_TOKEN_ALTERED_SUCCESS:
            return {
                ...state,
                TokenStatusMsgLoading: false,
                TokenStatusMsg: action.payload.statusMsg,
            }
        case type.IS_TOKEN_ALTERED_FAILED:
            return {
                ...state,
                TokenStatusMsgLoading: false,
                error: action.payload,
            }
        case type.GET_TOKEN_BURNED:
            return {
                ...state,
                loading: true,
            }
        case type.GET_TOKEN_BURNED_SUCCESS:
            return {
                ...state,
                loading: false,
                burnedPotionNfts: action.payload.burnedPotionNfts,
            }
        case type.GET_TOKEN_BURNED_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        case type.RESET_POTION:
            return {
                ...state,
                mapChamWithPotionLoading: false,
                potionApplyStatus: '',
                onAfterMintPotionStatus: '',
                onAfterBurnStatus: '',
                error: '',
                TokenStatusMsg: '',
            }    
        default:
            return state;
    }
}
