import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FaInfoCircle, FaEye, FaEyeSlash, FaExchangeAlt, FaUndo } from "react-icons/fa";
import { PulseLoader, GridLoader } from "react-spinners";
import { toast } from 'react-toastify';
import { utils, BigNumber, ethers } from 'ethers';
import {nftContract, signer,iface,provider,ethereum} from '../../chamPotionVenderWeb3';
import { getTokenByUser } from '../../redux/actions/users';
import { 
  getListOfPotionMintTokens, 
  getMyPotion, 
  getTokenDetail, 
  getTokenDetailForSwap, 
  getTokenExcluded, 
  mapChameleonWithPotion, 
  onAfterBurn, 
  potionApply, 
  resetPotion } from '../../redux/actions/potion';
import './MintedForPotionCardWrapper.css';
import BurnedForPotionCardWrapper from '../BurnedForPotionCardWrapper/BurnedForPotionCardWrapper';

function MintedForPotionCardWrapper() {
  const [isShowMore, setIsShowMore] = useState(false);
  const [burnTokenLoading, setBurnTokenLoading] = useState(false);
  const [selectedPotionIdToBurn, setSelectedPotionIdToBurn] = useState();
  const [potionMintedTokenList, setPotionMintedTokenList] = useState([]);
  const [myPotionsArray, setMyPotionsArray] = useState([]);
  const [chameleonTraits, setChameleonTraits] = useState([]);
  const [potionNftIdToBurn, setPotionNftIdToBurn] = useState();
  const [isSelectingPotionType, setIsSelectingPotionType] = useState(false);
  const [isPotionBurnModelOpen, setIsPotionBurnModelOpen] = useState(false);
  const [isPotionMintModel2Open, setIsPotionMintModel2Open] = useState(false);
  const [selectedTokenToChoosePotion, setSelectedTokenToChoosePotion] = useState();
  const [selectedToChoosePotionTypeArr, setSelectedToChoosePotionTypeArr] = useState([]);
  const [selectedToChoosePotionTypeArrLength, setSelectedToChoosePotionTypeArrLength] = useState([]);
  const [isWithProp, setIsWithProp] = useState(true);
  const [isPrimaryTokenOverlay, setIsPrimaryTokenOverlay] = useState({});
  const [isPrimaryTokenProp, setIsPrimaryTokenProp] = useState({});
  
  // local states for conditional rendering of dropdowns 
  const [localPotionState, setLocalPotionState] = useState({});
  const [localMutationPartValue, setLocalMutationPartValue] = useState(null);
  const [localSwapTokenIdValue, setLocalSwapTokenIdValue] = useState(null);
  const [localSwapTokenValue, setLocalSwapTokenValue] = useState(null);
  const [localSwapTrait1Value, setLocalSwapTrait1Value] = useState(null);
  const [localSwapToken2Value, setLocalSwapToken2Value] = useState(null);
  const [localSwapTrait2Value, setLocalSwapTrait2Value] = useState(null);
  const [potionToken, setPotionToken] = useState();

  // local state for Potion Overlay
  const [potionOverlayDisplay, setPotionOverlayDisplay] = useState(true);
  const [potionPropDisplay, setPotionPropDisplay] = useState(false);
  const [localChamTraits, setLocalChamTraits] = useState([]);
  const [localChamTraitsForSwap, setLocalChamTraitsForSwap] = useState([]);
  const [localChamTraitsAlt, setLocalChamTraitsAlt] = useState([]);
  const [localChamTraitsForSwapAlt, setLocalChamTraitsForSwapAlt] = useState([]);
  const [localChamTraitsIMD, setLocalChamTraitsIMD] = useState([]);
  const [localChamTraitsForSwapIMD, setLocalChamTraitsForSwapIMD] = useState([]);
  const [potionOverlayAssets] = useState([
    { potionOverlayId: 1, potionOverlayImg: 'img/cham-potion-assets/TraitSwap.png', },
    { potionOverlayId: 2, potionOverlayImg: 'img/cham-potion-assets/PropRemoval.png', },
    { potionOverlayId: 3, potionOverlayImg: 'img/cham-potion-assets/Noir.png', },
    { potionOverlayId: 4, potionOverlayImg: 'img/cham-potion-assets/Holographic.png', },
    { potionOverlayId: 5, potionOverlayImg: 'img/cham-potion-assets/MutantColorPotion.png', },
    { potionOverlayId: 6, potionOverlayImg: 'img/cham-potion-assets/RobotTraitPygmyColors.png', },
    { potionOverlayId: 7, potionOverlayImg: 'img/cham-potion-assets/PygmyColorPotion.png', },
    { potionOverlayId: 8, potionOverlayImg: 'img/cham-potion-assets/RobotTraitGoldPotion.png', },
    { potionOverlayId: 9, potionOverlayImg: 'img/cham-potion-assets/RobotTraitBlackPotion.png', },
    { potionOverlayId: 10, potionOverlayImg: 'img/cham-potion-assets/RobotTraitWhitePotion.png', },
    { potionOverlayId: 11, potionOverlayImg: 'img/cham-potion-assets/RobotTraitPastelPotion.png', },
    { potionOverlayId: 12, potionOverlayImg: 'img/cham-potion-assets/MutantGoldPotion.png', },
    { potionOverlayId: 13, potionOverlayImg: 'img/cham-potion-assets/MutantBlackPotion.png', },
    { potionOverlayId: 14, potionOverlayImg: 'img/cham-potion-assets/MutantWhitePotion.png', },
    { potionOverlayId: 15, potionOverlayImg: 'img/cham-potion-assets/MutantPastelPotion.png', },
    { potionOverlayId: 16, potionOverlayImg: 'img/cham-potion-assets/PygmyGoldPotion.png', },
    { potionOverlayId: 17, potionOverlayImg: 'img/cham-potion-assets/PygmyBlackPotion.png', },
    { potionOverlayId: 18, potionOverlayImg: 'img/cham-potion-assets/PygmyWhitePotion.png', },
    { potionOverlayId: 19, potionOverlayImg: 'img/cham-potion-assets/PygmyPastelPotion.png', },
    { potionOverlayId: 20, potionOverlayImg: 'img/cham-potion-assets/TastyCham.png', },
    { potionOverlayId: 21, potionOverlayImg: 'img/cham-potion-assets/TastyChamGold.png', },
    { potionOverlayId: 22, potionOverlayImg: 'img/cham-potion-assets/TastyChamBlack.png', },
    { potionOverlayId: 23, potionOverlayImg: 'img/cham-potion-assets/TastyChamWhite.png', },
    { potionOverlayId: 24, potionOverlayImg: 'img/cham-potion-assets/TastyChamPastel.png', },
    { potionOverlayId: 25, potionOverlayImg: 'img/cham-potion-assets/AshCham.jpeg', },
    { potionOverlayId: 26, potionOverlayImg: 'img/cham-potion-assets/DoodleCham.png', },
    { potionOverlayId: 27, potionOverlayImg: 'img/cham-potion-assets/InvisibleCham.png', },
    { potionOverlayId: 28, potionOverlayImg: 'img/cham-potion-assets/Raindrops.png', },
    { potionOverlayId: 29, potionOverlayImg: 'img/cham-potion-assets/GoldenTicket.jpeg', },
    { potionOverlayId: 30, potionOverlayImg: 'img/cham-potion-assets/TreasureChest5E.png', },
    { potionOverlayId: 31, potionOverlayImg: 'img/cham-potion-assets/TreasureChest25E.png', },
  ])

  const history = useHistory();
  const dispatch = useDispatch();
  const tokensMintedForPotion = useSelector(state => state.potion.tokensMintedForPotion);
  const potions = useSelector(state => state.potion.potions);
  const chameleonNft = useSelector(state => state.potion.chameleonNft);
  // const chameleonTraits = useSelector(state => state.users.chameleonTraits);
  const tokenDetail = useSelector(state => state.potion.tokenDetail);
  const chamTraits = useSelector(state => state.potion.chamTraits);
  const tokenDetailForSwap = useSelector(state => state.potion.tokenDetailForSwap);
  const chamTraitsForSwap = useSelector(state => state.potion.chamTraitsForSwap);
  const myPotionList = useSelector(state => state.potion.myPotionList);
  const loading = useSelector(state => state.potion.loading);
  const mapChamWithPotionLoading = useSelector(state => state.potion.mapChamWithPotionLoading);
  const chamTraitsLoading = useSelector(state => state.potion.chamTraitsLoading);
  const chamTraitsForSwapLoading = useSelector(state => state.potion.chamTraitsForSwapLoading);
  const potionApplyStatus = useSelector(state => state.potion.potionApplyStatus);
  const onAfterBurnStatus = useSelector(state => state.potion.onAfterBurnStatus);
  const error = useSelector(state => state.potion.error);

  useEffect(() => {
    dispatch(getTokenExcluded());
    dispatch(getListOfPotionMintTokens());
  }, []);

  useEffect(() => {
    setPotionMintedTokenList(chameleonNft);
  }, [chameleonNft]);

  useEffect(() => {
    setLocalChamTraits(chamTraits);
    let primaryOverlay = chamTraits?.filter((trait) => {return trait.traitType == 'Overlay'})
    if(primaryOverlay) {
      let primaryOverlayObj = primaryOverlay[0];
      setIsPrimaryTokenOverlay(primaryOverlayObj);
    }
    let primaryProp = chamTraits?.filter((trait) => {return trait.traitType == 'Prop'})
    if(primaryProp) {
      let primaryPropObj = primaryProp[0];
      setIsPrimaryTokenProp(primaryPropObj);
    }
  }, [chamTraits]);

  useEffect(() => {
    setLocalChamTraitsForSwap(chamTraitsForSwap);
  }, [chamTraitsForSwap]);

  useEffect(() => {
    setMyPotionsArray(myPotionList);
  }, [myPotionList]);

  useEffect(() => {
    if(localPotionState.value == 2) {
      let tempChamTraits = chamTraits?.filter((trait) => {return trait.traitType != 'Prop'})
      if(chamTraits?.filter((trait) => {return trait.traitType == 'Prop'}).length <= 0){
        setIsWithProp(false);
        toast.error( "Selected NFT does not have prop, please select another Potion" , {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        },);
      }else{
        setLocalChamTraits(tempChamTraits);
      }
    }else {
      setLocalChamTraits(chamTraits);
    }
  }, [localPotionState]);

  
  const setChamsForPotionTypeSelection = (e) => {
    const {name, checked} = e.target;
    let tempPotionsChamNft = potionMintedTokenList.map((nft) => 
        nft.tokenId == name ? {...nft, isCheckedForPotionType: checked} : {...nft, isCheckedForPotionType: false} 
    );
    setPotionMintedTokenList(tempPotionsChamNft);
  }
  
  useEffect(() => {
    let tempPotions = potionMintedTokenList?.filter((nft) => {return nft.isCheckedForPotionType === true})
    setSelectedToChoosePotionTypeArrLength(tempPotions.length);
    setSelectedToChoosePotionTypeArr(tempPotions)
    tempPotions.length && setSelectedTokenToChoosePotion(tempPotions[0].token) 
    tempPotions.length && setSelectedPotionIdToBurn(tempPotions[0].tokenId);
  }, [potionMintedTokenList]);

  const selectForPotionsCham = (e) => {
    const {name, value} = e.target;
    let tempPotionObj = myPotionList?.filter((potion) => {return potion.potionNftId == value});
    let potionObj = tempPotionObj[0]

    setPotionNftIdToBurn(value)
    if(potionObj.potionId == 1) {
      let tempChamsForPotion = selectedToChoosePotionTypeArr.map((nft) => 
        nft.tokenId == name ? {...nft, potionNftId: value, potionId: potionObj.potionId } : nft
      );
      setSelectedToChoosePotionTypeArr(tempChamsForPotion);
      setLocalPotionState({name: name, value: potionObj.potionId});
    }else {
      let tempChamsForPotion = selectedToChoosePotionTypeArr.map((nft) => 
        nft.tokenId == name ? {...nft, potionNftId: value, potionId: potionObj.potionId, swapTokenId: null, swapTrait1: null, swapTrait2: null } : nft
      );
      setSelectedToChoosePotionTypeArr(tempChamsForPotion);
      setLocalPotionState({name: name, value: potionObj.potionId});
    }
    
    // set Local PotionToken

    setPotionToken(Number(potionObj.token));

  }

  // const selectMutationTypeForPotionsCham = (e) => {
  //   const {name, value} = e.target;
  //   let tempChamsForPotion = selectedToChoosePotionTypeArr.map((nft) => 
  //     nft.tokenId == name ? {...nft, mutateBody: value} : nft
  //   );
  //   setSelectedToChoosePotionTypeArr(tempChamsForPotion);
  //   setLocalMutationPartValue(value);
  // }

  const refreshSelectTokenForSwap  = (e) => {
    let tempChamsForPotionRefresh = selectedToChoosePotionTypeArr.map((nft) => 
      nft ? {...nft, swapTokenId: null, swapTrait1: null, swapTrait2: null} : nft
    );
    setSelectedToChoosePotionTypeArr(tempChamsForPotionRefresh);
    setLocalSwapTokenIdValue(null);
    // let tempChamsForPotionOnRefresh1 = selectedToChoosePotionTypeArr.map((nft) => 
    //   nft ? {...nft, swapTrait1: null} : nft
    // );
    // setSelectedToChoosePotionTypeArr(tempChamsForPotionOnRefresh1);
    setLocalSwapTrait1Value(null);
    // let tempChamsForPotionOnRefresh2 = selectedToChoosePotionTypeArr.map((nft) => 
    //   nft ? {...nft, swapTrait2: null} : nft
    // );
    // setSelectedToChoosePotionTypeArr(tempChamsForPotionOnRefresh2);
    setLocalSwapTrait2Value(null);
    setLocalChamTraitsAlt([]);
    setLocalChamTraitsIMD([]);
    setLocalChamTraitsForSwapAlt([]);
    setLocalChamTraitsForSwapIMD([]);
  }
  
  const selectTokenForSwap = (e) => {
    const {name, value} = e.target;
    let tempChamsForPotion = selectedToChoosePotionTypeArr.map((nft) => 
      nft.tokenId == name ? {...nft, swapTokenId: value, swapTrait1: null, swapTrait2: null} : nft
    );
    setSelectedToChoosePotionTypeArr(tempChamsForPotion);
    setLocalSwapTokenIdValue(value);
    // setLocalSwapTokenValue(selectedToChoosePotionTypeArr.filter((nft) => {return nft.tokenId == name })[0].token);
    let tempChameleonTraits = chameleonNft?.filter((nft) => {return nft.tokenId == value})
    setChameleonTraits(tempChameleonTraits[0].attributes);
    dispatch(getTokenDetailForSwap({tokenId: value}));
    // let tempChamsForPotionOnRefresh1 = selectedToChoosePotionTypeArr.map((nft) => 
    //   nft ? {...nft, swapTrait1: null} : nft
    // );
    // setSelectedToChoosePotionTypeArr(tempChamsForPotionOnRefresh1);
    setLocalSwapTrait1Value(null);
    // let tempChamsForPotionOnRefresh2 = selectedToChoosePotionTypeArr.map((nft) => 
    //   nft ? {...nft, swapTrait2: null} : nft
    // );
    // setSelectedToChoosePotionTypeArr(tempChamsForPotionOnRefresh2);
    setLocalSwapTrait2Value(null);
    // setLocalChamTraitsAlt([]);
    // setLocalChamTraitsIMD([]);
    // setLocalChamTraitsForSwapAlt([]);
    // setLocalChamTraitsForSwapIMD([]);
  }
  
  // const removeOverlay = (array, setArray ) => {
  //   let tempArray = array?.map((item) => {
  //     item.traitType === 'Overlay' ? null : item
  //   })
  //   setArray(() => tempArray)
  //   // console.log(array);
  //   // console.log(tempArray);
  // }

  // removeOverlay(localChamTraits, setLocalChamTraits);

  const selectSwapTrait1 = (e) => {
    const {name, value} = e.target;
    let tempChamsForPotion = selectedToChoosePotionTypeArr.map((nft) => 
      nft.tokenId == name ? {...nft, swapTrait1: value, swapTrait2: null} : nft
    );
    setSelectedToChoosePotionTypeArr(tempChamsForPotion);
    setLocalSwapTrait1Value(value);

    // state for img 1
    let newSwapTrait = chamTraitsForSwap?.filter((trait) => {return trait.traitType == value})
    let newSwapTraitObj;
    let newTempChamTraits;
    let newTempChamOverlay;
    let primaryOverlay = chamTraits?.filter((trait) => {return trait.traitType == 'Overlay'})
    let primaryOverlayObj = primaryOverlay[0];
    // console.log('primaryOverlay :', primaryOverlay)
    // console.log('primaryOverlayObj :', primaryOverlayObj)
    
    // state for img 2
    let newSwapTraitForSwap = chamTraits?.filter((trait) => {return trait.traitType == value})
    let newSwapTraitForSwapObj;
    let newTempChamTraitsForSwap;
    let secondaryOverlay = chamTraitsForSwap?.filter((trait) => {return trait.traitType == 'Overlay'})
    let secondaryOverlayObj = secondaryOverlay[0];
    // console.log('secondaryOverlayObj :', secondaryOverlayObj)

    // logic for img 1
    if(newSwapTrait) {
      newSwapTraitObj = newSwapTrait[0];
      newTempChamTraits = chamTraits?.map((trait) => 
        trait.traitType == value ? {...trait, url:newSwapTraitObj.url , value: newSwapTraitObj.value } : trait
      );
      setLocalChamTraitsAlt(newTempChamTraits);
      setLocalChamTraitsIMD(newTempChamTraits);
      if(value === 'Background'){
        if(primaryOverlayObj) {
          if(secondaryOverlayObj) {
            // console.log("both img has overlay , add overlay to img-1 from img-2")
            newTempChamOverlay = chamTraits?.map((trait) => 
              trait.traitType == 'Overlay' ? {...trait, url:secondaryOverlayObj.url , value: secondaryOverlayObj.value} : trait
            );
            let newTempChamTraitsIMD = newTempChamOverlay?.map((trait) => 
              trait.traitType == value ? {...trait, url:newSwapTraitObj.url , value: newSwapTraitObj.value } : trait
            );
            setLocalChamTraitsAlt(newTempChamTraitsIMD);
            setLocalChamTraitsIMD(newTempChamTraitsIMD);
          }else {
            // console.log("primary img has overlay and 2ndry img does not, remove overlay at img-1")
            // console.log("newTempChamOverlayIMD chamTraits:",chamTraits)
            newTempChamOverlay = chamTraits?.map((trait) => 
              trait.traitType == "Overlay" ? {...trait, traitType: null, url: null, value:null} : trait
            );
            let newTempChamOverlayIMD = newTempChamOverlay?.map((trait) => 
              trait.traitType == value ? {...trait, url:newSwapTraitObj.url , value: newSwapTraitObj.value } : trait
            );
            setLocalChamTraitsAlt(newTempChamOverlayIMD);
            setLocalChamTraitsIMD(newTempChamOverlayIMD);
          }
        }
      }else {
        setLocalChamTraitsAlt(newTempChamTraits);
        setLocalChamTraitsIMD(newTempChamTraits);
        // console.log("primary token does not have overlay")
      }
    }
    
    // logic for img 2
    if(newSwapTraitForSwap) {
      newSwapTraitForSwapObj = newSwapTraitForSwap[0];
      newTempChamTraitsForSwap = chamTraitsForSwap?.map((trait) => 
        trait.traitType == value ? {...trait, url:newSwapTraitForSwapObj.url , value: newSwapTraitForSwapObj.value } : trait
      );
      setLocalChamTraitsForSwapAlt(newTempChamTraitsForSwap);
      setLocalChamTraitsForSwapIMD(newTempChamTraitsForSwap);
      if(value === 'Background'){
        if(primaryOverlayObj) {
          if(secondaryOverlayObj) {
            // console.log("both img has overlay , add overlay to img-2 form img-1")
            newTempChamTraitsForSwap = chamTraitsForSwap?.map((trait) => 
              trait.traitType == 'Overlay' ? {...trait, url:primaryOverlayObj.url , value: primaryOverlayObj.value} : trait
            );
            let newTempChamTraitsForSwapIMD = newTempChamTraitsForSwap?.map((trait) => 
              trait.traitType == value ? {...trait, url:newSwapTraitForSwapObj.url , value: newSwapTraitForSwapObj.value } : trait
            );
            setLocalChamTraitsForSwapAlt(newTempChamTraitsForSwapIMD);
            setLocalChamTraitsForSwapIMD(newTempChamTraitsForSwapIMD);
          }else {
            // console.log("primary img has overlay and 2ndry img does not, add overlay to img-2 from img-1")
            setLocalChamTraitsForSwapAlt(localChamTraitsForSwapAlt => [...localChamTraitsForSwapAlt, primaryOverlayObj])
            setLocalChamTraitsForSwapIMD(localChamTraitsForSwapIMD => [...localChamTraitsForSwapIMD, primaryOverlayObj])
            // newTempChamOverlayForSwap = chamTraitsForSwap?.map((trait) => 
            //   trait.traitType == 'Overlay' ? {} : trait
            // );
            // setLocalChamTraitsForSwapAlt(newTempChamOverlayForSwap);
            // setLocalChamTraitsForSwapIMD(newTempChamOverlayForSwap);
          }
        }
      }else {
        setLocalChamTraitsForSwapAlt(newTempChamTraitsForSwap);
        setLocalChamTraitsForSwapIMD(newTempChamTraitsForSwap);
      }
    }
    // let tempChamsForPotionOnRefresh2 = selectedToChoosePotionTypeArr.map((nft) => 
    //   nft ? {...nft, swapTrait2: null} : nft
    // );
    // setSelectedToChoosePotionTypeArr(tempChamsForPotionOnRefresh2);
    setLocalSwapTrait2Value(null);
    // setLocalChamTraitsForSwapAlt([]);
    // setLocalChamTraitsForSwapIMD([]);
  }
  
  // const selectToken2ForSwap = (e) => {
  //   const {name, value} = e.target;
  //   let tempChamsForPotion = selectedToChoosePotionTypeArr.map((nft) => 
  //     nft.tokenId == name ? {...nft, swapToken2: value} : nft
  //   );
  //   setSelectedToChoosePotionTypeArr(tempChamsForPotion);
  //   setLocalSwapToken2Value(value);
  // }
    
  const selectSwapTrait2 = (e) => {
    const {name, value} = e.target;
    let tempChamsForPotion = selectedToChoosePotionTypeArr.map((nft) => 
      nft.tokenId == name ? {...nft, swapTrait2: value} : nft
    );
    setSelectedToChoosePotionTypeArr(tempChamsForPotion);
    setLocalSwapTrait2Value(value);
    // state for img 1
    let newSwapTrait = chamTraitsForSwap?.filter((trait) => {return trait.traitType == value})
    let newSwapTraitObj;
    let newTempChamTraits;
    let newTempChamOverlay;
    let primaryOverlay = chamTraits?.filter((trait) => {return trait.traitType == 'Overlay'})
    let primaryOverlayObj = primaryOverlay[0];
    // console.log('primaryOverlayObj :', primaryOverlayObj)
    
    // state for img 2
    let newSwapTraitForSwap = chamTraits?.filter((trait) => {return trait.traitType == value})
    let newSwapTraitForSwapObj;
    let newTempChamTraitsForSwap;
    let secondaryOverlay = chamTraitsForSwap?.filter((trait) => {return trait.traitType == 'Overlay'})
    let secondaryOverlayObj = secondaryOverlay[0];
    // console.log('secondaryOverlayObj :', secondaryOverlayObj)

    // logic for img 1
    if(newSwapTrait) {
      newSwapTraitObj = newSwapTrait[0];
      newTempChamTraits = localChamTraitsIMD?.map((trait) => 
        trait.traitType == value ? {...trait, url:newSwapTraitObj.url , value: newSwapTraitObj.value } : trait
      );
      setLocalChamTraitsAlt(newTempChamTraits);
      if(value === 'Background'){
        if(primaryOverlayObj) {
          if(secondaryOverlayObj) {
            // console.log("both img has overlay , add overlay to img-1 from img-2")
            newTempChamOverlay = localChamTraitsIMD?.map((trait) => 
              trait.traitType == 'Overlay' ? {...trait, url:secondaryOverlayObj.url , value: secondaryOverlayObj.value} : trait
            );
            let newTempChamTraitsIMD = newTempChamOverlay?.map((trait) => 
              trait.traitType == value ? {...trait, url:newSwapTraitObj.url , value: newSwapTraitObj.value } : trait
            );
            setLocalChamTraitsAlt(newTempChamTraitsIMD);
          }else {
            // console.log("primary img has overlay and 2ndry img does not, remove overlay at img-1")
            // console.log("newTempChamOverlayIMD chamTraits:",chamTraits)
            newTempChamOverlay = localChamTraitsIMD?.map((trait) => 
              trait.traitType == "Overlay" ? {...trait, traitType: null, url: null, value:null} : trait
            );
            let newTempChamOverlayIMD = newTempChamOverlay?.map((trait) => 
              trait.traitType == value ? {...trait, url:newSwapTraitObj.url , value: newSwapTraitObj.value } : trait
            );
            setLocalChamTraitsAlt(newTempChamOverlayIMD);
          }
        }
      }else {
        setLocalChamTraitsAlt(newTempChamTraits);
        // console.log("primary token does not have overlay")
      }
    }
    // for img 2

    if(newSwapTraitForSwap) {
      newSwapTraitForSwapObj = newSwapTraitForSwap[0];
      newTempChamTraitsForSwap = localChamTraitsForSwapIMD?.map((trait) => 
        trait.traitType == value ? {...trait, url:newSwapTraitForSwapObj.url , value: newSwapTraitForSwapObj.value } : trait
      );
      setLocalChamTraitsForSwapAlt(newTempChamTraitsForSwap);
      if(value === 'Background'){
        if(primaryOverlayObj) {
          if(secondaryOverlayObj) {
            // console.log("both img has overlay , add overlay to img-2 form img-1")
            newTempChamTraitsForSwap = localChamTraitsForSwapIMD?.map((trait) => 
              trait.traitType == 'Overlay' ? {...trait, url:primaryOverlayObj.url , value: primaryOverlayObj.value} : trait
            );
            let newTempChamTraitsForSwapIMD = newTempChamTraitsForSwap?.map((trait) => 
              trait.traitType == value ? {...trait, url:newSwapTraitForSwapObj.url , value: newSwapTraitForSwapObj.value } : trait
            );
            setLocalChamTraitsForSwapAlt(newTempChamTraitsForSwapIMD);
          }else {
            // console.log("primary img has overlay and 2ndry img does not, add overlay to img-2 from img-1")
            setLocalChamTraitsForSwapAlt(localChamTraitsForSwapAlt => [...localChamTraitsForSwapAlt, primaryOverlayObj])
            // newTempChamOverlayForSwap = chamTraitsForSwap?.map((trait) => 
            //   trait.traitType == 'Overlay' ? {} : trait
            // );
            // setLocalChamTraitsForSwapAlt(newTempChamOverlayForSwap);
            // setLocalChamTraitsForSwapIMD(newTempChamOverlayForSwap);
          }
        }
      }else {
        setLocalChamTraitsForSwapAlt(newTempChamTraitsForSwap);
      }
    }
  }

  // Burn Token handler 
  const burnNftHandler = async () => {
    try {
      setBurnTokenLoading(true);
      // at a later point in Time
      await ethereum.enable();
      // console.log("Potion ToBurn token no:",potionToken)
      let finney = 1000000000000000;
      // calculate the no of token is to be minted
        let tempBalance = await provider.getBalance(signer.getAddress());
        // console.log("tempBalance :",ethers.utils.formatEther(tempBalance))
        // check if price value is correct or not
        if(ethers.utils.formatEther(tempBalance)) {
          const sender = await  signer.getAddress()
          let nftTxn = await nftContract.burn(potionToken,{
            from: sender,
          });
          // console.log("txnObject : ", nftTxn)
          if(nftTxn && potionNftIdToBurn) {
            setBurnTokenLoading(false);
            dispatch(onAfterBurn({potionNftId: Number(potionNftIdToBurn), burnHash: nftTxn.hash}));
            // console.log(`{tokenId: ${selectedPotionIdToBurn}, burnHash: ${nftTxn.hash}}`);
          }
      }else{
        alert("Oops!! Insufficient balance")
        setBurnTokenLoading(false);
      }
    }catch (err) {
      setBurnTokenLoading(false);
      // console.log(err);
      alert(err.message);
    }
  }
  // Burn handler end

  useEffect(() => {
    if(potionApplyStatus == 200) {
      setIsPotionBurnModelOpen(true)
    }
    return () => {
      dispatch(resetPotion());
    }
  }, [potionApplyStatus]);

  useEffect(() => {
    if(onAfterBurnStatus == 200) {
      setIsSelectingPotionType(false);
      setIsShowMore(false);
      // setPotionMintedTokenList([]);
      setIsSelectingPotionType(false);
      setIsPotionMintModel2Open(false);
      setSelectedToChoosePotionTypeArr([]);
      setSelectedToChoosePotionTypeArrLength([]);
      setLocalPotionState({});
      setLocalMutationPartValue(null);
      setLocalSwapTokenIdValue(null);
      setLocalSwapTrait1Value(null);
      setLocalSwapToken2Value(null);
      setLocalSwapTrait2Value(null);
      setSelectedTokenToChoosePotion(null);
      dispatch(getListOfPotionMintTokens());
      setIsPotionBurnModelOpen(false);
      setPotionToken(false);
      let tempPotionsChamNft = potionMintedTokenList.map((nft) => 
        nft && {...nft, isCheckedForPotionType: false} 
      );
      setPotionMintedTokenList(tempPotionsChamNft);
    }
    return () => {
      dispatch(resetPotion());
      setIsPotionBurnModelOpen(false)
      // setSelectedTokenToChoosePotion(null);
      setPotionToken(false);
    }
  }, [onAfterBurnStatus]);

  // useEffect(() => {
  //   if(localPotionState.value != 2){
  //     setPotionPropDisplay(true);
  //   }else{
  //     setPotionPropDisplay(false);
  //   }
  // }, [localPotionState]);

  return (
    <Fragment>
      {!isSelectingPotionType ?
        <div className='potions_nft_2_card_wrapper mx-auto m-md-4 mb-4'>
          <div className="potions_nft_2_card_wrapper_heading d-flex justify-content-between">
            <h1 className="nft_card_heading text-uppercase p-4 m-0 pointer-cursor">select &nbsp; potion &nbsp; type</h1>
            <span className="down_icon_2 m-4 down_icon_primary" onClick={() => setIsShowMore(!isShowMore)}>
                {(!isShowMore) ?
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-40 w-40" viewBox="0 0 20 20" fill="currentColor">
                      <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                  </svg>
                  :
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-40 w-40" viewBox="0 0 20 20" fill="currentColor">
                      <path fill-rule="evenodd" d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z" clip-rule="evenodd" />
                  </svg>
                }
            </span>
          </div>
          {(potionMintedTokenList.length && !loading) ?
            <div className="d-flex justify-content-center align-items-center my-2">
              <FaInfoCircle size={20} className='text-info'/>
              &nbsp;
              Select NFTs to choose Potion type for each 
            </div>
            :
            null
          }
          <div className="d-flex flex-wrap p-2">
            {
              loading &&
              <div className="d-flex justify-content-center align-item-end w-100 p-5">
                <GridLoader loading={loading} size={30} color='#01BFD9'/>
              </div>
            }
            {(potionMintedTokenList.length && !loading) ?
              potionMintedTokenList?.map(( nftItem, index ) => {
                return(
                    <div 
                      className={`${(!isShowMore) ? ( index >= 6 )? "d-none" : "potions_nft_2_card d-flex flex-column p-2" : "potions_nft_2_card d-flex flex-column p-2"}`}
                    >
                      <img src={nftItem.image ? nftItem.image : 'img/blank-chamagotchi.jpg'} alt="" className="" />
                      <div className="d-flex justify-content-between align-item-center p-2">
                        <span className="tokan_no">{nftItem.token}</span>
                        <div className="form-check ">
                          <input type="checkbox" 
                            className=" form-check-input custom-checkbox cursor-pointer cursor-pointer" 
                            id="materialUnchecked"
                            name={nftItem.tokenId} 
                            checked={nftItem.isCheckedForPotionType || false}
                            onChange={setChamsForPotionTypeSelection}
                          />
                        </div>
                      </div>
                    </div>
                  )
                }
              )
              : (!loading) ?
              <div className="d-flex justify-content-center align-items-end w-100 p-4">
                <img src="img/no-data.png" alt="" className="" />
                <h4 className="text-danger my-2">You don't have any Potion NFT associated With this account !!!</h4>
              </div>
              :
              null
            }
            {(!isShowMore && (potionMintedTokenList.length > 7) && !loading) ?
              <div className="nft-show-more-card d-flex d-md-none justify-content-center align-item-center">
                <button className="btn btn-outline-info" onClick={() => setIsShowMore(true)}>
                  {"More >>"}
                </button>
              </div>
              :( (potionMintedTokenList.length > 7) && !loading) ?
                <div className="nft-show-more-card d-flex d-md-none justify-content-center align-item-center">
                  <button className="btn btn-outline-info" onClick={() => setIsShowMore(false)}>
                    {"collapse <<"}
                  </button>
                </div>
              :
              null
            }
          </div>
          <button 
            type="button" 
            className="btn btn-primary d-flex align-items-center ml-auto mr-4 mb-2"
            onClick={() => {
              setIsPotionMintModel2Open(true);
            }}
            disabled={selectedToChoosePotionTypeArrLength < 1}
          >
            select potions
          </button>
          <div className={`${isPotionMintModel2Open ? "potion-mint-modal-wrapper d-flex justify-content-center align-items-center position-fixed" : "d-none"}`}>
            <div className="potion-mint-modal">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLongTitle">Confirmation</h5>
                  <button type="button" className="close" onClick={() => {setIsPotionMintModel2Open(false);}}>
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <span className="p-4">
                    {"You are ready to select Potions for token no. "} 
                    <b>{selectedTokenToChoosePotion && selectedTokenToChoosePotion}</b> 
                  </span>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" onClick={() => {setIsPotionMintModel2Open(false);}}>Cancel</button>
                  <button 
                    type="button" 
                    className="btn btn-primary" 
                    onClick={() => {
                        dispatch(getTokenDetail({tokenId: selectedToChoosePotionTypeArr[0].tokenId}));
                        dispatch(getMyPotion({ tokenId: selectedToChoosePotionTypeArr[0].tokenId }));
                        setIsSelectingPotionType(true);
                      }
                    }
                  >
                    Confirm
                  </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      :
      <Fragment>
        <div className="px-5 mx-5">
          <h1 className="mt-3 mb-3 text-warning">Applying Potion For: <b className="text-danger">{selectedToChoosePotionTypeArr && selectedToChoosePotionTypeArr[0].token}</b></h1>
          <table className="table table-bordered">
            <thead>
              <tr className="table-info">
                <th scope="col">NFT</th>
                <th scope="col">Select Potions</th>
              </tr>
            </thead>
            <tbody>
              {selectedToChoosePotionTypeArr &&
                selectedToChoosePotionTypeArr?.map((nftItem, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        {(localPotionState.value != 1) ?
                          <div className="potion_after_mint_nftImg my-4">
                            {
                              chamTraitsLoading &&
                              <div className="d-flex justify-content-center align-item-end w-100 p-5">
                                <GridLoader loading={chamTraitsLoading} size={30} color='#01BFD9'/>
                              </div>
                            }
                            {/* <img src={nftItem.imagePath ? nftItem.imagePath : 'img/blank-chamagotchi.jpg'} alt="" className="" />  */}
                            {/* <img src={nftItem.imagePath ? nftItem.imagePath : 'https://chameleoncollective.io/metadata2/580.png'} alt="" className="" />  */}
                            {!chamTraitsLoading &&
                            <div className="compose-cham-from-api position-relative mx-auto">
                              {tokenDetail && (tokenDetail.attributes.length > 0) && chamTraits.length  ?
                                localChamTraits?.map((trait, i) => {
                                  return(
                                    <img 
                                      key={i} 
                                      src={trait.url ? trait.url : null } 
                                      alt="" 
                                      className={`${(trait.traitType != 'Background') ? `compose-cham-from-api-${trait.traitType} w-100 position-absolute` : `compose-cham-from-api-${trait.traitType} w-100 position-relative`}`} 
                                    /> 
                                  )
                                })
                                :
                                null
                              }
                              {(localPotionState.value == 2) && isWithProp ?
                                <Fragment>
                                  {potionPropDisplay &&
                                    chamTraits?.filter((trait) => { return trait.traitType == 'Prop' }).map((trait, i) => {
                                      return(
                                        <img 
                                          key={i} 
                                          src={trait.url ? trait.url : null } 
                                          alt="" 
                                          className={`${`compose-cham-from-api-${trait.traitType} w-100 position-absolute`}`} 
                                        /> 
                                      )
                                    }) 
                                  }
                                  <span 
                                    className="overlay-potion-img-toggler position-absolute cursor-pointer"
                                    onClick={() => {
                                      setPotionPropDisplay(!potionPropDisplay);
                                      setTimeout(() => {
                                        setPotionPropDisplay(false);
                                      }, 4000);
                                    }}
                                  >
                                    {!potionPropDisplay ? <FaEye size={25}/> : <FaEyeSlash size={25}/> }
                                  </span>
                                </Fragment>
                                :
                                null
                              }
                              {(potionOverlayAssets && localPotionState && (localPotionState.value != 2))?
                                potionOverlayAssets?.filter((potion) => {
                                  return potion.potionOverlayId == localPotionState.value
                                })?.map((cpOverlay, index) => {
                                  return(
                                    <Fragment>
                                      {potionOverlayDisplay &&
                                        <img key={index} src={cpOverlay.potionOverlayImg} alt="" className="overlay-potion-img position-absolute w-100" /> 
                                      }
                                      <span 
                                        className="overlay-potion-img-toggler position-absolute cursor-pointer"
                                        onClick={() => {
                                          setPotionOverlayDisplay(!potionOverlayDisplay);
                                          setTimeout(() => {
                                            setPotionOverlayDisplay(true);
                                          }, 4000);
                                        }}
                                      >
                                        {!potionOverlayDisplay ? <FaEye size={25}/> : <FaEyeSlash size={25}/> }
                                      </span>
                                    </Fragment>
                                  )
                                })
                                :
                                null
                              }
                            </div>
                            }
                          </div>
                          :
                          <div className='d-flex'>
                            <div className="potion_after_mint_nftImg my-4 mx-5">
                              {chamTraitsLoading &&
                                <div className="d-flex justify-content-center align-item-end w-100 p-5">
                                  <GridLoader loading={chamTraitsLoading} size={30} color='#01BFD9'/>
                                </div>
                              }
                              {/* <img src={nftItem.imagePath ? nftItem.imagePath : 'img/blank-chamagotchi.jpg'} alt="" className="" />  */}
                              {/* <img src={nftItem.imagePath ? nftItem.imagePath : 'https://chameleoncollective.io/metadata2/580.png'} alt="" className="" />  */}
                              {!chamTraitsLoading ?
                              (localSwapTrait1Value || localSwapTrait2Value) ?
                                <div className="compose-cham-from-api-alt position-relative mx-auto">
                                  {tokenDetail && (tokenDetail.attributes.length > 0) && chamTraits.length  ?
                                    localChamTraitsAlt?.map((trait, i) => {
                                      return(
                                        <img 
                                          key={i} 
                                          src={trait.url ? trait.url : null } 
                                          alt="" 
                                          className={`${(trait.traitType != 'Background') ? `compose-cham-from-api-${trait.traitType} w-100 position-absolute` : `compose-cham-from-api-${trait.traitType} w-100 position-relative`}`} 
                                        /> 
                                      )
                                    })
                                    :
                                    null
                                  }
                                </div>
                                :
                                <div className="compose-cham-from-api-alt position-relative mx-auto">
                                  {tokenDetail && (tokenDetail.attributes.length > 0) && chamTraits.length  ?
                                    localChamTraits?.map((trait, i) => {
                                      return(
                                        <img 
                                          key={i} 
                                          src={trait.url ? trait.url : null } 
                                          alt="" 
                                          className={`${(trait.traitType != 'Background') ? `compose-cham-from-api-${trait.traitType} w-100 position-absolute` : `compose-cham-from-api-${trait.traitType} w-100 position-relative`}`} 
                                        /> 
                                      )
                                    })
                                    :
                                    null
                                  }
                                </div>
                              :
                              null
                              }
                              <div className="d-flex justify-content-center align-items-center">
                                Primary NFT: {tokenDetail && tokenDetail.token ? tokenDetail.token : null}
                                &nbsp;
                                <FaExchangeAlt size={20} className='text-info'/>
                              </div>
                            </div>
                            {localSwapTokenIdValue && (localPotionState.value == 1)?
                              <div className="potion_after_mint_nftImg my-4">
                                {chamTraitsForSwapLoading &&
                                  <div className="d-flex justify-content-center align-item-end w-100 p-5">
                                    <GridLoader loading={chamTraitsForSwapLoading} size={30} color='#01BFD9'/>
                                  </div>
                                }
                                {/* <img src={nftItem.imagePath ? nftItem.imagePath : 'img/blank-chamagotchi.jpg'} alt="" className="" />  */}
                                {/* <img src={nftItem.imagePath ? nftItem.imagePath : 'https://chameleoncollective.io/metadata2/580.png'} alt="" className="" />  */}
                                {!chamTraitsForSwapLoading ?
                                  localSwapTrait1Value || localSwapTrait2Value ?
                                    <div className="compose-cham-from-api-alt position-relative mx-auto">
                                      {tokenDetailForSwap && (tokenDetailForSwap.attributes.length > 0) && chamTraitsForSwap.length  ?
                                        localChamTraitsForSwapAlt?.map((trait, i) => {
                                          return(
                                            <img 
                                              key={i} 
                                              src={trait.url ? trait.url : null } 
                                              alt="" 
                                              className={`${(trait.traitType != 'Background') ? `compose-cham-from-api-${trait.traitType} w-100 position-absolute` : `compose-cham-from-api-${trait.traitType} w-100 position-relative`}`} 
                                            /> 
                                          )
                                        })
                                        :
                                        null
                                      }
                                    </div>
                                    :
                                    <div className="compose-cham-from-api-alt position-relative mx-auto">
                                      {tokenDetailForSwap && (tokenDetailForSwap.attributes.length > 0) && chamTraitsForSwap.length  ?
                                        chamTraitsForSwap?.map((trait, i) => {
                                          return(
                                            <img 
                                              key={i} 
                                              src={trait.url ? trait.url : null } 
                                              alt="" 
                                              className={`${(trait.traitType != 'Background') ? `compose-cham-from-api-${trait.traitType} w-100 position-absolute` : `compose-cham-from-api-${trait.traitType} w-100 position-relative`}`} 
                                            /> 
                                          )
                                        })
                                        :
                                        null
                                      }
                                    </div>
                                  :
                                  null
                                }
                                <div className="d-flex justify-content-center align-items-center">
                                  <FaExchangeAlt size={20} className='text-info'/>
                                  &nbsp;
                                  Secondary NFT: {tokenDetailForSwap && tokenDetailForSwap.token ? tokenDetailForSwap.token : null}
                                </div>
                              </div>
                              :
                              <div className="d-flex justify-content-center align-items-center">
                                <FaInfoCircle size={20} className='text-info'/>
                                &nbsp;
                                Select a token to swap traits
                              </div>
                            }
                            </div>
                          }  
                      </td>
                      <td>
                        <div className="form-group potion_after_mint_dropdown mx-auto my-2">
                          {
                            <select 
                              name={nftItem.tokenId} 
                              className="form-select" 
                              aria-label="Default select example"
                              onChange={selectForPotionsCham}
                            >
                              <option value="null" selected>Select Potion For NFT With Token No. {nftItem.token}</option>
                              {myPotionsArray &&
                                myPotionsArray?.map((potion, i) => {
                                  return (
                                    <option 
                                      key={potion.potionNftId} 
                                      value={potion.potionNftId}
                                    >
                                        {potion.potionName}
                                    </option>
                                  )
                                })
                              }
                            </select>
                          }
                        </div>
                        {/* Check for the column where these must render */}
                        {(localPotionState.name == nftItem.tokenId) &&
                          <Fragment>
                            {/* IF POTION IS SELECTED TO BE MUTATE THEN IT WILL RENDER */}
                            {/* <div class={`${(localPotionState.value == 5) ? "form-group potion_after_mint_dropdown mx-auto my-2" : "d-none"}`}>
                              <select 
                                name={nftItem.tokenId} 
                                class="form-select" 
                                aria-label="Default select example"
                                onChange={selectMutationTypeForPotionsCham}
                              >
                                <option value="null" selected>Select Mutation Part</option>
                                <option value='top' >Top Body</option>
                                <option value='bottom' >Lower Body</option>
                              </select>
                            </div> */}
                            {/* IF POTION IS SELECTED TO BE TRAIT SWAP THEN THESE WILL RENDER FOR
                                SELECTION OF TOKEN NO.1 FOR TRAIT SWAPPING */}
                            <div className={`${(localPotionState.value == 1) ? "form-group potion_after_mint_dropdown mx-auto my-2" : "d-none"}`}>
                              <select 
                                name={nftItem.tokenId} 
                                className="form-select" 
                                aria-label="Default select example"
                                onChange={selectTokenForSwap}
                                value={localSwapTokenIdValue || 'null'}
                              >
                                <option value="null" selected>Select Token For Trait Swap</option>
                                {chameleonNft &&
                                  chameleonNft?.map((nft, index) => {
                                    let chamHasOverlay = nft.attributes?.filter((trait) => {return trait.traitType === 'Overlay'}).length;
                                    return (
                                      nftItem.tokenId !== nft.tokenId ? 
                                        isPrimaryTokenOverlay ?
                                          <option key={index} value={nft.tokenId}>{nft.token}</option> 
                                          :
                                          !chamHasOverlay ? 
                                            <option key={index} value={nft.tokenId}>{nft.token}</option> 
                                              :
                                            null
                                          : 
                                        null
                                    )
                                  })
                                }
                              </select>
                            </div>
                            {/* IF POTION IS SELECTED TO BE TRAIT SWAP AND TOKEN NO.1 IS ALSO SELECTED 
                                THEN THESE WILL RENDER FOR SELECTION OF TOKEN NO.2's TRAIT FOR TRAIT SWAPPING */}
                            <div className={`${(localPotionState.value == 1) && (localSwapTokenIdValue !== null) ? "form-group potion_after_mint_dropdown mx-auto my-2" : "d-none"}`}>
                              <select 
                                name={nftItem.tokenId} 
                                className="form-select" 
                                aria-label="Default select example"
                                onChange={selectSwapTrait1}
                                value={localSwapTrait1Value || 'null'}
                              >
                                <option value="null" selected>Select Trait 1 To Swap</option>
                                {chameleonTraits &&
                                  chameleonTraits?.map((trait, i) => {
                                    return (
                                      !isPrimaryTokenProp && (trait.traitType !== 'Prop') || isPrimaryTokenProp ?
                                        (trait.traitType !== 'Overlay') && <option key={i} value={trait.traitType} >{trait.traitType}</option> 
                                        : 
                                      null 
                                    )
                                  })
                                }
                              </select>
                            </div>
                            {/* IF POTION IS SELECTED TO BE TRAIT SWAP AND TOKEN NO.1 AND ITS TRAIT IS 
                                ALSO SELECTED THEN THESE WILL RENDER FOR SELECTION OF TOKEN NO.2 FOR TRAIT SWAPPING */}
                            {/* <div class={`${
                              (localPotionState.value == 1) && 
                              (localSwapTokenIdValue !== null) && 
                              (localSwapTrait1Value !== null) ? 
                                "form-group potion_after_mint_dropdown mx-auto my-2" 
                                : "d-none"
                              }`}
                            >
                              <select 
                                name={nftItem.tokenId} 
                                class="form-select" 
                                aria-label="Default select example"
                                onChange={selectToken2ForSwap}
                              >
                                <option value="null" selected>Select Token-2 Trait For Swap</option>
                                {chameleonNft &&
                                  chameleonNft?.map((nft, index) => {
                                    return (
                                      nftItem.tokenId !== nft.tokenId ? <option key={index} value={nft.tokenId}>{nft.tokenId}</option> : null
                                    )
                                  })
                                }
                              </select>
                            </div> */}
                            {/* IF POTION IS SELECTED TO BE TRAIT SWAP AND TOKEN NO.1, ITS TRAIT AND
                                IS TOKEN NO.2 FOR SWAPPING ALSO SELECTED THEN THESE WILL RENDER FOR 
                                SELECTION OF TOKEN NO.2's TRAIT FOR TRAIT SWAPPING */}
                            <div className={`${
                              (localPotionState.value == 1) && 
                              (localSwapTokenIdValue !== null) && 
                              (localSwapTrait1Value !== null) ? 
                                "form-group potion_after_mint_dropdown mx-auto my-2" 
                                : "d-none"
                              }`}
                            >
                              <select 
                                name={nftItem.tokenId} 
                                className="form-select" 
                                aria-label="Default select example"
                                onChange={selectSwapTrait2}
                                value={localSwapTrait2Value || 'null'}
                              >
                                <option value="null" selected>Select Trait 2 To Swap</option>
                                {chameleonTraits &&
                                  chameleonTraits?.map((trait, i) => {
                                    return (
                                      (trait.traitType != localSwapTrait1Value ) ?
                                      !isPrimaryTokenProp && (trait.traitType !== 'Prop') || isPrimaryTokenProp ?
                                        (trait.traitType !== 'Overlay') && <option key={i} value={trait.traitType} >{trait.traitType}</option> 
                                        :
                                        null
                                        : 
                                      null
                                    )
                                  })
                                }
                              </select>
                            </div>
                            {(localPotionState.value == 1) && (localSwapTokenIdValue !== null) && 
                              <div 
                                className="d-flex justify-content-center align-items-top my-4"
                                onClick={refreshSelectTokenForSwap}
                              >
                                <FaUndo size={20} className='text-info'/>
                                &nbsp;
                                Refresh trait swap values 
                              </div>
                            }
                          </Fragment>
                        }
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
          {localPotionState.value && (localPotionState.value != 1) && (localPotionState.value != 2) && (localPotionState.value != 'null') &&
            <div className="d-flex justify-content-center align-items-top my-4">
              <FaInfoCircle size={20} className='text-info'/>
              &nbsp;
              By clicking Eye icon You can Toggle between Orginal-cham and Potion-cham 
            </div>
          }
          {localPotionState.value && (localPotionState.value == 2) && (localPotionState.value != 'null') && isWithProp &&            
            <div className="d-flex justify-content-center align-items-top my-4">
              <FaInfoCircle size={20} className='text-info'/>
              &nbsp;
              By clicking Eye icon You can Toggle between Orginal-cham and without Prop-cham 
            </div>
          }
          {localPotionState.value && (localPotionState.value == 1) && !isPrimaryTokenOverlay &&            
            <div className="d-flex justify-content-center align-items-top my-4">
              <FaInfoCircle size={20} className='text-danger'/>
              &nbsp;
              If you wish to swap traits with Oceanic, Woodland or Underworld background then please 
              selecct them as Primary token 
            </div>
          }
          <button 
            type="button" 
            className="btn btn-primary btn-lg mb-4"
            onClick={() => {
              dispatch(potionApply(selectedToChoosePotionTypeArr[0]));
            }}
            disabled={(selectedToChoosePotionTypeArr?.filter((nft) => {return (!nft.potionNftId || nft.potionNftId == null || nft.potionNftId == 'null' ) || (nft.potionId == 2 && (!isWithProp)) || (nft.potionId == 1 && (!nft.swapTokenId || nft.swapTokenId == null || nft.swapTokenId == 'null')) || (nft.potionId == 1 && (!nft.swapTrait1 || nft.swapTrait1 == null || nft.swapTrait1 == 'null'))})).length > 0}
          >
            {mapChamWithPotionLoading ? <PulseLoader loading={mapChamWithPotionLoading} color='#FFF' /> : "Save"}
          </button>
          <div className={`${isPotionBurnModelOpen ? "potion-mint-modal-wrapper d-flex justify-content-center align-items-center position-fixed" : "d-none"}`}>
            <div className="potion-mint-modal">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLongTitle">Confirmation</h5>
                  <button type="button" className="close" onClick={() => {setIsPotionBurnModelOpen(false);}}>
                    {/* <span aria-hidden="true">&times;</span> */}
                  </button>
                </div>
                <div className="modal-body p-5">
                  <span className="">
                    {"Potion type saved Successfuly"} 
                    <br />
                    {"Now, "}
                    <br />
                    {"Apply Potions to token no. "} 
                    <b>{selectedTokenToChoosePotion && selectedTokenToChoosePotion}</b> 
                  </span>
                </div>
                <div className="modal-footer">
                  {/* <button type="button" class="btn btn-secondary" onClick={() => {setIsPotionBurnModelOpen(false);}}>Cancel</button> */}
                  <button 
                    type="button" 
                    className="btn btn-primary" 
                    onClick={() => {
                        burnNftHandler();
                      }
                    }
                  >
                   {burnTokenLoading ? <PulseLoader loading={burnTokenLoading} color='#FFF' /> : "Apply Potion" }
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    }
    <BurnedForPotionCardWrapper />
  </Fragment>
  )
}

export default MintedForPotionCardWrapper
