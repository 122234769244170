import axios from "axios";
import { base_url, end_points } from "../../constant";

function getAxios(method, url, data, isAccessToken) {
    if(isAccessToken) {
        return axios({
            method: method,
            url: url,
            data: data,
            headers: { "Content-Type": "application/json", "Authorization": "Bearer "+localStorage.getItem("chameleon_access_token") },
        })
    }else {
        return axios({
            method: method,
            url: url,
            data: data,
            headers: { "Content-Type": "application/json" },
        })
    }
}

export function getTokenWithoutPotionApi() {
    return getAxios( 'get', base_url + end_points.get_Token_Without_Potion, null, true)
}

export function getAllPotionsApi() {
    return getAxios( 'get', base_url + end_points.get_All_Potions, null, true)
}

export function onAfterMintPotionsApi( payload ) {
    return getAxios( 'post', base_url + end_points.on_After_Mint_Potions, payload, true)
}

export function mapChameleonWithPotionApi( payload ) {
    return getAxios( 'post', base_url + end_points.map_Chameleon_With_Potion, payload, true)
}

export function getListOfPotionMintTokensApi() {
    return getAxios( 'get', base_url + end_points.get_List_Of_Potion_Mint_Tokens, null, true)
}

export function getListOfChameleonPotionsApi() {
    return getAxios( 'get', base_url + end_points.get_List_Of_Chameleon_Potions, null, true)
}

export function onAfterBurnApi( payload ) {
    return getAxios( 'post', base_url + end_points.on_After_Burn, payload, true)
}

export function getTokenDetailApi( payload ) {
    return getAxios( 'get', base_url + end_points.get_Token_Detail + '?tokenId=' + payload.tokenId, null, true)
}

export function getMyPotionApi( payload ) {
    return getAxios( 'get', base_url + end_points.get_My_Potion + '?tokenId=' + payload.tokenId, null, true)
}

export function potionApplyApi( payload ) {
    return getAxios( 'post', base_url + end_points.potion_Apply, payload, true)
}

export function getTokenExcludedApi() {
    return getAxios( 'get', base_url + end_points.get_Token_Excluded, null, true)
}

export function isTokenAlteredApi( payload ) {
    return getAxios( 'get', base_url + end_points.is_Token_Altered+ '?token=' + payload.token, null, false)
}

export function getTokenBurnedApi() {
    return getAxios( 'get', base_url + end_points.get_Token_Burned, null, true)
}