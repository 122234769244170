import { call, takeEvery } from 'redux-saga/effects';
import { 
    getAllPotionsApi,
    getListOfChameleonPotionsApi,
    getListOfPotionMintTokensApi,
    getMyPotionApi,
    getTokenBurnedApi,
    getTokenDetailApi,
    getTokenExcludedApi,
    getTokenWithoutPotionApi,
    isTokenAlteredApi,
    mapChameleonWithPotionApi,
    onAfterBurnApi,
    onAfterMintPotionsApi,
    potionApplyApi,
 } from '../services/potion';
import { 
    getAllPotionsFailed,
    getAllPotionsSuccess,
    getListOfChameleonPotionsFailed,
    getListOfChameleonPotionsSuccess,
    getListOfPotionMintTokensFailed,
    getListOfPotionMintTokensSuccess,
    getMyPotionFailed,
    getMyPotionSuccess,
    getTokenBurnedFailed,
    getTokenBurnedSuccess,
    getTokenDetailFailed,
    getTokenDetailForSwapFailed,
    getTokenDetailForSwapSuccess,
    getTokenDetailSuccess,
    getTokenExcludedFailed,
    getTokenExcludedSuccess,
    getTokenWithoutPotionFailed, 
    getTokenWithoutPotionSuccess, 
    isTokenAlteredFailed, 
    isTokenAlteredSuccess, 
    mapChameleonWithPotionFailed, 
    mapChameleonWithPotionSuccess, 
    onAfterBurnFailed, 
    onAfterBurnSuccess, 
    onAfterMintPotionsFailed, 
    onAfterMintPotionsSuccess,
    potionApplyFailed,
    potionApplySuccess,
 } from '../actions/potion';
import store from '../store';
import { toast } from 'react-toastify';

function* getTokenWithoutPotionSaga({ payload }) {
    try{
        const responce = yield call(getTokenWithoutPotionApi , payload)
        if(responce){
            if (responce.data.status === 200) {
                store.dispatch(getTokenWithoutPotionSuccess({status: responce.data.status, tokens: responce.data.response}))
                store.dispatch(getTokenWithoutPotionApi())
                // console.log("getTokenWithoutPotionSagaSuccess Potions :", responce.data.response)
            }else {
                store.dispatch(getTokenWithoutPotionFailed(responce.data.message))
                toast.error( responce.data.message , {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                },);
                // console.log("getTokenWithoutPotionFailed",responce.data.status)
            }
        }
    } catch (e) {
        // console.log('GET_TOKEN_WITHOUT_POTION',e)
        // yield put({ type: 'GET_TOKEN_WITHOUT_POTION', message: e.message });
    }
}

function* getAllPotionsSaga({ payload }) {
    try{
        const responce = yield call(getAllPotionsApi , payload)
        if(responce){
            if (responce.data.status === 200) {
                store.dispatch(getAllPotionsSuccess({status: responce.data.status, potions: responce.data.response}))
                // console.log("getAllPotionsSagaSuccess",responce.data)
            }else {
                store.dispatch(getAllPotionsFailed(responce.data.message))
                toast.error( responce.data.message , {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                },);
                // console.log("getAllPotionsFailed",responce.data.status)
            }
        }
    } catch (e) {
        // console.log('GET_TOKEN_WITHOUT_POTION',e)
        // yield put({ type: 'GET_TOKEN_WITHOUT_POTION', message: e.message });
    }
}

function* onAfterMintPotionsSaga({ payload }) {
    try{
        const responce = yield call(onAfterMintPotionsApi , payload)
        if(responce){
            if (responce.data.status === 200) {
                store.dispatch(onAfterMintPotionsSuccess({status: responce.data.status}))
                // SHOULD WE CALL THIS HERE ? -> dispatch(getTokenWithoutPotion());
                // console.log("onAfterMintPotionsSagaSuccess",responce.data)
            }else {
                store.dispatch(onAfterMintPotionsFailed(responce.data.message))
                toast.error( responce.data.message , {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                },);
                // console.log("onAfterMintPotionsFailed",responce.data.status)
            }
        }
    } catch (e) {
        // console.log('GET_TOKEN_WITHOUT_POTION',e)
        // yield put({ type: 'GET_TOKEN_WITHOUT_POTION', message: e.message });
    }
}

function* mapChameleonWithPotionSaga({ payload }) {
    try{
        const responce = yield call(mapChameleonWithPotionApi , payload)
        if(responce){
            if (responce.data.status === 200) {
                store.dispatch(mapChameleonWithPotionSuccess({status: responce.data.status}))
                // console.log("mapChameleonWithPotionSagaSuccess",responce.data)
            }else {
                store.dispatch(mapChameleonWithPotionFailed(responce.data.message))
                toast.error( responce.data.message , {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                },);
                // console.log("mapChameleonWithPotionFailed",responce.data.status)
            }
        }
    } catch (e) {
        // console.log('GET_TOKEN_WITHOUT_POTION',e)
        // yield put({ type: 'GET_TOKEN_WITHOUT_POTION', message: e.message });
    }
}

function* getListOfPotionMintTokensSaga({ payload }) {
    try{
        const responce = yield call(getListOfPotionMintTokensApi , payload)
        if(responce){
            if (responce.data.status === 200) {
                store.dispatch(getListOfPotionMintTokensSuccess({status: responce.data.status, tokenList: responce.data.response}))
                // console.log("getListOfPotionMintTokensSagaSuccess",responce.data.response)
            }else {
                store.dispatch(getListOfPotionMintTokensFailed(responce.data.message))
                toast.error( responce.data.message , {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                },);
                // console.log("getListOfPotionMintTokensFailed",responce.data.status)
            }
        }
    } catch (e) {
        // console.log('GET_TOKEN_WITHOUT_POTION',e)
        // yield put({ type: 'GET_TOKEN_WITHOUT_POTION', message: e.message });
    }
}

function* getListOfChameleonPotionsSaga({ payload }) {
    try{
        const responce = yield call(getListOfChameleonPotionsApi , payload)
        if(responce){
            if (responce.data.status === 200) {
                store.dispatch(getListOfChameleonPotionsSuccess({status: responce.data.status, tokenList: responce.data.response}))
                // console.log("getListOfChameleonPotionsSagaSuccess",responce.data)
            }else {
                store.dispatch(getListOfChameleonPotionsFailed(responce.data.message))
                toast.error( responce.data.message , {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                },);
                // console.log("getListOfChameleonPotionsFailed",responce.data.status)
            }
        }
    } catch (e) {
        // console.log('GET_TOKEN_WITHOUT_POTION',e)
        // yield put({ type: 'GET_TOKEN_WITHOUT_POTION', message: e.message });
    }
}

function* onAfterBurnSaga({ payload }) {
    try{
        const responce = yield call(onAfterBurnApi , payload)
        if(responce){
            if (responce.data.status === 200) {
                store.dispatch(onAfterBurnSuccess({status: responce.data.status}))
                // console.log("onAfterBurnSagaSuccess",responce.data)
                toast.success( responce.data.message , {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                },);
            }else {
                store.dispatch(onAfterBurnFailed(responce.data.message))
                toast.error( responce.data.message , {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                },);
                // console.log("onAfterBurnFailed",responce.data.status)
            }
        }
    } catch (e) {
        // console.log('GET_TOKEN_WITHOUT_POTION',e)
        // yield put({ type: 'GET_TOKEN_WITHOUT_POTION', message: e.message });
    }
}

function* getTokenDetailSaga({ payload }) {
    try{
        const responce = yield call(getTokenDetailApi , payload)
        if(responce){
            if (responce.data.status === 200) {
                store.dispatch(getTokenDetailSuccess({status: responce.data.status, tokenDetail: responce.data.response, chamTraits: responce.data.response.attributes}))
                // console.log("getTokenDetailSagaSuccess",responce.data.response)
                // console.log("getTokenDetailSagaSuccess Traits: ",responce.data.response.attributes)
            }else {
                store.dispatch(getTokenDetailFailed(responce.data.message))
                toast.error( responce.data.message , {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                },);
                // console.log("getTokenDetailFailed",responce.data.status)
            }
        }
    } catch (e) {
        // console.log('GET_TOKEN_WITHOUT_POTION',e)
        // yield put({ type: 'GET_TOKEN_WITHOUT_POTION', message: e.message });
    }
}

function* getTokenDetailForSwapSaga({ payload }) {
    try{
        const responce = yield call(getTokenDetailApi , payload)
        if(responce){
            if (responce.data.status === 200) {
                store.dispatch(getTokenDetailForSwapSuccess({status: responce.data.status, tokenDetailForSwap: responce.data.response, chamTraitsForSwap: responce.data.response.attributes}))
                // console.log("getTokenDetailForSwapSagaSuccess",responce.data.response)
                // console.log("getTokenDetailForSwapSagaSuccess Traits: ",responce.data.response.attributes)
            }else {
                store.dispatch(getTokenDetailForSwapFailed(responce.data.message))
                toast.error( responce.data.message , {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                },);
                // console.log("getTokenDetailForSwapFailed",responce.data.status)
            }
        }
    } catch (e) {
        // console.log('GET_TOKEN_WITHOUT_POTION',e)
        // yield put({ type: 'GET_TOKEN_WITHOUT_POTION', message: e.message });
    }
}

function* getMyPotionSaga({ payload }) {
    try{
        const responce = yield call(getMyPotionApi , payload)
        if(responce){
            if (responce.data.status === 200) {
                store.dispatch(getMyPotionSuccess({status: responce.data.status, myPotionList: responce.data.response}))
                // console.log("getMyPotionSagaSuccess",responce.data.response)
                // console.log("getMyPotionSagaSuccess Traits: ",responce.data.response.attributes)
            }else {
                store.dispatch(getMyPotionFailed(responce.data.message))
                toast.error( responce.data.message , {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                },);
                // console.log("getMyPotionFailed",responce.data.status)
            }
        }
    } catch (e) {
        // console.log('GET_TOKEN_WITHOUT_POTION',e)
        // yield put({ type: 'GET_TOKEN_WITHOUT_POTION', message: e.message });
    }
}

function* potionApplySaga({ payload }) {
    try{
        const responce = yield call(potionApplyApi , payload)
        if(responce){
            if (responce.data.status === 200) {
                store.dispatch(potionApplySuccess({status: responce.data.status}))
                // console.log("potionApplySagaSuccess",responce.data.response)
                toast.success( responce.data.message , {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                },);
            }else {
                store.dispatch(potionApplyFailed(responce.data.message))
                toast.error( responce.data.message , {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                },);
                // console.log("potionApplyFailed",responce.data.status)
            }
        }
    } catch (e) {
        // console.log('GET_TOKEN_WITHOUT_POTION',e)
        // yield put({ type: 'GET_TOKEN_WITHOUT_POTION', message: e.message });
    }
}

function* getTokenExcludedSaga({ payload }) {
    try{
        const responce = yield call(getTokenExcludedApi , payload)
        if(responce){
            if (responce.data.status === 200) {
                store.dispatch(getTokenExcludedSuccess({status: responce.data.status, chameleonNft: responce.data.response}))
                // console.log("getTokenExcludedSagaSuccess",responce.data.response)
            }else {
                store.dispatch(getTokenExcludedFailed(responce.data.message))
                toast.error( responce.data.message , {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                },);
                // console.log("getTokenExcludedFailed",responce.data.status)
            }
        }
    } catch (e) {
        // console.log('GET_TOKEN_WITHOUT_POTION',e)
        // yield put({ type: 'GET_TOKEN_WITHOUT_POTION', message: e.message });
    }
}

function* isTokenAlteredSaga({ payload }) {
    try{
        const responce = yield call(isTokenAlteredApi , payload)
        if(responce){
            if (responce.data.status === 200) {
                store.dispatch(isTokenAlteredSuccess({status: responce.data.status, statusMsg: responce.data.message}))
                // console.log("isTokenAlteredSagaSuccess",responce.data.response)
            }else {
                store.dispatch(isTokenAlteredFailed(responce.data.message))
                toast.error( responce.data.message , {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                },);
                // console.log("isTokenAlteredFailed",responce.data.status)
            }
        }
    } catch (e) {
        // console.log('GET_TOKEN_WITHOUT_POTION',e)
        // yield put({ type: 'GET_TOKEN_WITHOUT_POTION', message: e.message });
    }
}

function* getTokenBurnedSaga({ payload }) {
    try{
        const responce = yield call(getTokenBurnedApi , payload)
        if(responce){
            if (responce.data.status === 200) {
                store.dispatch(getTokenBurnedSuccess({status: responce.data.status, burnedPotionNfts: responce.data.response}))
                // console.log("getTokenBurnedSagaSuccess",responce.data.response)
            }else {
                store.dispatch(getTokenBurnedFailed(responce.data.message))
                toast.error( responce.data.message , {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                },);
                // console.log("getTokenBurnedFailed",responce.data.status)
            }
        }
    } catch (e) {
        // console.log('GET_TOKEN_WITHOUT_POTION',e)
        // yield put({ type: 'GET_TOKEN_WITHOUT_POTION', message: e.message });
    }
}

function* potionSaga() {
    yield takeEvery('GET_TOKEN_WITHOUT_POTION', getTokenWithoutPotionSaga );
    yield takeEvery('GET_ALL_POTIONS', getAllPotionsSaga );
    yield takeEvery('ON_AFTER_MINT_POTIONS', onAfterMintPotionsSaga );
    yield takeEvery('MAP_CHAMELEON_WITH_POTION', mapChameleonWithPotionSaga );
    yield takeEvery('GET_LIST_OF_POTION_MINT_TOKENS', getListOfPotionMintTokensSaga );
    yield takeEvery('GET_LIST_OF_CHAMELEON_POTIONS', getListOfChameleonPotionsSaga );
    yield takeEvery('ON_AFTER_BURN', onAfterBurnSaga );
    yield takeEvery('GET_TOKEN_DETAIL', getTokenDetailSaga );
    yield takeEvery('GET_TOKEN_DETAIL_FOR_SWAP', getTokenDetailForSwapSaga );
    yield takeEvery('GET_MY_POTION', getMyPotionSaga );
    yield takeEvery('POTION_APPLY', potionApplySaga );
    yield takeEvery('GET_TOKEN_EXCLUDED', getTokenExcludedSaga );
    yield takeEvery('IS_TOKEN_ALTERED', isTokenAlteredSaga );
    yield takeEvery('GET_TOKEN_BURNED', getTokenBurnedSaga );
}

export default potionSaga;
