import React, { useEffect } from 'react';
import Factions from '../../components/Factions/Factions';
import Header from '../../components/Header/Header';
import Merch from '../../components/Merch/Merch';
import MerchBg from '../../assets/Img/merch-img.png';
import Founder from '../../components/Founder/Founder';
import Footer from '../../components/Footer/Footer';
import Roadmap from '../../components/Roadmap/Roadmap';

function Home({ setActivePage }) {

    useEffect(() => {
        setActivePage('home');
        return () => {
            setActivePage('');
        }
    }, []);

    return (
        <div>
            <Header Intro="We're a collection of 10,000 highly detailed hand drawn cute, crazy, and loveable chameleons. Every chamaleon and trait are hand drawn by Zach Bush with over 850 different traits and colour available" btnName="OPENSEA COLLECTION" href="https://opensea.io/collection/chameleon-collective"/>
            <Factions />
            {/* <Merch merchBg={MerchBg} btnContent="Shop Now" /> */}
            <Roadmap />
            <Founder />
            <Footer />
        </div>
    )
}

export default Home
