import React from 'react';
import Cosmic  from '../../assets/Img/cosmic.png';
import Woodland  from '../../assets/Img/woodland.png';
import Underworld  from '../../assets/Img/underworld.png';
import Oceanic  from '../../assets/Img/oceanic.png';
import Card from '../Card/Card';
import './Factions.css';

function Factions() {
    return (
        <div className="app_container factions">
            <div className="faction_heading">
                <h1>FACTIONS</h1>
                <p>We brought a little friendly competition between our project supporters to kick off the next chapter in our journey. You must choose your decision wisely, as once you pick your faction you will be locked in for the season. The first season will end in 3 months, until our next big event.</p>
            </div>
            <div className="d-flex flex-column flex-md-row w-100">
                <div className="col-12 col-md-6 Faction_Img_grid d-flex flex-column p-2 p-md-0 ">
                    <div className="d-flex justify-content-between mb-4">
                        <Card ImgName={Cosmic} CardColor="Cosmic" CardTitle="Cosmic" />
                        <Card ImgName={Woodland} CardColor="Woodland" CardTitle="Woodland" />
                    </div>
                    <div className="d-flex justify-content-between">
                        <Card ImgName={Underworld} CardColor="Underworld" CardTitle="Underworld" />
                        <Card ImgName={Oceanic} CardColor="Oceanic" CardTitle="Oceanic" />
                    </div>
                </div>
                <div className="col-12 col-md-6 faction_content">
                    <span>First up will be a</span>
                    <div className="text_highlight">2-week  trivia  competition.</div>
                    <div className="mb-3">
                        <b>Top 10 will win points for their faction. The winning team will earn a custom faction background! </b>
                        Every member of the winning faction will have the ability to assign it to ONE of the chameleons they own. The only other way to earn the custom background is by holding a squad when the competition ends.
                    </div>
                    <div className="mb-3 text-uppercase" style={{fontSize: "14px"}}>
                        <b>
                            <span style={{fontSize: "18px"}}>B</span>lack Squad: Cosmic | 
                            <span style={{fontSize: "18px"}}>W</span>hite Squad: Woodland | 
                            <span style={{fontSize: "18px"}}>g</span>old Squad: Oceanic | 
                            <span style={{fontSize: "18px"}}>b</span>urn Squad: Underworld
                        </b>
                        </div>
                    <div className="mb-3">
                        <b>In addition – You can increase your odds of winning by holding a Chameleon Collective NFT from one of these factions.</b>
                        It will be taken into consideration that you are an indirect team member of the ones you hold.
                    </div>
                    <div className="mb-3">
                        We will have a tiered reward system for competitions. Each time your faction wins a competition they will gain access to winning greater rewards. We will have the full list of awards and more details on Sunday during our twitter spaces AMA. Please come prepared with any questions you may have in order to prepare for the friendly battles ahead!
                    </div>
                    <div className="mb-5"><b>Good luck everyone, and welcome to the ChamFam!</b></div>
                </div>
          </div>
        </div>
    )
}

export default Factions
