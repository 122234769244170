import * as type from '../types';

export function findAllChamTraitTypesSuccess(payload) {
    return {
        type: type.FIND_ALL_CHAM_TRAIT_TYPES_SUCCESS,
        payload,
    }
}

export function findAllChamTraitTypes(payload) {
    return {
        type: type.FIND_ALL_CHAM_TRAIT_TYPES,
        payload,
    }
}

export function findAllChamTraitTypesFailed(payload) {
    return {
        type: type.FIND_ALL_CHAM_TRAIT_TYPES_FAILED,
        payload,
    }
}

export function findChamTraitsByTraitTypeSuccess(payload) {
    return {
        type: type.FIND_CHAM_TRAITS_BY_TRAIT_TYPE_SUCCESS,
        payload,
    }
}

export function findChamTraitsByTraitType(payload) {
    return {
        type: type.FIND_CHAM_TRAITS_BY_TRAIT_TYPE,
        payload,
    }
}

export function findChamTraitsByTraitTypeFailed(payload) {
    return {
        type: type.FIND_CHAM_TRAITS_BY_TRAIT_TYPE_FAILED,
        payload,
    }
}

export function savePreviewNftSuccess(payload) {
    return {
        type: type.SAVE_PREVIEW_NFT_SUCCESS,
        payload,
    }
}

export function savePreviewNft(payload) {
    return {
        type: type.SAVE_PREVIEW_NFT,
        payload,
    }
}

export function savePreviewNftFailed(payload) {
    return {
        type: type.SAVE_PREVIEW_NFT_FAILED,
        payload,
    }
}

export function getAllPreviewNftListSuccess(payload) {
    return {
        type: type.GET_ALL_PREVIEW_NFT_LIST_SUCCESS,
        payload,
    }
}

export function getAllPreviewNftList(payload) {
    return {
        type: type.GET_ALL_PREVIEW_NFT_LIST,
        payload,
    }
}

export function getAllPreviewNftListFailed(payload) {
    return {
        type: type.GET_ALL_PREVIEW_NFT_LIST_FAILED,
        payload,
    }
}

export function getAllOverlayTypeSuccess(payload) {
    return {
        type: type.GET_ALL_OVERLAY_TYPE_SUCCESS,
        payload,
    }
}

export function getAllOverlayType(payload) {
    return {
        type: type.GET_ALL_OVERLAY_TYPE,
        payload,
    }
}

export function getAllOverlayTypeFailed(payload) {
    return {
        type: type.GET_ALL_OVERLAY_TYPE_FAILED,
        payload,
    }
}

export function getOverlayByTypeSuccess(payload) {
    return {
        type: type.GET_OVERLAY_BY_TYPE_SUCCESS,
        payload,
    }
}

export function getOverlayByType(payload) {
    return {
        type: type.GET_OVERLAY_BY_TYPE,
        payload,
    }
}

export function getOverlayByTypeFailed(payload) {
    return {
        type: type.GET_OVERLAY_BY_TYPE_FAILED,
        payload,
    }
}

export function getAllOverlaySuccess(payload) {
    return {
        type: type.GET_ALL_OVERLAY_SUCCESS,
        payload,
    }
}

export function getAllOverlay(payload) {
    return {
        type: type.GET_ALL_OVERLAY,
        payload,
    }
}

export function getAllOverlayFailed(payload) {
    return {
        type: type.GET_ALL_OVERLAY_FAILED,
        payload,
    }
}

export function addToWishlistSuccess(payload) {
    return {
        type: type.ADD_TO_WISHLIST_SUCCESS,
        payload,
    }
}

export function addToWishlist(payload) {
    return {
        type: type.ADD_TO_WISHLIST,
        payload,
    }
}

export function addToWishlistFailed(payload) {
    return {
        type: type.ADD_TO_WISHLIST_FAILED,
        payload,
    }
}

export function getAllWishListSuccess(payload) {
    return {
        type: type.GET_ALL_WISHLIST_SUCCESS,
        payload,
    }
}

export function getAllWishList(payload) {
    return {
        type: type.GET_ALL_WISHLIST,
        payload,
    }
}

export function getAllWishListFailed(payload) {
    return {
        type: type.GET_ALL_WISHLIST_FAILED,
        payload,
    }
}

export function deleteWishlistSuccess(payload) {
    return {
        type: type.DELETE_WISHLIST_SUCCESS,
        payload,
    }
}

export function deleteWishlist(payload) {
    return {
        type: type.DELETE_WISHLIST,
        payload,
    }
}

export function deleteWishlistFailed(payload) {
    return {
        type: type.DELETE_WISHLIST_FAILED,
        payload,
    }
}

export function wishlistSelectedForPrintSuccess(payload) {
    return {
        type: type.WISHLIST_SELECTED_FOR_PRINT_SUCCESS,
        payload,
    }
}

export function wishlistSelectedForPrint(payload) {
    return {
        type: type.WISHLIST_SELECTED_FOR_PRINT,
        payload,
    }
}

export function wishlistSelectedForPrintFailed(payload) {
    return {
        type: type.WISHLIST_SELECTED_FOR_PRINT_FAILED,
        payload,
    }
}

export function afterPaymentSuccess(payload) {
    return {
        type: type.AFTER_PAYMENT_SUCCESS,
        payload,
    }
}

export function afterPayment(payload) {
    return {
        type: type.AFTER_PAYMENT,
        payload,
    }
}

export function afterPaymentFailed(payload) {
    return {
        type: type.AFTER_PAYMENT_FAILED,
        payload,
    }
}

export function afterPaymentReset(payload) {
    return {
        type: type.AFTER_PAYMENT_RESET,
        payload,
    }
}

export function deleteOrderSuccess(payload) {
    return {
        type: type.DELETE_ORDER_SUCCESS,
        payload,
    }
}

export function deleteOrder(payload) {
    return {
        type: type.DELETE_ORDER,
        payload,
    }
}

export function deleteOrderFailed(payload) {
    return {
        type: type.DELETE_ORDER_FAILED,
        payload,
    }
}

export function findAllTraitSuccess(payload) {
    return {
        type: type.FIND_ALL_TRAITS_SUCCESS,
        payload,
    }
}

export function findAllTrait(payload) {
    return {
        type: type.FIND_ALL_TRAITS,
        payload,
    }
}

export function findAllTraitFailed(payload) {
    return {
        type: type.FIND_ALL_TRAITS_FAILED,
        payload,
    }
}

export function findAllForDressingRoomSuccess(payload) {
    return {
        type: type.FIND_ALL_FOR_DRESSING_ROOM_SUCCESS,
        payload,
    }
}

export function findAllForDressingRoom(payload) {
    return {
        type: type.FIND_ALL_FOR_DRESSING_ROOM,
        payload,
    }
}

export function findAllForDressingRoomFailed(payload) {
    return {
        type: type.FIND_ALL_FOR_DRESSING_ROOM_FAILED,
        payload,
    }
}

export function findAllInventoryTraitTypesSuccess(payload) {
    return {
        type: type.FIND_ALL_INVENTORY_TRAIT_TYPES_SUCCESS,
        payload,
    }
}

export function findAllInventoryTraitTypes(payload) {
    return {
        type: type.FIND_ALL_INVENTORY_TRAIT_TYPES,
        payload,
    }
}

export function findAllInventoryTraitTypesFailed(payload) {
    return {
        type: type.FIND_ALL_INVENTORY_TRAIT_TYPES_FAILED,
        payload,
    }
}

export function findAllAvailableInventorySuccess(payload) {
    return {
        type: type.FIND_ALL_AVAILABLE_INVENTORY_SUCCESS,
        payload,
    }
}

export function findAllAvailableInventory(payload) {
    return {
        type: type.FIND_ALL_AVAILABLE_INVENTORY,
        payload,
    }
}

export function findAllAvailableInventoryFailed(payload) {
    return {
        type: type.FIND_ALL_AVAILABLE_INVENTORY_FAILED,
        payload,
    }
}

export function applyInventorySuccess(payload) {
    return {
        type: type.APPLY_INVENTORY_SUCCESS,
        payload,
    }
}

export function applyInventory(payload) {
    return {
        type: type.APPLY_INVENTORY,
        payload,
    }
}

export function applyInventoryFailed(payload) {
    return {
        type: type.APPLY_INVENTORY_FAILED,
        payload,
    }
}

// For reset
export function resetDressingCham(payload) {
    return {
        type: type.RESET_DRESSING_CHAM,
        payload,
    }
}