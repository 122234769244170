import React, { useState } from 'react';
import RoadmapImg from '../../assets/Img/roadmap_img1.jpg';
import RoadmapPopup from '../RoadmapPopup/RoadmapPopup';
import './Roadmap.css';

function Roadmap() {
    const [showCloset, setShowCloset] = useState(false);
    const [showLounge, setShowLounge] = useState(false);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [showStudio, setShowStudio] = useState(false);
    const [showLab, setShowLab] = useState(false);
    

    return (
            <div className="app_container roadmap">
                <img src={RoadmapImg} useMap="#image-map"/>
                <div className="hover_pointer closet" onClick={() => setShowCloset(true)}>
                        <RoadmapPopup  
                            Title="The Closet"
                            Subtitle="Club Chameleon"
                            show={showCloset}
                            onClose={(state) => {
                                setShowCloset(state);
                            }}
                            />
                </div>
                <div className="hover_pointer lounge" onClick={() => setShowLounge(true)}>
                        <RoadmapPopup  
                            Title="Lizard Lounge"
                            Subtitle="Get your Kicks"
                            show={showLounge}
                            onClose={(state) => {
                                setShowLounge(state);
                            }}
                            />
                </div>
                <div className="hover_pointer snack_bar" onClick={() => setShowSnackbar(true)}>
                        <RoadmapPopup  
                            Title="Snack Bar"
                            Subtitle="Get your Kicks"
                            show={showSnackbar}
                            onClose={(state) => {
                                setShowSnackbar(state);
                            }}
                            />
                </div>
                <div className="hover_pointer studio" onClick={() => setShowStudio(true)}>
                        <RoadmapPopup  
                            Title="The Studio"
                            Subtitle="Get your Kicks"
                            show={showStudio}
                            onClose={(state) => {
                                setShowStudio(state);
                            }}
                            />
                </div>
                <div className="hover_pointer lab" onClick={() => setShowLab(true)}>
                        <RoadmapPopup  
                            Title="The Lab"
                            Subtitle="Get your Kicks"
                            show={showLab}
                            onClose={(state) => {
                                setShowLab(state);
                            }}
                        />
                </div>
            </div>
    )
}

export default Roadmap
