import React from 'react';
import TreeHouse from '../../assets/Img/treehouse.png';
import BannerImg from '../../assets/Img/banner_img.png';

import './Header.css';

function Header({ Intro, btnName, href }) {

    function openLink() {
        window.open(href, '_blank');
    }

    return (
        <div className="app_container banner_sec">
            <div className="d-flex flex-column-reverse flex-md-row">
                <div className="col-12 col-md-6 banner_img d-flex mt-auto">
                    <img src={BannerImg} alt="" />
                </div>
                <div className="col-12 col-md-6 banner_content">
                    <h3 className="mb-3">Welcome to the</h3>
                    <img className="mb-3" src={TreeHouse} alt="tree=house" />
                    <p>{Intro ? Intro : "We're a collection of 10,000 highly detailed hand drawn cute, crazy, and loveable chameleons. Every chamaleon and trait are hand drawn by Zach Bush with over 850 different traits and colour available"}</p>
                    <div className="d-flex w-100 mt-2 mt-md-0">
                        <button className="banner_btn mx-auto" onClick={openLink}>
                            {btnName}
                        </button>
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default Header
