import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NftCard from '../NftCard/NftCard';
import { GridLoader } from 'react-spinners';
import { getTokenBurned } from '../../redux/actions/potion';
import { FaTimes } from 'react-icons/fa';
import './BurnedForPotionCardWrapper.css';

function BurnedForPotionCardWrapper() {
    const [isShowMore, setIsShowMore] = useState(false);
    const [burnedPotionNftsArr, setBurnedPotionNftsArr] = useState([]);
    const [brunedTokenTraits, setBrunedTokenTraits] = useState([]);
    const [showNftPopup, setShowNftPopup] = useState();
    const [popupNftTokanNo, setPopupNftTokanNo] = useState();

    const dispatch = useDispatch();
    const burnedPotionNfts = useSelector(state => state.potion.burnedPotionNfts);
    const loading = useSelector(state => state.potion.loading);
    const error = useSelector(state => state.potion.error);
    
    useEffect(() => {
        setBurnedPotionNftsArr(burnedPotionNfts)
    }, [burnedPotionNfts]);

    useEffect(() => {
        dispatch(getTokenBurned());
    }, []);

    useEffect(() => {
        let tempPopupNftTokan = burnedPotionNftsArr?.filter((nft) => {return nft.token === popupNftTokanNo});
        let popupNftTokanObj = tempPopupNftTokan[0];
        if(popupNftTokanObj) {
            setBrunedTokenTraits(popupNftTokanObj.attributes)            
        }
    }, [popupNftTokanNo]);

    return (
        <Fragment>
            <div className='nft_card_wrapper mx-auto m-md-4 mb-4'>
                <div className="nft_card_wrapper_heading d-flex justify-content-between">
                    <h1 className="nft_card_heading text-uppercase p-4 m-0 pointer-cursor">chameleon with potion</h1>
                    <span className="down_icon m-4 down_icon_primary" onClick={() => setIsShowMore(!isShowMore)}>
                        {(!isShowMore) ?
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-40 w-40" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                            </svg>
                            :
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-40 w-40" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd" d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z" clip-rule="evenodd" />
                            </svg>
                        }
                    </span>
                </div>
                <div className="d-flex flex-wrap p-2">
                    {
                        loading &&
                        <div className="d-flex justify-content-center align-item-end w-100 p-5">
                            <GridLoader loading={loading} size={30} color='#01BFD9'/>
                        </div>
                    }
                    {(burnedPotionNftsArr && burnedPotionNftsArr.length && !loading) ?
                        burnedPotionNftsArr?.map(( nft, index ) => {
                            return(
                                <div 
                                    className={`${(!isShowMore) ? ( index >= 6 )? "d-none" : "potions_nft_2_card d-flex flex-column p-2" : "potions_nft_2_card d-flex flex-column p-2"}`}
                                    onClick={() => {setShowNftPopup(true);setPopupNftTokanNo(nft.token)}}
                                >
                                    <img src={nft.image ? nft.image : 'img/blank-chamagotchi.jpg'} alt="" className="" />
                                </div>
                            )
                        })
                        : (!loading) ?
                            <div className="d-flex justify-content-center align-items-end w-100 p-4">
                                <img src="img/no-data.png" alt="" className="" />
                                <h4 className="text-danger my-2">You don't have any NFT associated With this account !!!</h4>
                            </div>
                        :
                        null
                    }
                    {(!isShowMore && burnedPotionNftsArr && burnedPotionNftsArr.length && !loading) ?
                        <div className="nft-show-more-card d-flex d-md-none justify-content-center align-item-center">
                            <button className="btn btn-outline-info" onClick={() => setIsShowMore(true)}>
                                {"More >>"}
                            </button>
                        </div>
                        :( burnedPotionNftsArr && burnedPotionNftsArr.length && !loading) ?
                            <div className="nft-show-more-card d-flex d-md-none justify-content-center align-item-center">
                                <button className="btn btn-outline-info" onClick={() => setIsShowMore(false)}>
                                    {"collapse <<"}
                                </button>
                            </div>
                        :
                        null
                    }
                </div>
                <div className={`${showNftPopup ? "burned_popup_wrapper d-flex justify-content-center align-item-center" : "d-none"}`}>
                    <div className="burned_popup_container d-flex flex-column mt-5">
                        <button className="burned_close_popup" onClick={() => {setShowNftPopup(false);setBrunedTokenTraits([]);}}>
                            <FaTimes size={20}/>
                        </button>
                        <div className="d-flex flex-column justify-content-center align-items-center m-auto">
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="compose-cham-from-api-alt position-relative mx-auto">
                                    {burnedPotionNftsArr && brunedTokenTraits && brunedTokenTraits.length  ?
                                        brunedTokenTraits?.map((trait, i) => {
                                            return(
                                                <img 
                                                    key={i} 
                                                    src={trait.url ? trait.url : null } 
                                                    alt="" 
                                                    className={`${(trait.traitType != 'Background') ? `compose-cham-from-api-${trait.traitType} w-100 position-absolute` : `compose-cham-from-api-${trait.traitType} w-100 position-relative`}`} 
                                                /> 
                                            )
                                        })
                                        :
                                        null
                                    }
                                </div>
                                <div className="compose-cham-from-api-alt position-relative mx-auto p-4 pt-5">
                                    {burnedPotionNftsArr && brunedTokenTraits && brunedTokenTraits.length  ?
                                        brunedTokenTraits?.map((trait, i) => {
                                            return(
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <span className="text-secondary">{trait.traitType && trait.traitType} :</span>
                                                    <span className="text-dark">{trait.value && trait.value}</span>
                                                </div> 
                                            )
                                        })
                                        :
                                        null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default BurnedForPotionCardWrapper
