// User
export const LOGIN_USER_BY_ADDRESS = 'LOGIN_USER_BY_ADDRESS';
export const LOGIN_USER_BY_ADDRESS_SUCCESS = 'LOGIN_USER_BY_ADDRESS_SUCCESS';
export const LOGIN_USER_BY_ADDRESS_FAILED = 'LOGIN_USER_BY_ADDRESS_FAILED';
export const LOGIN_USER_BY_USERNAME = 'LOGIN_USER_BY_USERNAME';
export const LOGIN_USER_BY_USERNAME_SUCCESS = 'LOGIN_USER_BY_USERNAME_SUCCESS';
export const LOGIN_USER_BY_USERNAME_FAILED = 'LOGIN_USER_BY_USERNAME_FAILED';
export const GET_TOKEN_BY_USER = 'GET_TOKEN_BY_USER';
export const GET_TOKEN_BY_USER_SUCCESS = 'GET_TOKEN_BY_USER_SUCCESS';
export const GET_TOKEN_BY_USER_FAILED = 'GET_TOKEN_BY_USER_FAILED';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_FAILED = 'UPDATE_PROFILE_FAILED';
export const GET_USER_DETAIL_BY_ADDRESS = 'GET_USER_DETAIL_BY_ADDRESS';
export const GET_USER_DETAIL_BY_ADDRESS_SUCCESS = 'GET_USER_DETAIL_BY_ADDRESS_SUCCESS';
export const GET_USER_DETAIL_BY_ADDRESS_FAILED = 'GET_USER_DETAIL_BY_ADDRESS_FAILED';
export const UPLOAD_PROFILE_IMAGE = 'UPLOAD_PROFILE_IMAGE';
export const UPLOAD_PROFILE_IMAGE_SUCCESS = 'UPLOAD_PROFILE_IMAGE_SUCCESS';
export const UPLOAD_PROFILE_IMAGE_FAILED = 'UPLOAD_PROFILE_IMAGE_FAILED';
export const UPLOAD_BANNER_IMAGE = 'UPLOAD_BANNER_IMAGE';
export const UPLOAD_BANNER_IMAGE_SUCCESS = 'UPLOAD_BANNER_IMAGE_SUCCESS';
export const UPLOAD_BANNER_IMAGE_FAILED = 'UPLOAD_BANNER_IMAGE_FAILED';
// Chamagotchi
export const FIND_BY_TRAITS_TYPE = 'FIND_BY_TRAITS_TYPE';
export const FIND_BY_TRAITS_TYPE_SUCCESS = 'FIND_BY_TRAITS_TYPE_SUCCESS';
export const FIND_BY_TRAITS_TYPE_FAILED = 'FIND_BY_TRAITS_TYPE_FAILED';
export const CREATE_CHAMAGOTCHI = 'CREATE_CHAMAGOTCHI';
export const CREATE_CHAMAGOTCHI_SUCCESS = 'CREATE_CHAMAGOTCHI_SUCCESS';
export const CREATE_CHAMAGOTCHI_FAILED = 'CREATE_CHAMAGOTCHI_FAILED';
export const FIND_CHAMAGOTCHI_BY_USER = 'FIND_CHAMAGOTCHI_BY_USER';
export const FIND_CHAMAGOTCHI_BY_USER_SUCCESS = 'FIND_CHAMAGOTCHI_BY_USER_SUCCESS';
export const FIND_CHAMAGOTCHI_BY_USER_FAILED = 'FIND_CHAMAGOTCHI_BY_USER_FAILED';
export const CREATE_CHAMAGOTCHI_FEED = 'CREATE_CHAMAGOTCHI_FEED';
export const CREATE_CHAMAGOTCHI_FEED_SUCCESS = 'CREATE_CHAMAGOTCHI_FEED_SUCCESS';
export const CREATE_CHAMAGOTCHI_FEED_FAILED = 'CREATE_CHAMAGOTCHI_FEED_FAILED';
export const ON_AFTER_MINT = 'ON_AFTER_MINT';
export const ON_AFTER_MINT_SUCCESS = 'ON_AFTER_MINT_SUCCESS';
export const ON_AFTER_MINT_FAILED = 'ON_AFTER_MINT_FAILED';
export const ON_AFTER_AIRDROP = 'ON_AFTER_AIRDROP';
export const ON_AFTER_AIRDROP_SUCCESS = 'ON_AFTER_AIRDROP_SUCCESS';
export const ON_AFTER_AIRDROP_FAILED = 'ON_AFTER_AIRDROP_FAILED';
export const GET_CHAMAGOTCHI_DETAILS_BY_ID = 'GET_CHAMAGOTCHI_DETAILS_BY_ID';
export const GET_CHAMAGOTCHI_DETAILS_BY_ID_SUCCESS = 'GET_CHAMAGOTCHI_DETAILS_BY_ID_SUCCESS';
export const GET_CHAMAGOTCHI_DETAILS_BY_ID_FAILED = 'GET_CHAMAGOTCHI_DETAILS_BY_ID_FAILED';
export const GET_LEADER_BOARD_DATA = 'GET_LEADER_BOARD_DATA';
export const GET_LEADER_BOARD_DATA_SUCCESS = 'GET_LEADER_BOARD_DATA_SUCCESS';
export const GET_LEADER_BOARD_DATA_FAILED = 'GET_LEADER_BOARD_DATA_FAILED';
export const GET_CAPTCHA = 'GET_CAPTCHA';
export const GET_CAPTCHA_SUCCESS = 'GET_CAPTCHA_SUCCESS';
export const GET_CAPTCHA_FAILED = 'GET_CAPTCHA_FAILED';
// Potions Chameleon
export const GET_TOKEN_WITHOUT_POTION = 'GET_TOKEN_WITHOUT_POTION';
export const GET_TOKEN_WITHOUT_POTION_SUCCESS = 'GET_TOKEN_WITHOUT_POTION_SUCCESS';
export const GET_TOKEN_WITHOUT_POTION_FAILED = 'GET_TOKEN_WITHOUT_POTION_FAILED';
export const GET_ALL_POTIONS = 'GET_ALL_POTIONS';
export const GET_ALL_POTIONS_SUCCESS = 'GET_ALL_POTIONS_SUCCESS';
export const GET_ALL_POTIONS_FAILED = 'GET_ALL_POTIONS_FAILED';
export const ON_AFTER_MINT_POTIONS = 'ON_AFTER_MINT_POTIONS';
export const ON_AFTER_MINT_POTIONS_SUCCESS = 'ON_AFTER_MINT_POTIONS_SUCCESS';
export const ON_AFTER_MINT_POTIONS_FAILED = 'ON_AFTER_MINT_POTIONS_FAILED';
export const MAP_CHAMELEON_WITH_POTION = 'MAP_CHAMELEON_WITH_POTION';
export const MAP_CHAMELEON_WITH_POTION_SUCCESS = 'MAP_CHAMELEON_WITH_POTION_SUCCESS';
export const MAP_CHAMELEON_WITH_POTION_FAILED = 'MAP_CHAMELEON_WITH_POTION_FAILED';
export const GET_LIST_OF_POTION_MINT_TOKENS = 'GET_LIST_OF_POTION_MINT_TOKENS';
export const GET_LIST_OF_POTION_MINT_TOKENS_SUCCESS = 'GET_LIST_OF_POTION_MINT_TOKENS_SUCCESS';
export const GET_LIST_OF_POTION_MINT_TOKENS_FAILED = 'GET_LIST_OF_POTION_MINT_TOKENS_FAILED';
export const GET_LIST_OF_CHAMELEON_POTIONS = 'GET_LIST_OF_CHAMELEON_POTIONS';
export const GET_LIST_OF_CHAMELEON_POTIONS_SUCCESS = 'GET_LIST_OF_CHAMELEON_POTIONS_SUCCESS';
export const GET_LIST_OF_CHAMELEON_POTIONS_FAILED = 'GET_LIST_OF_CHAMELEON_POTIONS_FAILED';
export const ON_AFTER_BURN = 'ON_AFTER_BURN';
export const ON_AFTER_BURN_SUCCESS = 'ON_AFTER_BURN_SUCCESS';
export const ON_AFTER_BURN_FAILED = 'ON_AFTER_BURN_FAILED';
export const GET_TOKEN_DETAIL = 'GET_TOKEN_DETAIL';
export const GET_TOKEN_DETAIL_SUCCESS = 'GET_TOKEN_DETAIL_SUCCESS';
export const GET_TOKEN_DETAIL_FAILED = 'GET_TOKEN_DETAIL_FAILED';
export const GET_TOKEN_DETAIL_FOR_SWAP = 'GET_TOKEN_DETAIL_FOR_SWAP';
export const GET_TOKEN_DETAIL_FOR_SWAP_SUCCESS = 'GET_TOKEN_DETAIL_FOR_SWAP_SUCCESS';
export const GET_TOKEN_DETAIL_FOR_SWAP_FAILED = 'GET_TOKEN_DETAIL_FOR_SWAP_FAILED';
export const GET_MY_POTION = 'GET_MY_POTION';
export const GET_MY_POTION_SUCCESS = 'GET_MY_POTION_SUCCESS';
export const GET_MY_POTION_FAILED = 'GET_MY_POTION_FAILED';
export const POTION_APPLY = 'POTION_APPLY';
export const POTION_APPLY_SUCCESS = 'POTION_APPLY_SUCCESS';
export const POTION_APPLY_FAILED = 'POTION_APPLY_FAILED';
export const GET_TOKEN_EXCLUDED = 'GET_TOKEN_EXCLUDED';
export const GET_TOKEN_EXCLUDED_SUCCESS = 'GET_TOKEN_EXCLUDED_SUCCESS';
export const GET_TOKEN_EXCLUDED_FAILED = 'GET_TOKEN_EXCLUDED_FAILED';
export const IS_TOKEN_ALTERED = 'IS_TOKEN_ALTERED';
export const IS_TOKEN_ALTERED_SUCCESS = 'IS_TOKEN_ALTERED_SUCCESS';
export const IS_TOKEN_ALTERED_FAILED = 'IS_TOKEN_ALTERED_FAILED';
export const GET_TOKEN_BURNED = 'GET_TOKEN_BURNED';
export const GET_TOKEN_BURNED_SUCCESS = 'GET_TOKEN_BURNED_SUCCESS';
export const GET_TOKEN_BURNED_FAILED = 'GET_TOKEN_BURNED_FAILED';
// Dressing Chameleon && create chameleon
export const FIND_ALL_CHAM_TRAIT_TYPES = 'FIND_ALL_CHAM_TRAIT_TYPES';
export const FIND_ALL_CHAM_TRAIT_TYPES_SUCCESS = 'FIND_ALL_CHAM_TRAIT_TYPES_SUCCESS';
export const FIND_ALL_CHAM_TRAIT_TYPES_FAILED = 'FIND_ALL_CHAM_TRAIT_TYPES_FAILED';
export const FIND_CHAM_TRAITS_BY_TRAIT_TYPE = 'FIND_CHAM_TRAITS_BY_TRAIT_TYPE';
export const FIND_CHAM_TRAITS_BY_TRAIT_TYPE_SUCCESS = 'FIND_CHAM_TRAITS_BY_TRAIT_TYPE_SUCCESS';
export const FIND_CHAM_TRAITS_BY_TRAIT_TYPE_FAILED = 'FIND_CHAM_TRAITS_BY_TRAIT_TYPE_FAILED';
export const SAVE_PREVIEW_NFT = 'SAVE_PREVIEW_NFT';
export const SAVE_PREVIEW_NFT_SUCCESS = 'SAVE_PREVIEW_NFT_SUCCESS';
export const SAVE_PREVIEW_NFT_FAILED = 'SAVE_PREVIEW_NFT_FAILED';
export const GET_ALL_PREVIEW_NFT_LIST = 'GET_ALL_PREVIEW_NFT_LIST';
export const GET_ALL_PREVIEW_NFT_LIST_SUCCESS = 'GET_ALL_PREVIEW_NFT_LIST_SUCCESS';
export const GET_ALL_PREVIEW_NFT_LIST_FAILED = 'GET_ALL_PREVIEW_NFT_LIST_FAILED';
export const GET_ALL_OVERLAY_TYPE = 'GET_ALL_OVERLAY_TYPE';
export const GET_ALL_OVERLAY_TYPE_SUCCESS = 'GET_ALL_OVERLAY_TYPE_SUCCESS';
export const GET_ALL_OVERLAY_TYPE_FAILED = 'GET_ALL_OVERLAY_TYPE_FAILED';
export const GET_OVERLAY_BY_TYPE = 'GET_OVERLAY_BY_TYPE';
export const GET_OVERLAY_BY_TYPE_SUCCESS = 'GET_OVERLAY_BY_TYPE_SUCCESS';
export const GET_OVERLAY_BY_TYPE_FAILED = 'GET_OVERLAY_BY_TYPE_FAILED';
export const GET_ALL_OVERLAY = 'GET_ALL_OVERLAY';
export const GET_ALL_OVERLAY_SUCCESS = 'GET_ALL_OVERLAY_SUCCESS';
export const GET_ALL_OVERLAY_FAILED = 'GET_ALL_OVERLAY_FAILED';
export const ADD_TO_WISHLIST = 'ADD_TO_WISHLIST';
export const ADD_TO_WISHLIST_SUCCESS = 'ADD_TO_WISHLIST_SUCCESS';
export const ADD_TO_WISHLIST_FAILED = 'ADD_TO_WISHLIST_FAILED';
export const GET_ALL_WISHLIST = 'GET_ALL_WISHLIST';
export const GET_ALL_WISHLIST_SUCCESS = 'GET_ALL_WISHLIST_SUCCESS';
export const GET_ALL_WISHLIST_FAILED = 'GET_ALL_WISHLIST_FAILED';
export const DELETE_WISHLIST = 'DELETE_WISHLIST';
export const DELETE_WISHLIST_SUCCESS = 'DELETE_WISHLIST_SUCCESS';
export const DELETE_WISHLIST_FAILED = 'DELETE_WISHLIST_FAILED';
export const WISHLIST_SELECTED_FOR_PRINT = 'WISHLIST_SELECTED_FOR_PRINT';
export const WISHLIST_SELECTED_FOR_PRINT_SUCCESS = 'WISHLIST_SELECTED_FOR_PRINT_SUCCESS';
export const WISHLIST_SELECTED_FOR_PRINT_FAILED = 'WISHLIST_SELECTED_FOR_PRINT_FAILED';
export const AFTER_PAYMENT = 'AFTER_PAYMENT';
export const AFTER_PAYMENT_SUCCESS = 'AFTER_PAYMENT_SUCCESS';
export const AFTER_PAYMENT_FAILED = 'AFTER_PAYMENT_FAILED';
export const AFTER_PAYMENT_RESET = 'AFTER_PAYMENT_RESET';
export const DELETE_ORDER = 'DELETE_ORDER';
export const DELETE_ORDER_SUCCESS = 'DELETE_ORDER_SUCCESS';
export const DELETE_ORDER_FAILED = 'DELETE_ORDER_FAILED';
export const FIND_ALL_TRAITS = 'FIND_ALL_TRAITS';
export const FIND_ALL_TRAITS_SUCCESS = 'FIND_ALL_TRAITS_SUCCESS';
export const FIND_ALL_TRAITS_FAILED = 'FIND_ALL_TRAITS_FAILED';
export const FIND_ALL_FOR_DRESSING_ROOM = 'FIND_ALL_FOR_DRESSING_ROOM';
export const FIND_ALL_FOR_DRESSING_ROOM_SUCCESS = 'FIND_ALL_FOR_DRESSING_ROOM_SUCCESS';
export const FIND_ALL_FOR_DRESSING_ROOM_FAILED = 'FIND_ALL_FOR_DRESSING_ROOM_FAILED';
export const FIND_ALL_INVENTORY_TRAIT_TYPES = 'FIND_ALL_INVENTORY_TRAIT_TYPES';
export const FIND_ALL_INVENTORY_TRAIT_TYPES_SUCCESS = 'FIND_ALL_INVENTORY_TRAIT_TYPES_SUCCESS';
export const FIND_ALL_INVENTORY_TRAIT_TYPES_FAILED = 'FIND_ALL_INVENTORY_TRAIT_TYPES_FAILED';
export const FIND_ALL_AVAILABLE_INVENTORY = 'FIND_ALL_AVAILABLE_INVENTORY';
export const FIND_ALL_AVAILABLE_INVENTORY_SUCCESS = 'FIND_ALL_AVAILABLE_INVENTORY_SUCCESS';
export const FIND_ALL_AVAILABLE_INVENTORY_FAILED = 'FIND_ALL_AVAILABLE_INVENTORY_FAILED';
export const APPLY_INVENTORY = 'APPLY_INVENTORY';
export const APPLY_INVENTORY_SUCCESS = 'APPLY_INVENTORY_SUCCESS';
export const APPLY_INVENTORY_FAILED = 'APPLY_INVENTORY_FAILED';

// miscellaneous 
export const SET_WALLET_CONNECTED = 'SET_WALLET_CONNECTED';
export const SET_WALLET_CONNECTED_SUCCESS = 'SET_WALLET_CONNECTED_SUCCESS';
export const SET_WALLET_CONNECTED_FAILED = 'SET_WALLET_CONNECTED_FAILED';
export const SET_WALLET_ADDRESS = 'SET_WALLET_ADDRESS';
export const SET_WALLET_ADDRESS_SUCCESS = 'SET_WALLET_ADDRESS_SUCCESS';
export const SET_WALLET_ADDRESS_FAILED = 'SET_WALLET_ADDRESS_FAILED';


// logout & reset
export const USER_LOGOUT = 'USER_LOGOUT';
export const RESET_CHAMAGOTCHI = 'RESET_CHAMAGOTCHI';
export const RESET_STORE_STATUS = 'RESET_STORE_STATUS';
export const RESET_POTION = 'RESET_POTION';
export const RESET_DRESSING_CHAM = 'RESET_DRESSING_CHAM';
