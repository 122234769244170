import * as type from '../types';

const initialState = {
    isWalletConnected: false,
    walletAddress: '',
}

export default function miscellaneous(state = initialState, action) {
    switch (action.type) {
        case type.SET_WALLET_CONNECTED:
            return {
                ...state,
                isWalletConnected: action.payload.isWalletConnected,
            }
        case type.SET_WALLET_ADDRESS:
            return {
                ...state,
                walletAddress: action.payload.walletAddress,
            }
        default:
            return state;
    }
}