import React, { useEffect, useState } from 'react'
import Accordion from 'react-bootstrap/Accordion';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Footer from '../../components/Footer/Footer';
import { 
    applyInventory,
    findAllAvailableInventory, 
    findAllForDressingRoom, 
    findAllInventoryTraitTypes, 
    getAllPreviewNftList 
} from '../../redux/actions/dressingCham';
import { getTokenExcluded } from '../../redux/actions/potion';
import { RiMagicFill } from 'react-icons/ri';
import './ChamInventory.css'
import { toast } from 'react-toastify';

function ChamInventory({setActivePage}) {
    const [isInventoryModalOpen, setISInventoryModalOpen] = useState(false);
    const [localChams, setLocalChams] = useState([]);
    const [localInventoryTraitsTypes, setLocalInventoryTraitsTypes] = useState([]);
    const [localInventoryTraits, setLocalInventoryTraits] = useState([]);
    const [activeInventoryTraitAccordian, setActiveInventoryTraitAccordian] = useState([]);
    const [selectedChamForInventory, setSelectedChamForInventory] = useState([]);
    const [selectedTraitForInventory, setSelectedTraitForInventory] = useState([]);
    const [appliedInventoryTraits, setAppliedInventoryTraits] = useState([]);
    const [appliedInventoryIds, setAppliedInventoryIds] = useState([]);

    const history = useHistory();
    const dispatch = useDispatch();
    const user = useSelector(state => state.users.user);
    const chameleonNft = useSelector(state => state.potion.chameleonNft);
    const allPreviewNft = useSelector(state => state.dressingCham.allPreviewNft);
    const allInventoryTraitsTypes = useSelector(state => state.dressingCham.allInventoryTraitsTypes);
    // const allTraitsForDressingRoom = useSelector(state => state.dressingCham.allTraitsForDressingRoom);
    const allAvailableInventory = useSelector(state => state.dressingCham.allAvailableInventory);
    const getTokenExcludedStatus = useSelector(state => state.potion.getTokenExcludedStatus);
    const applyInventoryStatus = useSelector(state => state.dressingCham.applyInventoryStatus);

    const selectChamForInventory = (e) => {
        const {name, checked} = e.target;
        let tempLocalChams = localChams.map((nft) => 
            nft.tokenId == name ? {...nft, isChecked: checked} : {...nft, isChecked: false} 
        );
        setLocalChams(tempLocalChams);
    }

    const selectTraitForInventory = (e, traitType) => {
        const {name, checked} = e.target;
        let tempSelectedTraitForInventory = [];
        if (checked){
            let trait = localInventoryTraits?.filter((trait) => { return trait.inventoryId == name })[0];
            tempSelectedTraitForInventory =  selectedTraitForInventory?.filter((traitItem) => { return traitItem.trait != traitType });
            tempSelectedTraitForInventory.push(trait);
        }else{
            tempSelectedTraitForInventory =  selectedTraitForInventory?.filter((traitItem) => { return traitItem.inventoryId != name });
        }
        let tempLocalInventoryTraits = JSON.parse(JSON.stringify(localInventoryTraits));
        tempLocalInventoryTraits = tempLocalInventoryTraits.map((trait) => trait && {...trait, isChecked: false}  );
        for (let i = 0; i< tempSelectedTraitForInventory.length; i++){
            let tempItem = tempSelectedTraitForInventory[i];
            for (let j = 0; j< tempLocalInventoryTraits.length; j++) {
                let localTrait = tempLocalInventoryTraits[j];
                if(localTrait.inventoryId == tempItem.inventoryId){
                    localTrait['isChecked'] = true;
                }
            }
        }
        setLocalInventoryTraits(tempLocalInventoryTraits);
        setSelectedTraitForInventory(tempSelectedTraitForInventory);
    }

    function contains(a, obj) {
        var i = a.length;
        while (i--) {
            if (a[i].traitId === obj) {
                return true;
            }
        }
        return false;
    }

    const validateTraitRelation = () => {
        let isRelatedTraitPresent = 0; 
        let chamTraitTypeIds = [];
        chamTraitTypeIds = selectedTraitForInventory?.map((item) => {return item.chamTraitTypeId})

        let traitError = false;
        for (let i = 0; i < selectedTraitForInventory?.length; i++) {
            let relatedTraits = allInventoryTraitsTypes?.filter((trait) => {
                return trait.traitId == selectedTraitForInventory[i].traitId
            })
            if (traitError) {
                break;
            }
            if (relatedTraits[0]?.relativeTraits.length > 0) {
                isRelatedTraitPresent = 1;
                for (let j = 0; j < relatedTraits[0]?.relativeTraits.length; j++) {
                    if (relatedTraits[0]?.relativeTraits[j].relation == 'AND') {
                        if (contains(selectedTraitForInventory, relatedTraits[0]?.relativeTraits[j].relationWith)) {
                            let chamTraitTypeIds = [];
                            chamTraitTypeIds = selectedTraitForInventory?.map((item) => {
                                return item.chamTraitTypeId
                            })
                            setISInventoryModalOpen(true)
                            traitError = true;
                        } else {
                            let relatedName = allInventoryTraitsTypes?.filter((trait) => {
                                return trait.traitId == relatedTraits[0]?.relativeTraits[j].relationWith
                            })[0].trait
                            // DUE TO KEVIN ::: When you go to change to a new weapon, it also forces a new arm change. We don’t need to force arm change.

                           /* console.log("relatedName :", relatedName)
                            toast.warning('Please select '+relatedName+" to proceed", {
                                position: "bottom-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: false,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            })*/
                            setISInventoryModalOpen(true)

                            traitError = true;
                            break;
                        }
                    } else if (relatedTraits[0]?.relativeTraits[j].relation == 'OR') {
                        // todo check for OR
                        if (contains(selectedTraitForInventory, relatedTraits[0]?.relativeTraits[j].relationWith)) {
                            let relatedName = relatedTraits[0].trait
                            let relatedName1 = allInventoryTraitsTypes?.filter((trait) => {
                                return trait.traitId == relatedTraits[0]?.relativeTraits[j].relationWith
                            })[0].trait
                            console.log("relatedName 2:", relatedName)
                            console.log("relatedName 2.2:", relatedTraits[0].trait)
                            toast.warning('Please remove '+relatedName+" or "+relatedName1+" to proceed", {
                                position: "bottom-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: false,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            })
                            traitError = true;
                            break;
                        } else {
                            setISInventoryModalOpen(true)
                            let chamTraitTypeIds = [];
                            chamTraitTypeIds = selectedTraitForInventory?.map((item) => {
                                return item.chamTraitTypeId
                            })
                            
                            traitError = true;
                        }
                    }
                }
            }else {
                isRelatedTraitPresent = 0;
            }
        }
        console.log("isRelatedTraitPresent :", isRelatedTraitPresent)
        if(!isRelatedTraitPresent){
            setISInventoryModalOpen(true)
        }
    } 

    useEffect(() => {
        setLocalChams(chameleonNft)
    }, [chameleonNft]);
    
    useEffect(() => {
        if(getTokenExcludedStatus == 401) {
            history.push('/signup')
        }else if(getTokenExcludedStatus == 200){
            // dispatch(getAllPreviewNftList())
            dispatch(findAllInventoryTraitTypes())
            dispatch(findAllForDressingRoom())
            dispatch(findAllAvailableInventory())
        }else
            return;
    }, [getTokenExcludedStatus]);
    
    useEffect(() => {
        setLocalInventoryTraitsTypes(allInventoryTraitsTypes)
    }, [allInventoryTraitsTypes]);
  
    useEffect(() => {
        setLocalInventoryTraits(allAvailableInventory)
    }, [allAvailableInventory]);

    // update selected chams local state 
    useEffect(() => {
        let tempSelectedChamForInventory = localChams?.filter((overlay) => { return overlay.isChecked });
        setSelectedChamForInventory(tempSelectedChamForInventory);
    }, [localChams]);

    useEffect(() => {
        let tempAppliedInventoryTraits = selectedTraitForInventory?.map((item) => {return item.trait})
        setAppliedInventoryTraits(tempAppliedInventoryTraits)
    }, [selectedTraitForInventory]);

    useEffect(() => {
        let tempAppliedInventoryIds = selectedTraitForInventory?.map((item) => {return item.inventoryId})
        setAppliedInventoryIds(tempAppliedInventoryIds)
    }, [selectedTraitForInventory]);

    useEffect(() => {
        if(applyInventoryStatus === 200){
            dispatch(getTokenExcluded())
            setAppliedInventoryIds([]);
            setSelectedChamForInventory([]);
            setSelectedTraitForInventory([])
            setAppliedInventoryTraits([])
            setLocalChams(chameleonNft)
            setLocalInventoryTraits(allAvailableInventory)
            setLocalInventoryTraitsTypes(allInventoryTraitsTypes)
        }
    }, [applyInventoryStatus]);

    useEffect(() => {
        dispatch(getTokenExcluded())
        setActivePage('inventory');
        return () => {
            setActivePage('');
        }
    }, []);

    return (
        <>
            <div className={`${isInventoryModalOpen ? "wishlist-add-to-cart-modal-wrapper d-flex justify-content-center align-items-center position-fixed" : "d-none"}`}>
                <div className="discard-order-modal">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLongTitle">Confirmation</h5>
                            <button type="button" className="close" onClick={() => {
                                setISInventoryModalOpen(false);
                            }}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <span className="p-4">
                                Are you sure to apply changes to your NFT.
                            </span>
                        </div>
                        <div className="modal-footer">
                            <button 
                                type="button" 
                                className="btn btn-outline-danger" 
                                onClick={() => {setISInventoryModalOpen(false);}}
                            >
                                Discard
                            </button>
                            <button
                                type="button" 
                                className="btn btn-outline-primary"
                                onClick={() => {dispatch(applyInventory({tokenId: selectedChamForInventory[0].tokenId, inventoryIds: appliedInventoryIds}));setISInventoryModalOpen(false);}}
                            >
                                Apply Changes
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className='cham-dressing-page row w-100 bg_white m-0'>
                <div className="col-2 px-0 cham-slider-wrapper">
                    <div className="all-cham-slider-wrapper">
                        <button className="btn btn-primary w-100 header-btn-styles cursor-default rounded-0" disabled>My Chameleons</button>
                        <div className="all-cham-slider-alt d-flex flex-column align-items-center bg_white w-100 p-2 py-xxl-4 position-relative">
                        {localChams.length > 0 && 
                            localChams?.map((nft, i) => {
                                return(
                                    <div key={i} className={"all-cham-slider-items mb-2 position-relative"}>
                                        <img src={nft.image && nft.image} alt="" className="w-100" />
                                        <div className="form-check cham-thumbnails-check-box position-absolute">
                                            <input type="checkbox" 
                                                className=" form-check-input custom-checkbox cursor-pointer" 
                                                id="materialUnchecked"
                                                name={nft.tokenId} 
                                                checked={nft.isChecked || false}
                                                onChange={(e) => selectChamForInventory(e)}
                                            />
                                        </div>
                                        <div className="cham-thumbnails-token-no position-absolute">
                                            {nft.token}
                                        </div>
                                    </div>
                                )
                            })
                        }
                        {allPreviewNft.length && 
                            allPreviewNft?.map((nft, i) => {
                                return(
                                    <div key={i} className="all-cham-slider-items mb-4">
                                        <img src={nft.imageUrl && nft.imageUrl} alt="" className="w-100" />
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
                <div className="col-3 w-100 px-0">
                    <div className="d-flex flex-column w-100">
                        <div className="overlay-custom-accordin-wrapper">
                            <button className="btn btn-primary w-100 header-btn-styles cursor-default rounded-0" disabled>My Inventory</button>
                            <div className='overlay-custom-accordin-container'>
                                <Accordion defaultActiveKey={['0']}>
                                    {localInventoryTraitsTypes.length &&
                                        localInventoryTraitsTypes?.map((Trait, i) => {
                                            return(
                                                <Accordion.Item eventKey={i} key={i} >
                                                    <Accordion.Header onClick={() => setActiveInventoryTraitAccordian(Trait.trait)}>
                                                        <div className="overlay-custom-accordin-btn">
                                                            {Trait.trait}
                                                        </div>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className="row overlay-thumbnails-wrapper">
                                                            {localInventoryTraits.length && Trait.trait === activeInventoryTraitAccordian &&
                                                                localInventoryTraits?.map((InventoryTrait, i) => {
                                                                    return(
                                                                        <>
                                                                            {InventoryTrait.trait === activeInventoryTraitAccordian &&
                                                                                <div className="col-4 p-2">
                                                                                    <div className="overlay-thumbnails position-relative">
                                                                                        <img src={InventoryTrait.imageUrl && InventoryTrait.imageUrl} alt="" className="w-100" />
                                                                                        <div className="form-check overlay-thumbnails-check-box position-absolute">
                                                                                            <input type="checkbox" 
                                                                                                className=" form-check-input custom-checkbox cursor-pointer" 
                                                                                                id="materialUnchecked"
                                                                                                name={InventoryTrait.inventoryId}
                                                                                                checked={InventoryTrait.isChecked || false}
                                                                                                onChange={(e) => selectTraitForInventory(e, InventoryTrait.trait)}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                        </>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            )
                                        })
                                    }
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-7 px-0">
                    <div className="cham-overlay-preview-wrapper d-flex flex-column border position-relative bg_white h-100 w-100">
                        <button className="btn btn-primary w-100 header-btn-styles cursor-default rounded-0" disabled>Preview</button>
                        <div className="cham-overlay-preview-wrapper-container d-flex flex-column h-100 pt-2">
                            <div className="row">
                                <div className="col-8 mx-auto">
                                    <div className="cham-invantory-preview px-4">
                                        {selectedChamForInventory.length ? 
                                            selectedChamForInventory?.map((nft, i) => {
                                                return(
                                                    <div className="dressing-cham-preview position-relative w-100">
                                                        {/* fixed bg layer for propper grid view */}
                                                        {nft && (selectedTraitForInventory.length == 0) &&
                                                            <div className={`dressing-cham-preview-layered-bg`}>
                                                                <img src={nft.image && nft.image} alt="" className="w-100" />
                                                            </div>
                                                        }
                                                        {
                                                            nft.attributes?.map((traitLayer, i) => {
                                                                return (
                                                                    appliedInventoryTraits?.filter((item) => {return item == traitLayer.traitType}).length > 0  ?
                                                                        null
                                                                        :appliedInventoryTraits?.filter((item) => {return item == 'Hat' && traitLayer.traitType == 'Helmet'}).length > 0  ?
                                                                            null
                                                                            :appliedInventoryTraits?.filter((item) => {return item == 'Helmet' && traitLayer.traitType == 'Hat'}).length > 0  ?
                                                                                null
                                                                                :appliedInventoryTraits?.filter((item) => {return item == 'Mask' && traitLayer.traitType == 'Prop'}).length > 0  ?
                                                                                    null
                                                                                    :appliedInventoryTraits?.filter((item) => {return item == 'Prop' && traitLayer.traitType == 'Mask'}).length > 0  ?
                                                                                        null
                                                                                        :appliedInventoryTraits?.filter((item) => {return item == 'Clothes' && traitLayer.traitType == 'Armor'}).length > 0  ?
                                                                                            null
                                                                                            :appliedInventoryTraits?.filter((item) => {return item == 'Armor' && traitLayer.traitType == 'Clothes'}).length > 0  ?
                                                                                                null
                                                                                                :
                                                                    <div className={`dressing-cham-preview-layered position-absolute w-100 ${"dressing-cham-"+(traitLayer.traitType).split(' ').join('_')}`}>
                                                                        <img src={traitLayer.url && traitLayer.url} alt="" className="w-100" />
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                        {selectedTraitForInventory?.map((inventoryTrait, i) => {
                                                                return (
                                                                    <div key={i} className={`dressing-preview-section-overlay position-absolute dressing-cham-Overlay w-100 ${"dressing-cham-"+(inventoryTrait.trait).split(' ').join('_')}`}>
                                                                        <img src={inventoryTrait.imageUrl && inventoryTrait.imageUrl} alt="" className="w-100"/>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                )
                                            })
                                            :
                                            null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='apply-inventory-btn mb-2 w-50 mx-auto bg-black'>
                            <button 
                                className={(selectedTraitForInventory.length != 0) && (selectedChamForInventory.length != 0) ? "btn btn-primary w-100 rounded-0" : "btn btn-outline-primary w-100 rounded-0"} 
                                onClick={() => {validateTraitRelation()}}
                                disabled={(selectedTraitForInventory.length == 0) || (selectedChamForInventory.length == 0)}
                            >
                                <RiMagicFill className='text-warning mb-1 mx-2'/>
                                Apply selected traits 
                                <RiMagicFill className='text-warning mb-1 mx-2'/>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <Footer fullWidth/>
        </>
    )
}

export default ChamInventory
