import { call, put, takeEvery } from 'redux-saga/effects';
import { 
    createChamagotchiApi,
    createChamagotchiFeedApi,
    findByTraitTypeApi,
    findChamagotchiByUserApi,
    getCaptchaApi,
    getChamagotchiDetailsByIdApi,
    getLeaderBoardDataApi,
    onAfterairdropApi,
    onAfterMintApi,
 } from '../services/chamagotchi';
import { 
    createChamagotchiFailed,
    createChamagotchiFeedFailed,
    createChamagotchiFeedSuccess,
    createChamagotchiSuccess,
    findByTraitTypeFailed, 
    findByTraitTypeSuccess, 
    findChamagotchiByUser, 
    findChamagotchiByUserFailed, 
    findChamagotchiByUserSuccess,
    getCaptchaFailed,
    getCaptchaSuccess,
    getChamagotchiDetailsByIdFailed,
    getChamagotchiDetailsByIdSuccess,
    getLeaderBoardDataFailed,
    getLeaderBoardDataSuccess,
    onAfterairdropFailed,
    onAfterairdropSuccess,
    onAfterMintFailed,
    onAfterMintSuccess
} from '../actions/chamagotchi';
import store from '../store';
import { toast } from 'react-toastify';

function* findByTraitTypeSaga({ payload }) {
    try{
        const responce = yield call(findByTraitTypeApi , payload)
        if(responce){
            if (responce.data.status === 200) {
                store.dispatch(findByTraitTypeSuccess({status: responce.data.status, traits: responce.data.response}))
                // console.log("findByTraitTypeSagaSuccess",responce.data)
            }else {
                store.dispatch(findByTraitTypeFailed(responce.data.message))
                toast.error( responce.data.message , {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                },);
                // console.log("findByTraitTypeSagaFailed",responce.data.status)
            }
        }
    } catch (e) {
        // console.log('FIND_BY_TRAITS_TYPE',e)
        // yield put({ type: 'FIND_BY_TRAITS_TYPE', message: e.message });
    }
}

function* createChamagotchiSaga({ payload }) {
    // console.log("createChamagotchiSaga",payload)
    try{
        const responce = yield call(createChamagotchiApi , payload)
        if(responce){
            if (responce.data.status === 200) {
                store.dispatch(createChamagotchiSuccess({status: responce.data.status, message: responce.data.message}))
                toast.success('Chamagotchi NFT created successfully 👍', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                },);
                // console.log("createChamagotchiSagaSuccess",responce.data)
            }else {
                store.dispatch(createChamagotchiFailed(responce.data.message))
                toast.error( responce.data.message , {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                },);
                // console.log("createChamagotchiSagaFailed",responce.data.status)
            }
        }
    } catch (e) {
        // console.log('FIND_BY_TRAITS_TYPE',e)
        // yield put({ type: 'FIND_BY_TRAITS_TYPE', message: e.message });
    }
}

function* findChamagotchiByUserSaga({ payload }) {
    // console.log("findChamagotchiByUserSaga",payload)
    try{
        const responce = yield call(findChamagotchiByUserApi , payload)
        if(responce){
            if (responce.data.status === 200) {
                store.dispatch(findChamagotchiByUserSuccess({status: responce.data.status, chamagotchis: responce.data.response}))
                // console.log("findChamagotchiByUserSagaSuccess",responce.data)
            }else {
                // store.dispatch(findChamagotchiByUserFailed(responce.data.message));
                // console.log("findChamagotchiByUserSagaFailed",responce.data.status)
            }
        }
    } catch (e) {
        // console.log('FIND_BY_TRAITS_TYPE',e)
        // yield put({ type: 'FIND_BY_TRAITS_TYPE', message: e.message });
    }
}

function* createChamagotchiFeedSaga({ payload }) {
    // console.log("createChamagotchiFeedSaga",payload)
    try{
        const responce = yield call(createChamagotchiFeedApi , payload)
        if(responce){
            if (responce.data.status === 200) {
                // debugger;
                store.dispatch(createChamagotchiFeedSuccess({status: responce.data.status}))
                toast.success( responce.data.message , {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                },)
                store.dispatch(findChamagotchiByUser(payload.userId));
                // console.log("createChamagotchiFeedSagaSuccess",responce.data)
            }else {
                store.dispatch(createChamagotchiFeedFailed({status: responce.data.status, message: responce.data.message}))
                toast.error( responce.data.message , {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                },)
                store.dispatch(findChamagotchiByUser(payload.userId));
                // console.log("createChamagotchiFeedSagaFailed",responce.data.status)
            }
        }
    } catch (e) {
        // console.log('FIND_BY_TRAITS_TYPE',e)
        // yield put({ type: 'FIND_BY_TRAITS_TYPE', message: e.message });
    }
}

function* onAfterMintSaga({ payload }) {
    // console.log("onAfterMintSaga",payload)
    try{
        const responce = yield call(onAfterMintApi , payload)
        if(responce){
            if (responce.data.status === 200) {
                store.dispatch(onAfterMintSuccess({status: responce.data.status, ChamagotchiDetails:responce.data.response }))
                // console.log("onAfterMintSuccess",responce.data)
                toast.success( responce.data.message , {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                },)
            }else if (responce.data.status === 302) {
                // debugger;
                store.dispatch(createChamagotchiFeedSuccess({status: responce.data.status}))
                toast.success( responce.data.message , {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                },)
                store.dispatch(findChamagotchiByUser(payload.userId));
                // console.log("createChamagotchiFeedSagaSuccess",responce.data)
            }else {
                store.dispatch(onAfterMintFailed(responce.data.message))
                toast.error( responce.data.message , {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                },)
                // console.log("onAfterMintSagaFailed",responce.data.status)
            }
        }
    } catch (e) {
        // console.log('FIND_BY_TRAITS_TYPE',e)
        // yield put({ type: 'FIND_BY_TRAITS_TYPE', message: e.message });
    }
}

function* onAfterairdropSaga({ payload }) {
    // console.log("onAfterairdropSaga",payload)
    try{
        const responce = yield call(onAfterairdropApi , payload)
        if(responce){
            if (responce.data.status === 200) {
                store.dispatch(onAfterairdropSuccess({status: responce.data.status}))
                // console.log("onAfterairdropSuccess",responce.data)
                toast.success( responce.data.message , {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                },);
            }else {
                store.dispatch(onAfterairdropFailed(responce.data.message))
                toast.error( responce.data.message , {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                },);
                // console.log("onAfterairdropSagaFailed",responce.data.status)
            }
        }
    } catch (e) {
        // console.log('FIND_BY_TRAITS_TYPE',e)
        // yield put({ type: 'FIND_BY_TRAITS_TYPE', message: e.message });
    }
}

function* getChamagotchiDetailsByIdSaga({ payload }) {
    // console.log("getChamagotchiDetailsByIdSaga",payload)
    try{
        const responce = yield call(getChamagotchiDetailsByIdApi , payload)
        if(responce){
            if (responce.data.status === 200) {
                store.dispatch(getChamagotchiDetailsByIdSuccess({status: responce.data.status, ChamagotchiDetailsById:responce.data.response }))
                // console.log("getChamagotchiDetailsByIdSuccess",responce.data)
                // toast.success( responce.data.message , {
                //     position: "bottom-right",
                //     autoClose: 5000,
                //     hideProgressBar: false,
                //     closeOnClick: false,
                //     pauseOnHover: true,
                //     draggable: true,
                //     progress: undefined,
                // },);
            }else {
                store.dispatch(getChamagotchiDetailsByIdFailed(responce.data.message))
                toast.error( responce.data.message , {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                },);
                // console.log("getChamagotchiDetailsByIdSagaFailed",responce.data.status)
            }
        }
    } catch (e) {
        // console.log('FIND_BY_TRAITS_TYPE',e)
        // yield put({ type: 'FIND_BY_TRAITS_TYPE', message: e.message });
    }
}

function* getLeaderBoardDataSaga({ payload }) {
    // console.log("getLeaderBoardDataSaga",payload)
    try{
        const responce = yield call(getLeaderBoardDataApi , payload)
        if(responce){
            if (responce.data.status === 200) {
                store.dispatch(getLeaderBoardDataSuccess({status: responce.data.status, leaderBoardData: responce.data.response }))
                // console.log("getLeaderBoardDataSuccess",responce.data)
                // toast.success( responce.data.message , {
                //     position: "bottom-right",
                //     autoClose: 5000,
                //     hideProgressBar: false,
                //     closeOnClick: false,
                //     pauseOnHover: true,
                //     draggable: true,
                //     progress: undefined,
                // },);
            }else {
                store.dispatch(getLeaderBoardDataFailed(responce.data.message))
                toast.error( responce.data.message , {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                },);
                // console.log("getLeaderBoardDataSagaFailed",responce.data.status)
            }
        }
    } catch (e) {
        // console.log('FIND_BY_TRAITS_TYPE',e)
        // yield put({ type: 'FIND_BY_TRAITS_TYPE', message: e.message });
    }
}

function* getCaptchaSaga({ payload }) {
    // console.log("getCaptchaSaga",payload)
    try{
        const responce = yield call(getCaptchaApi , payload)
        if(responce){
            // console.log(("getCaptchaSuccess",responce)
            if (responce.data.status === 200) {
                store.dispatch(getCaptchaSuccess({status: responce.data.status, captchaCode: responce.data.response.captchaCode, captchaBase64: responce.data.response.captcha }))
                // toast.success( responce.data.message , {
                //     position: "bottom-right",
                //     autoClose: 5000,
                //     hideProgressBar: false,
                //     closeOnClick: false,
                //     pauseOnHover: true,
                //     draggable: true,
                //     progress: undefined,
                // },);
            }else {
                store.dispatch(getCaptchaFailed(responce.data.message))
                toast.error( responce.data.message , {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                },);
                // console.log("getCaptchaSagaFailed",responce.data.status)
            }
        }
    } catch (e) {
        // console.log('FIND_BY_TRAITS_TYPE',e)
        // yield put({ type: 'FIND_BY_TRAITS_TYPE', message: e.message });
    }
}

function* chamagotchiSaga() {
    yield takeEvery('FIND_BY_TRAITS_TYPE', findByTraitTypeSaga );
    yield takeEvery('CREATE_CHAMAGOTCHI', createChamagotchiSaga );
    yield takeEvery('FIND_CHAMAGOTCHI_BY_USER', findChamagotchiByUserSaga );
    yield takeEvery('CREATE_CHAMAGOTCHI_FEED', createChamagotchiFeedSaga );
    yield takeEvery('ON_AFTER_MINT', onAfterMintSaga );
    yield takeEvery('ON_AFTER_AIRDROP', onAfterairdropSaga );
    yield takeEvery('GET_CHAMAGOTCHI_DETAILS_BY_ID', getChamagotchiDetailsByIdSaga );
    yield takeEvery('GET_LEADER_BOARD_DATA', getLeaderBoardDataSaga );
    yield takeEvery('GET_CAPTCHA', getCaptchaSaga );
}

export default chamagotchiSaga;
