import * as type from '../types';

export function getTokenWithoutPotionSuccess(payload) {
    return {
        type: type.GET_TOKEN_WITHOUT_POTION_SUCCESS,
        payload,
    }
}

export function getTokenWithoutPotion(payload) {
    return {
        type: type.GET_TOKEN_WITHOUT_POTION,
        payload,
    }
}

export function getTokenWithoutPotionFailed(payload) {
    return {
        type: type.GET_TOKEN_WITHOUT_POTION_FAILED,
        payload,
    }
}

export function getAllPotionsSuccess(payload) {
    return {
        type: type.GET_ALL_POTIONS_SUCCESS,
        payload,
    }
}

export function getAllPotions(payload) {
    return {
        type: type.GET_ALL_POTIONS,
        payload,
    }
}

export function getAllPotionsFailed(payload) {
    return {
        type: type.GET_ALL_POTIONS_FAILED,
        payload,
    }
}

export function onAfterMintPotionsSuccess(payload) {
    return {
        type: type.ON_AFTER_MINT_POTIONS_SUCCESS,
        payload,
    }
}

export function onAfterMintPotions(payload) {
    return {
        type: type.ON_AFTER_MINT_POTIONS,
        payload,
    }
}

export function onAfterMintPotionsFailed(payload) {
    return {
        type: type.ON_AFTER_MINT_POTIONS_FAILED,
        payload,
    }
}

export function mapChameleonWithPotionSuccess(payload) {
    return {
        type: type.MAP_CHAMELEON_WITH_POTION_SUCCESS,
        payload,
    }
}

export function mapChameleonWithPotion(payload) {
    return {
        type: type.MAP_CHAMELEON_WITH_POTION,
        payload,
    }
}

export function mapChameleonWithPotionFailed(payload) {
    return {
        type: type.MAP_CHAMELEON_WITH_POTION_FAILED,
        payload,
    }
}

export function getListOfPotionMintTokensSuccess(payload) {
    return {
        type: type.GET_LIST_OF_POTION_MINT_TOKENS_SUCCESS,
        payload,
    }
}

export function getListOfPotionMintTokens(payload) {
    return {
        type: type.GET_LIST_OF_POTION_MINT_TOKENS,
        payload,
    }
}

export function getListOfPotionMintTokensFailed(payload) {
    return {
        type: type.GET_LIST_OF_POTION_MINT_TOKENS_FAILED,
        payload,
    }
}

export function getListOfChameleonPotionsSuccess(payload) {
    return {
        type: type.GET_LIST_OF_CHAMELEON_POTIONS_SUCCESS,
        payload,
    }
}

export function getListOfChameleonPotions(payload) {
    return {
        type: type.GET_LIST_OF_CHAMELEON_POTIONS,
        payload,
    }
}

export function getListOfChameleonPotionsFailed(payload) {
    return {
        type: type.GET_LIST_OF_CHAMELEON_POTIONS_FAILED,
        payload,
    }
}

export function onAfterBurnSuccess(payload) {
    return {
        type: type.ON_AFTER_BURN_SUCCESS,
        payload,
    }
}

export function onAfterBurn(payload) {
    return {
        type: type.ON_AFTER_BURN,
        payload,
    }
}

export function onAfterBurnFailed(payload) {
    return {
        type: type.ON_AFTER_BURN_FAILED,
        payload,
    }
}

export function getTokenDetailSuccess(payload) {
    return {
        type: type.GET_TOKEN_DETAIL_SUCCESS,
        payload,
    }
}

export function getTokenDetail(payload) {
    return {
        type: type.GET_TOKEN_DETAIL,
        payload,
    }
}

export function getTokenDetailFailed(payload) {
    return {
        type: type.GET_TOKEN_DETAIL_FAILED,
        payload,
    }
}

export function getTokenDetailForSwapSuccess(payload) {
    return {
        type: type.GET_TOKEN_DETAIL_FOR_SWAP_SUCCESS,
        payload,
    }
}

export function getTokenDetailForSwap(payload) {
    return {
        type: type.GET_TOKEN_DETAIL_FOR_SWAP,
        payload,
    }
}

export function getTokenDetailForSwapFailed(payload) {
    return {
        type: type.GET_TOKEN_DETAIL_FOR_SWAP_FAILED,
        payload,
    }
}

export function getMyPotionSuccess(payload) {
    return {
        type: type.GET_MY_POTION_SUCCESS,
        payload,
    }
}

export function getMyPotion(payload) {
    return {
        type: type.GET_MY_POTION,
        payload,
    }
}

export function getMyPotionFailed(payload) {
    return {
        type: type.GET_MY_POTION_FAILED,
        payload,
    }
}

export function potionApplySuccess(payload) {
    return {
        type: type.POTION_APPLY_SUCCESS,
        payload,
    }
}

export function potionApply(payload) {
    return {
        type: type.POTION_APPLY,
        payload,
    }
}

export function potionApplyFailed(payload) {
    return {
        type: type.POTION_APPLY_FAILED,
        payload,
    }
}

export function getTokenExcludedSuccess(payload) {
    return {
        type: type.GET_TOKEN_EXCLUDED_SUCCESS,
        payload,
    }
}

export function getTokenExcluded(payload) {
    return {
        type: type.GET_TOKEN_EXCLUDED,
        payload,
    }
}

export function getTokenExcludedFailed(payload) {
    return {
        type: type.GET_TOKEN_EXCLUDED_FAILED,
        payload,
    }
}

export function isTokenAlteredSuccess(payload) {
    return {
        type: type.IS_TOKEN_ALTERED_SUCCESS,
        payload,
    }
}

export function isTokenAltered(payload) {
    return {
        type: type.IS_TOKEN_ALTERED,
        payload,
    }
}

export function isTokenAlteredFailed(payload) {
    return {
        type: type.IS_TOKEN_ALTERED_FAILED,
        payload,
    }
}

export function getTokenBurnedSuccess(payload) {
    return {
        type: type.GET_TOKEN_BURNED_SUCCESS,
        payload,
    }
}

export function getTokenBurned(payload) {
    return {
        type: type.GET_TOKEN_BURNED,
        payload,
    }
}

export function getTokenBurnedFailed(payload) {
    return {
        type: type.GET_TOKEN_BURNED_FAILED,
        payload,
    }
}

// For reset
export function resetPotion(payload) {
    return {
        type: type.RESET_POTION,
        payload,
    }
}