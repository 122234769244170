import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FaTimes } from 'react-icons/fa';
import { BsArrowRepeat } from 'react-icons/bs';
import NoImage from '../../assets/Img/no_image.png';
import ReCAPTCHA from "react-google-recaptcha"
import { createChamagotchiFeed, getCaptcha, resetChamagotchi } from '../../redux/actions/chamagotchi';
import {getRemainingTimeUntilMsTimestamp} from "../ChamagotchiNftCardWrapper/utils/CountdownTimerUtils";
import { resetStatus, userLogout } from '../../redux/actions/users';
import { toast } from 'react-toastify';
import './NftPopup.css';
import { PulseLoader } from 'react-spinners';

function NftPopup({  
    show, 
    onClose, 
    onOpen, 
    tokanNo, 
    userId, 
    nftImgUrl, 
    popupAlt, 
    nextFeedEndTime, 
    setShowNftPopup, 
    isLive, 
    setIsLive,
    deadTime,
    setDeadTime,
    setAccountNo,
    setUserId,
    setActivePage, }) {
    const [showPopup, setShowPopup] = useState(show);
    const [isVarified, setIsVarified] = useState(false);
    const [captchaResponse, setCaptchaResponse] = useState('');


    useEffect(() => {
        setShowPopup(show);
    }, [show]);
  
    useEffect(() => {
        if (showPopup) {
            if (onOpen) onOpen(showPopup);
        } else {
            if (onClose) {
                onClose(showPopup)
                resetChamagotchi();
            };
        }
    }, [showPopup]);
    
    const dispatch = useDispatch();
    const node = useRef();
    const captchaCode = useSelector(state => state.chamagotchi.captchaCode);
    const captchaBase64 = useSelector(state => state.chamagotchi.captchaBase64);
    const chamagotchiFeededStatus = useSelector(state => state.chamagotchi.chamagotchiFeededStatus);
    const error = useSelector(state => state.chamagotchi.error);
    const feedLoading = useSelector(state => state.chamagotchi.feedLoading);
    const captchaLoading = useSelector(state => state.chamagotchi.captchaLoading);

    var defaultRemainingTime = {
        seconds: '00',
        minutes: '00',
        hours: '00',
        days: '00',
    };
    const [remainingTime, setRemainingTime] = useState(defaultRemainingTime);
    var countdownTimestampMs = nextFeedEndTime ? new Date(nextFeedEndTime).getTime() : new Date().getTime();

    useEffect(() => {
        const intervalId = setInterval(() => {
            updateRemainingTime(countdownTimestampMs);
        }, 1000);
        return () => clearInterval(intervalId);
    },[countdownTimestampMs]);

    function updateRemainingTime(countdown) {
        setRemainingTime(getRemainingTimeUntilMsTimestamp(countdown));
    }

    useEffect(() => {
        if(captchaResponse && captchaResponse.length === 6 )
            setIsVarified(true);
        else
            setIsVarified(false);
    }, [captchaResponse])
    

    // to close popup after feeding
    useEffect(() => {
        if(chamagotchiFeededStatus === 200) {
            setShowPopup(false);
            setIsVarified(false);
            setIsLive(null)
            setDeadTime(null)
            dispatch(resetChamagotchi())
            setCaptchaResponse(null);
            document.getElementById("captchaInput").value = '';
        }else if (chamagotchiFeededStatus === 302) {
            setTimeout(() => {
                setShowPopup(true);
                setIsVarified(false);
                dispatch(getCaptcha());
                setCaptchaResponse(null);
                document.getElementById("captchaInput").value = '';
                setDeadTime(null)
                // dispatch(resetChamagotchi())
            }, 5000);
        }else if (chamagotchiFeededStatus === 401 || chamagotchiFeededStatus == null) {
            setAccountNo(null);
            setUserId(null);
            setActivePage('');
            dispatch(userLogout());
            toast.error( "Connection lost please try again" , {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            },);
        }
        return () => {
            setIsVarified(false);
            setCaptchaResponse(null);
            document.getElementById("captchaInput").value = '';
            dispatch(resetChamagotchi())
        }
    }, [chamagotchiFeededStatus]);

    // console.log(("isVarified :",isVarified ,"isLive :", isLive ,"deadTime :", deadTime)
    // console.log(("captchaLoading :",captchaLoading)

    return (
        <div className={`${showPopup ? "popup_wrapper d-flex justify-content-center align-item-center" : "d-none"}`}>
            <div ref={node} className="popup_container d-flex flex-column ">
                <button className="close_popup" onClick={() => {setCaptchaResponse(null);  document.getElementById("captchaInput").value = '' ;setShowPopup(false); resetChamagotchi()}}>
                    <FaTimes size={20}/>
                </button>
                <div className="d-flex flex-column justify-content-center align-items-center m-auto">
                    <div className="d-flex flex-column flex-md-row  justify-content-between align-items-center">
                        <img 
                            src={nftImgUrl ? nftImgUrl : NoImage}
                            alt={`nft-img-${tokanNo}`} 
                            className={`${(popupAlt) ? "nft_img_alt" : "nft_img"}`} 
                        />
                        {(!popupAlt) ?
                            <div className="feed_sec d-flex flex-column">
                                <div className="popup_clock">
                                    <div className="popup_time d-flex justify-content-center align-items-center h-100">
                                        <span className="popup_hours">{remainingTime.hours}</span>
                                        <span>:</span>
                                        <span className="popup_minutes">{remainingTime.minutes}</span>
                                        <span>:</span>
                                        <span className="popup_seconds">{remainingTime.seconds}</span>
                                    </div>
                                </div> 
                                {captchaLoading ?
                                    <div className="loaderDivBg"><PulseLoader loading={captchaLoading} color="#7B0083"/></div>
                                    :
                                    <div className="captcha-code-img ">
                                        <img src={`data:image/png;base64,${captchaBase64}`} alt="" className="w-100" />
                                    </div>
                                }
                                <div className="captcha-code-input d-flex flex-column align-items-start my-3">
                                    <span className="d-flex my-2">
                                        Please verify you are not robot
                                        <div className="refresh-btn mx-3" onClick={() => {
                                            dispatch(getCaptcha());
                                            setCaptchaResponse(null);
                                            document.getElementById("captchaInput").value = '';
                                        }}>
                                            <BsArrowRepeat size={20} />
                                        </div>
                                    </span>
                                    <div className="input-group">
                                        <input
                                            id="captchaInput"
                                            type="text"
                                            maxLength="6"
                                            className="form-control" 
                                            placeholder="Enter above captcha code" 
                                            value={captchaResponse}
                                            onChange={(e) => setCaptchaResponse(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            :
                            null
                        }
                    </div>
                    {
                      (!popupAlt) ? 
                        (isVarified && isLive && deadTime == null) ?
                            <button 
                                className={`${(isVarified && isLive && deadTime == null) ? "nft_popup_btn" : "nft_popup_btn_disabled"}`}
                                onClick={() => { 
                                    // setShowNftPopup(false);
                                    // resetCaptcha();
                                    dispatch(createChamagotchiFeed({chamagotchiId: tokanNo , userId: userId, captchaResponse: captchaResponse, captchaCode: captchaCode }));
                                }} 
                                disabled={feedLoading}
                            >
                                Feed Now
                            </button>
                            : (isLive && deadTime == null) ?
                            <button 
                                className={`${(isVarified && isLive && deadTime == null) ? "nft_popup_btn" : "nft_popup_btn_disabled"}`}
                                onClick={() => {
                                    toast.error( "Please Verify You Are Human" , {
                                        position: "bottom-right",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: false,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                    },)
                                }} 
                                disabled={feedLoading}
                            >
                                Feed
                            </button>
                            :
                            <button className="nft_popup_btn_disabled">
                                Feed
                            </button>
                        :
                        null
                    }
                </div>
            </div>
        </div>
    )
}

export default NftPopup
