import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PulseLoader, } from "react-spinners";
import { getUserDetailByAddress, userLogout } from '../../redux/actions/users';
import EditIcon from '../../assets/Img/edit-icon.png';
import Footer from '../Footer/Footer';
import './AppLayoutPrimary.css';

function AppLayoutPrimary({ content, BgImgUrl, canEditProfile, uploadBnrImg, setUploadBnrImg, previewBnr, accountNo, setAccountNo, setUserId }) {
 
    const dispatch = useDispatch();
    const user = useSelector(state => state.users.user);
    const loading = useSelector(state => state.users.loading);
    const uploadingBg = useSelector(state => state.users.uploadingBg);
    const error = useSelector(state => state.users.error);
    const loginStatus = useSelector(state => state.users.loginStatus);
    const walletAddress = useSelector(state => state.miscellaneous.walletAddress);
    
    useEffect(async (e) => {
        if(localStorage.getItem("chameleon_access_token")) {
            setAccountNo(walletAddress)
            dispatch(getUserDetailByAddress(walletAddress));
        }
    }, []);
        
    useEffect(() => {
        setUserId(user.userId)
    }, [user]);

    useEffect(() => {
        if(loginStatus === 401) {
            setAccountNo(null);
            setUserId(null);
            dispatch(userLogout());
        }
    }, [loginStatus]);
    
    return (
        <Fragment>
            <div className='app_container bg-white'>
                <div className="content_wrapper d-flex flex-column justify-content-start align-items-center">
                    <div className="top_img_sec position-relative">
                    {(uploadingBg && <div className="loaderDivBg position-absolute"><PulseLoader loading={uploadingBg} color="#7B0083"/></div>)}
                    {uploadBnrImg ? <img src={previewBnr} /> : (user.bannerImage) ? <img src={user.bannerImage} alt="cover-img" /> : <img src={BgImgUrl} alt="cover-img" /> }
                        {(canEditProfile) ?
                            <span className="edit_bg_pointer position-absolute">
                                <input type="file" id='profilePic' className="profile_upload" onChange={(e) => {setUploadBnrImg(e.target.files[0])}}/>
                                <label htmlFor="profilePic">
                                    <img src={EditIcon} alt="" className="" />
                                </label>
                            </span>
                            :
                            null
                        }
                    </div>
                    {content}
                </div>
            </div>
            <Footer />
        </Fragment>
    )
}

export default AppLayoutPrimary
