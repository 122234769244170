import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Footer from '../../components/Footer/Footer'
import { FaCheck } from 'react-icons/fa'
import { ImCheckboxUnchecked } from 'react-icons/im'
import { findAllChamTraitTypes, findChamTraitsByTraitType, savePreviewNft } from '../../redux/actions/dressingCham'
import './ChameleonCreation.css'

function ChameleonCreation({ accountNo, setAccountNo }) {
  const [activeTraitTypesBtn, setActiveTraitTypesBtn] = useState("Background");
  const [createCham, setCreateCham] = useState([
    { traitType: "Background", image: '', traitName: '', traitId: '', },
    { traitType: 'Body', image: '', traitName: '', traitId: '', },
    { traitType: 'Mouth', image: '', traitName: '', traitId: '', },
    { traitType: 'Eye', image: '', traitName: '', traitId: '', },
    { traitType: 'Tail', image: '', traitName: '', traitId: '', },
    { traitType: "Clothes", image: '', traitName: '', traitId: '', },
    { traitType: 'Hat', image: '', traitName: '', traitId: '', },
    { traitType: 'Prop', image: '', traitName: '', traitId: '', },
    { traitType: 'Robotic', image: '', traitName: '', traitId: '', },
    { traitType: 'Pygmy', image: '', traitName: '', traitId: '', },
    { traitType: 'Mutant', image: '', traitName: '', traitId: '', },
    { traitType: 'Border', image: '', traitName: '', traitId: '', },
  ]);
  const [selectedTraitIds, setSelectedTraitIds] = useState([]);

  const dispatch = useDispatch();
  const history = useHistory();
  const allChamTraitTypes = useSelector(state => state.dressingCham.allChamTraitTypes);
  const ChamTraitsByTypes = useSelector(state => state.dressingCham.ChamTraitsByTypes);
  const walletAddress = useSelector(state => state.miscellaneous.walletAddress);

  const handleTraitSelection = (img, traitName, traitId) => {
    let tempCreateCham = createCham?.map((trait) => 
      trait.traitType === activeTraitTypesBtn ? {...trait, image: img, traitName: traitName, traitId: traitId} : trait
    )
    setCreateCham(tempCreateCham);
    
  }

  useEffect(() => {
    let tempSelectedTraitIds = createCham?.map((trait) => 
      trait.traitId && trait.traitId 
    )
    setSelectedTraitIds(tempSelectedTraitIds);
  }, [createCham]);

  useEffect(() => {
    dispatch(findAllChamTraitTypes())
  }, []);

  useEffect(() => {
    dispatch(findChamTraitsByTraitType({traitType: activeTraitTypesBtn}))
  }, [activeTraitTypesBtn]);

  
  useEffect(async (e) => {
    setAccountNo(walletAddress)
  }, []);

  // console.log('createCham :', createCham)
  // console.log('SelectedTraitIds :', selectedTraitIds, selectedTraitIds.length)
  // console.log('createCham logic:', (selectedTraitIds?.filter((trait) => {return(trait !== '')}).length < allChamTraitTypes.length))

  return (
    <div className="bg_white d-flex flex-column justify-content-center align-items-center">
      <div className="create-cham-container p-4">
        <h5 className="create-cham-heading">Create Your Chameleon</h5>
        <span className="create-cham-subheading">
          Fully customise your chameleon! Work your way through the left menu, make sure a check mark appears next to each 
          element. Once all elements have been designed. click Create!
        </span>
        <div className="row my-4 align-items-start">
          <div className="col-2 d-flex flex-column justify-content-center align-items-center">
              {allChamTraitTypes.length ?
                allChamTraitTypes?.map((traitType, i) => {
                  return(
                    <div className="btn-group btn-group-justified d-flex align-items-center w-100">
                      {(createCham?.filter((trait) => {return(trait.traitType === traitType.traitType && trait.image !== "")}).length === 1) ?
                        <button className="btn btn-outline-success" disabled>
                          <FaCheck /> 
                        </button>
                        :
                        <button className="btn btn-outline-warning" disabled>
                          <ImCheckboxUnchecked />
                        </button>
                      }
                      <button 
                        key={i}
                        type="button" 
                        className={activeTraitTypesBtn === traitType.traitType ? "btn btn-secondary my-2 w-100" : "btn btn-outline-secondary my-2 w-100"}
                        onClick={() => setActiveTraitTypesBtn(traitType.traitType)}
                      >
                        {traitType.traitType}
                      </button>
                    </div>
                  )
                })
                :
                null
              }
          </div>
          <div className="col-6 d-flex w-100">
            <div className="row create-cham-preview create-cham-traits my-2 py-2">
                {ChamTraitsByTypes.length ?
                  ChamTraitsByTypes?.map((trait, i) => {
                    return(
                      <div
                        key={i}
                        className="col-2 mb-2 px-2"
                        onClick={() => handleTraitSelection(trait.imageUrl, trait.traitName, trait.id)}
                      >
                        <div className="create-cham-trait-thumbnail cursor-pointer">
                          <img src={trait.imageUrl} alt="" className="w-100" />
                        </div>
                        <small className="create-cham-trait-thumbnail-namep-0 m-0">{trait.traitName}</small>
                      </div>
                    )
                  })
                  :
                  null
                }
            </div>
          </div>
          <div className="col-4 d-flex flex-column w-100">
            <div className="d-flex create-cham-preview w-100 m-2 position-relative">
              <div className="create-cham-preview-bg position-relative m-2 w-100">
                <img src="https://chameleoncollective.io/small_traits/Background/Blue Sky.png" alt="" className="w-100" />
              </div>
              {createCham.length ?
                createCham?.map((traitImg, i) => {
                  return(
                    <div className={`create-cham-preview-dyn-img create-cham-preview-${traitImg.traitType} position-absolute p-2 w-100`}>
                      {traitImg.image && <img src={traitImg.image} alt="" className="w-100" />}
                    </div>
                  )
                })
                :
                null
              }
            </div>
            <button 
              className="btn btn-primary m-2 w-100"
              disabled={selectedTraitIds?.filter((trait) => {return(trait !== '')}).length < allChamTraitTypes.length}
              onClick={() => {
                dispatch(savePreviewNft({accountCode: accountNo, chamTraitTypeIds: selectedTraitIds}))
              }}
            >
              Create
            </button>
            <div className="d-flex flex-column">
              {createCham.length &&
                createCham?.map((trait, i) => {
                  return (
                    <div className="d-flex justify-content-between">
                      <small className="">{trait.traitType}:</small>
                      <small className=""><b>{trait.traitName ? trait.traitName : 'NA'}</b></small>
                    </div>
                  )
                })
              }              
            </div>
          </div>
        </div>
      </div> 
      <Footer fullWidth/>
    </div>
  )
}

export default ChameleonCreation
