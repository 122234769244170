import React from 'react';
import './Card.css';

function Card({ ImgName, CardColor, CardTitle}) {
    return (
        <div 
            className={`
                ${"card position-relative p-0 m-0"} 
                ${(CardColor === "Cosmic") ? "Cosmic" : (CardColor === "Woodland") ? "Woodland" : (CardColor === "Underworld") ? "Underworld" : "Oceanic" }
            `}
        >
            <img src={ImgName} alt={CardTitle} />
            <span className="card_title position-absolute">
                {CardTitle}
            </span>
        </div>
    )
}

export default Card