import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTokenByUser } from '../../redux/actions/users';
import { getAllPotions } from '../../redux/actions/potion';
import AppLayoutPrimary from '../../components/AppLayoutPrimary/AppLayoutPrimary';
import PotionsNftCardWrapper from '../../components/PotionsNftCardWrapper/PotionsNftCardWrapper';
import './PotionsChameleon.css';
import MintedForPotionCardWrapper from '../../components/MintedForPotionCardWrapper/MintedForPotionCardWrapper';

function PotionsChameleon({ setAccountNo, setUserId, setActivePage, }) {
  const [profilePic] = useState('img/profile-pic.png');
  const [BgImgUrl] = useState('img/bg-1.jpg');
  const [isSelectingPotionType, setIsSelectingPotionType] = useState(false);
  const [chamsForPotion, setChamsForPotion] = useState([]);
  const [isPotionMintEnable, setIsPotionMintEnable] = useState(false);
  

  const dispatch = useDispatch();
  const user = useSelector(state => state.users.user);
  const potions = useSelector(state => state.potion.potions);
  const loading = useSelector(state => state.potion.loading);
  const error = useSelector(state => state.potion.error);

  useEffect(() => {
    dispatch(getAllPotions(user.userId))
    dispatch(getTokenByUser(user.userId))
  }, [user, user.userId]);
  
  useEffect(() => {
    setActivePage('potions');
    return () => {
      setActivePage('');
    }
  }, []);

  return (
    <AppLayoutPrimary 
      setUserId={setUserId}
      setAccountNo={setAccountNo}
      BgImgUrl={BgImgUrl}
      content={
        <Fragment>
          {(user) ?
            <div className='Profile d-flex flex-column justify-content-center position-relative text-secondary w-100'>
              <div className="profile_img position-absolute">
                {(user.profileImage) ? <img src={user.profileImage} alt="profile-img" /> : <img src={profilePic} alt="default-profile-img" />}
              </div>
              <h3 className="userName">{user.userName}</h3>
              {(user.discordName) ? <h4 className="sub_text my-1">{user.discordName}</h4> : null}
              <h3 className="email my-1">{user.email}</h3>
              {isPotionMintEnable ?
                <PotionsNftCardWrapper 
                  setIsSelectingPotionType={setIsSelectingPotionType}
                  setChamsForPotion={setChamsForPotion}
                />
                :
                <MintedForPotionCardWrapper setActivePage={setActivePage}/>
              }
            </div>
            :
            null
          }
        </Fragment>
      }
    />
  )
}

export default PotionsChameleon