import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTokenWithoutPotion, onAfterMintPotions, resetPotion } from '../../redux/actions/potion';
import { GridLoader } from 'react-spinners';
import { FaInfoCircle } from "react-icons/fa";
import { toast } from 'react-toastify';
import { utils, BigNumber, ethers } from 'ethers';
import {nftContract, signer,iface,provider,ethereum} from '../../chamPotionVenderWeb3';
import { BeatLoader } from "react-spinners";
import './PotionsNftCardWrapper.css';

function PotionsNftCardWrapper({ setIsSelectingPotionType, setChamsForPotion }) {
    const [isShowMore, setIsShowMore] = useState(false);
    const [isPotionMintModelOpen, setIsPotionMintModelOpen] = useState(false);
    const [selectedNftForPotionArr, setSelectedNftForPotionArr] = useState([]);
    const [selectedNftForPotionArrLength, setSelectedNftForPotionArrLength] = useState([]);
    const [mintingSuccess, setMintingSuccess] = useState(true);
    const [potionsChamNft, setPotionsChamNft] = useState([]);
    const [mintSuccessMsg, setMintSuccessMsg] = useState('');
    const [mintLoading, setMintLoading] = useState(false);
    const [selectedMintIds, setSelectedMintIds] = useState([]);

    const dispatch = useDispatch();
    const tokensWithoutPotion = useSelector(state => state.potion.tokensWithoutPotion);
    const loading = useSelector(state => state.potion.loading);
    const onAfterMintPotionStatus = useSelector(state => state.potion.onAfterMintPotionStatus);
    const error = useSelector(state => state.potion.error);

    useEffect(() => {
        dispatch(getTokenWithoutPotion());
        setPotionsChamNft(tokensWithoutPotion);
    }, []);

    useEffect(() => {
        setPotionsChamNft(tokensWithoutPotion);
    }, [tokensWithoutPotion]);

    const selectForPotionsCham = (e) => {
        const {name, checked} = e.target;
        let tempPotionsChamNft = potionsChamNft.map((nft) => 
            nft.tokenId == name ? {...nft, isChecked: checked} : nft 
        );
        setPotionsChamNft(tempPotionsChamNft);
    }
    
    useEffect(() => {
        let tempSelectedMintIds = selectedNftForPotionArr.map((nft) => 
            Number(nft.token) 
        );
        setSelectedMintIds(tempSelectedMintIds);
    }, [selectedNftForPotionArr])
    
    
    useEffect(() => {
        let tempPotions = potionsChamNft?.filter((nft) => {return nft.isChecked === true})
        setSelectedNftForPotionArrLength(tempPotions.length);
        setSelectedNftForPotionArr(tempPotions)
    }, [potionsChamNft]);
    
    useEffect(() => {
        if(onAfterMintPotionStatus == 200) {
            setIsSelectingPotionType(true);
        }
        return () => {
            dispatch(resetPotion());
        }
    }, [onAfterMintPotionStatus]);

    // mint handler 
    const mintNftHandler = async (mintType) => {
        try {
            setMintLoading(true);
            // at a later point in Time
            await ethereum.enable();
            let finney = 1000000000000000;
            // calculate the no of token is to be minted
            let mintQty = selectedMintIds.length
            // get total number of tokens  
            let totalTokens = await nftContract.totalTokens();
            // get total number of tokens  
            let numberMinted = await nftContract.numberMinted();
            // get if minting is active or not  
            let isMintActive = await nftContract.mintActive();
            // console.log(("numberMinted :",numberMinted);
            // console.log(("totalTokens :", totalTokens);
            // console.log(("mintQty :", mintQty);
            // check if whitelist is active or not
            // console.log(("isMintActive  : ", isMintActive);
            if (isMintActive){
                // check if minting Qty available or not
                if((mintQty + Number(numberMinted)) <=  Number(totalTokens) ) {
                    // let nftPrice = (0.1 * 100 * mintQty) / 100;
                    // console.log("nftPrice :",nftPrice)
                    // let t1 = (1000 * nftPrice );
                    // let nftPrice1 = (1000000000000000 * t1).toString()
                    // const balance = BigNumber.from(nftPrice1.toString());
                    // console.log('Balance at line is ',balance)
                    let tempBalance = await provider.getBalance(signer.getAddress());
                    // console.log(("tempBalance :",ethers.utils.formatEther(tempBalance))
                    // check if price value is correct or not
                    if(ethers.utils.formatEther(tempBalance)) {
                        // console.log(("Interface  data is ",iface);
                        // const dataen= iface.encodeFunctionData("mint", [[selectedMintIds]]); // encoding data to create raw transaction
                        // console.log("Decoded data is ", dataen);
                        const sender = await  signer.getAddress()
                        // let gasPriceHex = ethers.utils.hexlify(200000000000);
                        //Set max gas limit to 4M
                        // var gasLimitHex = ethers.utils.hexlify(40000000);
                        // Raw Transaction
                        // calling minging function
                        let nftTxn = await nftContract.mint(selectedMintIds,{
                            from: sender,
                            // gasLimit: gasLimitHex,
                        });
                        // console.log(("txnObject : ", nftTxn)
                        if(nftTxn) {
                            setMintLoading(false);
                            setMintSuccessMsg("Minting initiated")
                            let nftWithPotionTxnHash = selectedNftForPotionArr.map((nft) => 
                                nft.tokenId ? { ...nft, potionHash: nftTxn.hash } : nft
                            );
                            dispatch(onAfterMintPotions(nftWithPotionTxnHash));
                            setTimeout(() => {
                                setMintSuccessMsg("")
                            }, 5000);
                        }
                    }else{
                        alert("Oops!! Insufficient balance")
                        setMintLoading(false);
                        setPotionsChamNft(tokensWithoutPotion);
                    }
                }else{
                    alert("Oops!! Minting limit exceeded")
                    setMintLoading(false);
                    setPotionsChamNft(tokensWithoutPotion);
                }
            }else{
                alert("Oops!! Minting is not Active");
                setMintLoading(false);
                setPotionsChamNft(tokensWithoutPotion);
            }
        }catch (err) {
            setMintLoading(false);
            setPotionsChamNft(tokensWithoutPotion);
            // console.log((err);
            alert(err.message);
        }
    }

    return (
        <Fragment>
            <div className='potions_nft_card_wrapper mx-auto m-md-4 mb-4'>
                <div className="potions_nft_card_wrapper_heading d-flex justify-content-between">
                    <h1 className="nft_card_heading text-uppercase p-4 m-0 pointer-cursor">potions</h1>
                    <span className="down_icon m-4 down_icon_primary" onClick={() => setIsShowMore(!isShowMore)}>
                        {(!isShowMore) ?
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-40 w-40" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                            </svg>
                            :
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-40 w-40" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd" d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z" clip-rule="evenodd" />
                            </svg>
                        }
                    </span>
                </div>
                {potionsChamNft.length && !loading &&
                    <div className="d-flex justify-content-center align-items-center my-2">
                        <FaInfoCircle size={20} className='text-info'/>
                        &nbsp;
                        Select NFTs to mint Potions 
                    </div>
                }
                <div className="d-flex flex-wrap p-2">
                    {
                        loading &&
                        <div className="d-flex justify-content-center align-item-end w-100 p-5">
                            <GridLoader loading={loading} size={30} color='#01BFD9'/>
                        </div>
                    }
                    {(potionsChamNft.length && !loading) ?
                        potionsChamNft?.map(( nftItem, index ) => {
                                return(
                                    <div 
                                        className={`${(!isShowMore) ? ( index >= 6 )? "d-none" : "potions_nft_card d-flex flex-column p-2" : "potions_nft_card d-flex flex-column p-2"}`}
                                    >
                                        <img src={nftItem.image ? nftItem.image : 'img/blank-chamagotchi.jpg'} alt="" className="" />
                                        <div className="d-flex justify-content-between align-item-center p-2">
                                            <span className="tokan_no">{nftItem.token}</span>
                                            <div className="form-check ">
                                                <input type="checkbox" 
                                                    className=" form-check-input custom-checkbox cursor-pointer"
                                                    id="materialUnchecked"
                                                    name={nftItem.tokenId} 
                                                    checked={nftItem.isChecked || false}
                                                    onChange={selectForPotionsCham}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        )
                        : (!loading) ?
                        <div className="d-flex justify-content-center align-items-end w-100 p-4">
                            <img src="img/no-data.png" alt="" className="" />
                            <h4 className="text-danger my-2">You don't have any NFT associated With this account !!!</h4>
                        </div>
                        :
                        null
                    }
                    {(!isShowMore && (potionsChamNft.length > 7) && !loading) ?
                        <div className="nft-show-more-card d-flex d-md-none justify-content-center align-item-center">
                            <button className="btn btn-outline-info" onClick={() => setIsShowMore(true)}>
                                {"More >>"}
                            </button>
                        </div>
                        :( (potionsChamNft.length > 7) && !loading) ?
                            <div className="nft-show-more-card d-flex d-md-none justify-content-center align-item-center">
                                <button className="btn btn-outline-info" onClick={() => setIsShowMore(false)}>
                                    {"collapse <<"}
                                </button>
                            </div>
                        :
                        null
                    }
                </div>
                <button 
                    type="button" 
                    className="btn btn-primary d-flex align-items-center ml-auto mr-4 mb-2"
                    onClick={() => {
                        setIsPotionMintModelOpen(true);
                    }}
                    disabled={selectedNftForPotionArrLength < 1}
                >
                    {mintLoading ? <BeatLoader size={10} color='#fff'/> : "Mint potions"}
                </button>
                <div className={`${isPotionMintModelOpen ? "potion-mint-modal-wrapper d-flex justify-content-center align-items-center position-fixed" : "d-none"}`}>
                    <div className="potion-mint-modal">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLongTitle">Confirmation</h5>
                                <button type="button" className="close" onClick={() => {setIsPotionMintModelOpen(false);}}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <span className="p-4">
                                    {"You are ready to mint "} 
                                    {selectedNftForPotionArrLength} 
                                    {selectedNftForPotionArrLength > 1 ? " Potions" : " Potion" } 
                                    {" for your "} 
                                    {selectedNftForPotionArrLength > 1 ? "NFTs" : "NFT" } 
                                </span>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={() => {setIsPotionMintModelOpen(false);}}>Cancel</button>
                                {selectedNftForPotionArrLength > 0 ?
                                    <button 
                                        type="button" 
                                        className="btn btn-primary"
                                        onClick={() => {
                                                mintNftHandler();
                                                setIsPotionMintModelOpen(false);
                                            }
                                        }
                                    >
                                        Confirm
                                    </button>
                                    :
                                    null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default PotionsNftCardWrapper
