import React from 'react';
import Z1 from '../../assets/Img/z1.png';
import Ash1 from '../../assets/Img/ash1.png';
import Ksum1 from '../../assets/Img/ksum1.png';
import './Founder.css';


function Founder() {
    return (
        <div className="app_container founder_sec">
            <div className="row">
                <div className="col-12 col-md-4">
                    <div className="founder">
                        <div className="founder_img">
                            <img src={Z1} />
                        </div>
                        <h2 className="my-3">ZACH BUSH</h2>
                        <h3>Founder</h3>
                        <p>
                            Ilustrator and 3D artist extraordinaire. 15+ years of professional illustration experience and a Masters degree in 3D Environmental design from Virginia Tech. His passion is for a 3D world-building, and will be focused on pushing all the artistic, metaverse and and VR boundaries
                        </p>
                    </div>
                </div>
                <div className="col-12 col-md-4">
                    <div className="founder">
                        <div className="founder_img">
                            <img src={Ash1} />
                        </div>
                        <h2 className="my-3">Ash BUSH</h2>
                        <h3>Founder</h3>
                        <p>
                            Brand and product builder. Bachelors degree in graphic design. 8+ years building her own product-based business utilising 3D printing with a 30,000 user following. Her focus is on building the brand, leading up the giving back branch and helping with the education initiative
                        </p>
                    </div>
                </div>
                <div className="col-12 col-md-4">
                    <div className="founder">
                        <div className="founder_img">
                            <img src={Ksum1} />
                        </div>
                        <h2 className="my-3">BEARDEDKSUM</h2>
                        <h3>CCO</h3>
                        <p>
                            10 years USAF vet. Strategic management + business operations Guru. As this project grows from a 2D generative NFT project into 3D avatars and 3D world, he will help team stay lean and focused on core competencies and long-term growth
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Founder
