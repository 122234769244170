import axios from "axios";
import { base_url, end_points } from "../../constant";

function getAxios(method, url, data, isAccessToken) {
    if(isAccessToken) {
        return axios({
            method: method,
            url: url,
            data: data,
            headers: { "Content-Type": "application/json", "Authorization": "Bearer "+localStorage.getItem("chameleon_access_token") },
        })
    }else {
        return axios({
            method: method,
            url: url,
            data: data,
            headers: { "Content-Type": "application/json" },
        })
    }
}

export function findAllChamTraitTypesApi() {
    return getAxios( 'get', base_url + end_points.find_All_Cham_Trait_Types, null, true)
}

export function findChamTraitsByTraitTypeApi( payload ) {
    return getAxios( 'get', base_url + end_points.find_Cham_Traits_By_Trait_Type + '?traitType=' + payload.traitType, null, true)
}

export function savePreviewNftApi( payload ) {
    return getAxios( 'post', base_url + end_points.save_preview_nft, payload, true)
}

export function getAllPreviewNftListApi() {
    return getAxios( 'get', base_url + end_points.get_all_preview_nft_list, null, true)
}

export function getAllOverlayTypeApi() {
    return getAxios( 'get', base_url + end_points.get_all_overlay_type, null, true)
}

export function getOverlayByTypeApi( payload ) {
    return getAxios( 'get', base_url + end_points.get_overlay_by_type + '?type=' + payload.type, null, true)
}

export function getAllOverlayApi() {
    return getAxios( 'get', base_url + end_points.get_all_overlay, null, true)
}

export function addToWishlistApi( payload ) {
    return getAxios( 'post', base_url + end_points.add_to_wishlist, payload, true)
}

export function getAllWishListApi() {
    return getAxios( 'get', base_url + end_points.get_all_wishList, null, true)
}

export function deleteWishlistApi( payload ) {
    return getAxios( 'get', base_url + end_points.delete_wishList + '?wishlistId=' + payload.wishlistId, null, true)
}

export function wishlistSelectedForPrintApi( payload ) {
    return getAxios( 'post', base_url + end_points.wishlist_selected_for_print, payload, true)
}

export function afterPaymentApi( payload ) {
    return getAxios( 'get', base_url + end_points.after_payment + '?orderId=' + payload.orderId + '&txnHash=' + payload.txnHash, null, true)
}

export function deleteOrderApi( payload ) {
    return getAxios( 'get', base_url + end_points.delete_order + '?orderId=' + payload.orderId, null, true)
}

export function findAllTraitApi() {
    return getAxios( 'get', base_url + end_points.find_all_trait, null, true)
}

export function findAllForDressingRoomApi() {
    return getAxios( 'get', base_url + end_points.find_all_for_dressing_room, null, true)
}

export function findAllInventoryTraitTypesApi() {
    return getAxios( 'get', base_url + end_points.find_all_inventory_trait_types, null, true)
}

export function findAllAvailableInventoryApi() {
    return getAxios( 'get', base_url + end_points.find_all_available_inventory, null, true)
}

export function applyInventoryApi( payload ) {
    return getAxios( 'post', base_url + end_points.apply_inventory, payload, true)
}