
export const base_url = 'https://chameleoncollective.io'; // PROD url
export const base_url_user = base_url+'/api/user';
export const base_url_token = base_url+'/api/token';
export const base_url_chamagotchi = base_url+'/api/chamagotchi';
export const base_url_traitType = base_url+'/api/traitType';

export const end_points = {
    // User
    login_user_by_address : '/api/auth/loginUserByAddress',
    login_user_by_username : '/api/auth/login',
    get_user_detail_by_address : '/getUserDetailByAddress',
    get_token_by_user: '/getTokenByUser',
    update_profile: '/updateProfile',
    upload_profile_image: '/uploadProfileImage',
    upload_banner_image: '/uploadBannerImage',
    // Chamagotchi
    Chamagotchi_find_by_trait_type: '/findByTraitType',
    create_chamagotchi: '/createChamagotchi',
    find_chamagotchi_by_user: '/findByUser',
    create_chamagotchi_feed: '/api/chamagotchiFeed/createChamagotchiFeed',
    on_after_mint: '/api/chamagotchi/onAfterMint',
    on_after_airdrop: '/api/chamagotchi/onAfterAirdrop',
    get_chamagotchi_details: '/api/chamagotchi/getChamagtochiDetailForUser',
    get_leader_board_data: '/api/public/getLeaderBoardData',
    get_captcha: '/api/chamagotchi/getCaptcha',
    // Potion Chameleon
    get_Token_Without_Potion: '/api/token/getTokenWithoutIsPotion',
    get_All_Potions: '/api/chameleonPotion/findAllPotions',
    on_After_Mint_Potions: '/api/token/onAfterMint',
    map_Chameleon_With_Potion: '/api/chameleonPotion/mapChameleonWithPotion',
    get_List_Of_Potion_Mint_Tokens: '/api/token/getTokenListOfMint',
    get_List_Of_Chameleon_Potions: '/api/chameleonPotion/listOfChameleonPotions',
    on_After_Burn: '/api/token/burnPotion',
    get_Token_Detail: '/api/token/getTokenDetail',
    get_My_Potion: '/api/token/getMyPotionWithPropCheck',
    potion_Apply: '/api/token/potionApplyV2',
    get_Token_Excluded: '/api/token/getTokenExcluded',
    is_Token_Altered: '/api/public/isTokenAltered',
    get_Token_Burned: '/api/token/getTokenBurned',
    // dressing cham || create cham
    find_All_Cham_Trait_Types: '/api/traitType/findAllChamTraitTypes',
    find_Cham_Traits_By_Trait_Type: '/api/traitType/findChamTraitsByTraitType',
    save_preview_nft: '/api/previewNft/savePreviewNft',
    get_all_preview_nft_list: '/api/previewNft/findAllPreviewNftList',
    get_all_overlay_type: '/api/traitType/findAllOverlayType',
    get_overlay_by_type: '/api/traitType/findOverlayByType',
    get_all_overlay: '/api/traitType/findAllOverlay',
    add_to_wishlist: '/api/wishlist/addToWishlist',
    get_all_wishList: '/api/wishlist/findAllWishList',
    delete_wishList: '/api/wishlist/deleteWishlist',
    wishlist_selected_for_print: '/api/order/wishlistSelectedForPrint',
    after_payment: '/api/order/afterPayment',
    delete_order: '/api/order/deleteOrder',
    find_all_trait: '/api/allInventory/findAllTrait',
    find_all_for_dressing_room: '/api/allInventory/findAllForDressingRoom',
    find_all_inventory_trait_types: '/api/allInventory/findAllAvailableTrait',
    find_all_available_inventory: '/api/allInventory/findAllAvailableInventory',
    apply_inventory: '/api/allInventory/applyInventory',

}
