import { call, takeEvery } from 'redux-saga/effects';
import { 
    addToWishlistApi,
    afterPaymentApi,
    applyInventoryApi,
    deleteOrderApi,
    deleteWishlistApi,
    findAllAvailableInventoryApi,
    findAllChamTraitTypesApi, 
    findAllForDressingRoomApi, 
    findAllInventoryTraitTypesApi, 
    findAllTraitApi, 
    findChamTraitsByTraitTypeApi,
    getAllOverlayApi,
    getAllOverlayTypeApi,
    getAllPreviewNftListApi,
    getAllWishListApi,
    getOverlayByTypeApi,
    savePreviewNftApi,
    wishlistSelectedForPrintApi,
} from '../services/dressingCham';
import { 
    addToWishlistFailed,
    addToWishlistSuccess,
    afterPaymentFailed,
    afterPaymentSuccess,
    applyInventoryFailed,
    applyInventorySuccess,
    deleteOrderFailed,
    deleteOrderSuccess,
    deleteWishlistFailed,
    deleteWishlistSuccess,
    findAllAvailableInventoryFailed,
    findAllAvailableInventorySuccess,
    findAllChamTraitTypesFailed, 
    findAllChamTraitTypesSuccess,
    findAllForDressingRoomFailed,
    findAllForDressingRoomSuccess,
    findAllInventoryTraitTypesFailed,
    findAllInventoryTraitTypesSuccess,
    findAllTraitFailed,
    findAllTraitSuccess,
    findChamTraitsByTraitTypeFailed,
    findChamTraitsByTraitTypeSuccess,
    getAllOverlayFailed,
    getAllOverlaySuccess,
    getAllOverlayTypeFailed,
    getAllOverlayTypeSuccess,
    getAllPreviewNftListFailed,
    getAllPreviewNftListSuccess,
    getAllWishList,
    getAllWishListFailed,
    getAllWishListSuccess,
    getOverlayByTypeFailed,
    getOverlayByTypeSuccess,
    savePreviewNftFailed,
    savePreviewNftSuccess,
    wishlistSelectedForPrintFailed,
    wishlistSelectedForPrintSuccess,
} from '../actions/dressingCham';
import store from '../store';
import { toast } from 'react-toastify';

function* findAllChamTraitTypesSaga({ payload }) {
    try{
        const responce = yield call(findAllChamTraitTypesApi , payload)
        if(responce){
            if (responce.data.status === 200) {
                store.dispatch(findAllChamTraitTypesSuccess({status: responce.data.status, traitTypes: responce.data.response}))
                // console.log("findAllChamTraitTypesSagaSuccess dressingChamSaga :", responce.data.response)
            }else {
                store.dispatch(findAllChamTraitTypesFailed(responce.data.message))
                toast.error( responce.data.message , {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                },);
                // console.log("findAllChamTraitTypesFailed",responce.data.status)
            }
        }
    } catch (e) {
        // console.log('GET_TOKEN_WITHOUT_POTION',e)
        // yield put({ type: 'GET_TOKEN_WITHOUT_POTION', message: e.message });
    }
}

function* findChamTraitsByTraitTypeSaga({ payload }) {
    try{
        const responce = yield call(findChamTraitsByTraitTypeApi , payload)
        if(responce){
            if (responce.data.status === 200) {
                store.dispatch(findChamTraitsByTraitTypeSuccess({status: responce.data.status, traits: responce.data.response}))
                // console.log("findChamTraitsByTraitTypeSagaSuccess dressingChamSaga :", responce.data.response)
            }else {
                store.dispatch(findChamTraitsByTraitTypeFailed(responce.data.message))
                toast.error( responce.data.message , {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                },);
                // console.log("findChamTraitsByTraitTypeFailed",responce.data.status)
            }
        }
    } catch (e) {
        // console.log('GET_TOKEN_WITHOUT_POTION',e)
        // yield put({ type: 'GET_TOKEN_WITHOUT_POTION', message: e.message });
    }
}

function* savePreviewNftSaga({ payload }) {
    try{
        const responce = yield call(savePreviewNftApi , payload)
        if(responce){
            if (responce.data.status === 200) {
                store.dispatch(savePreviewNftSuccess({status: responce.data.status}))
                toast.success( responce.data.message , {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                },);
                // console.log("savePreviewNftSagaSuccess dressingChamSaga :", responce.data.response)
            }else {
                store.dispatch(savePreviewNftFailed(responce.data.message))
                toast.error( responce.data.message , {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                },);
                // console.log("savePreviewNftFailed",responce.data.status)
            }
        }
    } catch (e) {
        // console.log('GET_TOKEN_WITHOUT_POTION',e)
        // yield put({ type: 'GET_TOKEN_WITHOUT_POTION', message: e.message });
    }
}

function* getAllPreviewNftListSaga({ payload }) {
    try{
        const responce = yield call(getAllPreviewNftListApi , payload)
        if(responce){
            if (responce.data.status === 200) {
                store.dispatch(getAllPreviewNftListSuccess({status: responce.data.status, allPreviewNft: responce.data.response}))
                // console.log("getAllPreviewNftListSagaSuccess dressingChamSaga :", responce.data.response)
            }else {
                store.dispatch(getAllPreviewNftListFailed(responce.data.message))
                toast.error( responce.data.message , {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                },);
                // console.log("getAllPreviewNftListFailed",responce.data.status)
            }
        }
    } catch (e) {
        // console.log('GET_TOKEN_WITHOUT_POTION',e)
        // yield put({ type: 'GET_TOKEN_WITHOUT_POTION', message: e.message });
    }
}

function* getAllOverlayTypeSaga({ payload }) {
    try{
        const responce = yield call(getAllOverlayTypeApi , payload)
        if(responce){
            if (responce.data.status === 200) {
                store.dispatch(getAllOverlayTypeSuccess({status: responce.data.status, OverlayTypes: responce.data.response}))
                // console.log("getAllOverlayTypeSagaSuccess dressingChamSaga :", responce.data.response)
            }else {
                store.dispatch(getAllOverlayTypeFailed(responce.data.message))
                toast.error( responce.data.message , {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                },);
                // console.log("getAllOverlayTypeFailed",responce.data.status)
            }
        }
    } catch (e) {
        // console.log('GET_TOKEN_WITHOUT_POTION',e)
        // yield put({ type: 'GET_TOKEN_WITHOUT_POTION', message: e.message });
    }
}

function* getOverlayByTypeSaga({ payload }) {
    try{
        const responce = yield call(getOverlayByTypeApi , payload)
        if(responce){
            if (responce.data.status === 200) {
                store.dispatch(getOverlayByTypeSuccess({status: responce.data.status, Overlays: responce.data.response}))
                // console.log("getOverlayByTypeSagaSuccess dressingChamSaga :", responce.data.response)
            }else {
                store.dispatch(getOverlayByTypeFailed(responce.data.message))
                toast.error( responce.data.message , {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                },);
                // console.log("getOverlayByTypeFailed",responce.data.status)
            }
        }
    } catch (e) {
        // console.log('GET_TOKEN_WITHOUT_POTION',e)
        // yield put({ type: 'GET_TOKEN_WITHOUT_POTION', message: e.message });
    }
}

function* getAllOverlaySaga({ payload }) {
    try{
        const responce = yield call(getAllOverlayApi , payload)
        if(responce){
            if (responce.data.status === 200) {
                store.dispatch(getAllOverlaySuccess({status: responce.data.status, allOverlays: responce.data.response}))
                // console.log("getAllOverlaySagaSuccess dressingChamSaga :", responce.data.response)
            }else {
                store.dispatch(getAllOverlayFailed(responce.data.message))
                toast.error( responce.data.message , {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                },);
                // console.log("getAllOverlayFailed",responce.data.status)
            }
        }
    } catch (e) {
        // console.log('GET_TOKEN_WITHOUT_POTION',e)
        // yield put({ type: 'GET_TOKEN_WITHOUT_POTION', message: e.message });
    }
}

function* addToWishlistSaga({ payload }) {
    try{
        const responce = yield call(addToWishlistApi , payload)
        if(responce){
            if (responce.data.status === 200) {
                store.dispatch(addToWishlistSuccess({status: responce.data.status}))
                store.dispatch(getAllWishList())
                // console.log("addToWishlistSagaSuccess dressingChamSaga :", responce.data.response)
                toast.success( responce.data.message , {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                },);
            }else {
                store.dispatch(addToWishlistFailed(responce.data.message))
                toast.error( responce.data.message , {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                },);
                // console.log("addToWishlistFailed",responce.data.status)
            }
        }
    } catch (e) {
        // console.log('GET_TOKEN_WITHOUT_POTION',e)
        // yield put({ type: 'GET_TOKEN_WITHOUT_POTION', message: e.message });
    }
}

function* getAllWishListSaga({ payload }) {
    try{
        const responce = yield call(getAllWishListApi , payload)
        if(responce){
            if (responce.data.status === 200) {
                store.dispatch(
                    getAllWishListSuccess({
                        status: responce.data.status, 
                        wishlist: responce.data.response, 
                        isPendingOrder: responce.data.isPendingOrder, 
                        orderDetails: responce.data.isPendingOrder && responce.data.pendingOrder, 
                        traitList: responce.data.isPendingOrder && responce.data.pendingOrder.traitList, 
                    })
                )
                // console.log("getAllWishListSagaSuccess dressingChamSaga :", responce.data.response)
            }else {
                store.dispatch(getAllWishListFailed(responce.data.message))
                toast.error( responce.data.message , {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                },);
                // console.log("getAllWishListFailed",responce.data.status)
            }
        }
    } catch (e) {
        // console.log('GET_TOKEN_WITHOUT_POTION',e)
        // yield put({ type: 'GET_TOKEN_WITHOUT_POTION', message: e.message });
    }
}

function* deleteWishlistSaga({ payload }) {
    try{
        const responce = yield call(deleteWishlistApi , payload)
        // console.log("deleteWishlistApi" , payload)
        if(responce){
            if (responce.data.status === 200) {
                store.dispatch(deleteWishlistSuccess({status: responce.data.status}))
                store.dispatch(getAllWishList())
                // console.log("deleteWishlistSagaSuccess dressingChamSaga :", responce.data.response)
                toast.success( responce.data.message , {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                },);
            }else {
                store.dispatch(deleteWishlistFailed(responce.data.message))
                toast.error( responce.data.message , {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                },);
                // console.log("deleteWishlistFailed",responce.data.status)
            }
        }
    } catch (e) {
        // console.log('GET_TOKEN_WITHOUT_POTION',e)
        // yield put({ type: 'GET_TOKEN_WITHOUT_POTION', message: e.message });
    }
}

function* wishlistSelectedForPrintSaga({ payload }) {
    try{
        const responce = yield call(wishlistSelectedForPrintApi , payload)
        // console.log("wishlistSelectedForPrintApi" , payload)
        if(responce){
            if (responce.data.status === 200) {
                store.dispatch(wishlistSelectedForPrintSuccess({status: responce.data.status, orderDetails:responce.data.response, traitList:responce.data.response.traitList}))
                store.dispatch(getAllWishList())
                // console.log("wishlistSelectedForPrintSagaSuccess dressingChamSaga :", responce.data.response)
                toast.success( responce.data.message , {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                },);
            }else if (responce.data.status === 304) {
                store.dispatch(wishlistSelectedForPrintSuccess({status: responce.data.status, orderDetails:responce.data.response, traitList:responce.data.response.traitList}))
                store.dispatch(getAllWishList())
                // console.log("wishlistSelectedForPrintSagaSuccess dressingChamSaga :", responce.data.response)
                toast.warn( responce.data.message , {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                },);
            }else {
                store.dispatch(wishlistSelectedForPrintFailed(responce.data.message))
                toast.error( responce.data.message , {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                },);
                // console.log("wishlistSelectedForPrintFailed",responce.data.status)
            }
        }
    } catch (e) {
        // console.log('GET_TOKEN_WITHOUT_POTION',e)
        // yield put({ type: 'GET_TOKEN_WITHOUT_POTION', message: e.message });
    }
}

function* afterPaymentSaga({ payload }) {
    try{
        const responce = yield call(afterPaymentApi , payload)
        // console.log("afterPaymentApi" , payload)
        if(responce){
            if (responce.data.status === 200) {
                store.dispatch(afterPaymentSuccess({status: responce.data.status, orderDetail: responce.data.response}))
                // console.log("afterPaymentSagaSuccess dressingChamSaga :", responce.data.response)
                toast.success( responce.data.message , {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                },);
            }else if (responce.data.status === 302) {
                store.dispatch(afterPaymentSuccess({status: responce.data.status, failedMsg: responce.data.message}))
                // console.log("afterPaymentSagaSuccess dressingChamSaga :", responce.data.response)
               /* toast.warning( responce.data.message , {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                },);*/
            }else {
                store.dispatch(afterPaymentFailed(responce.data.message))
                toast.error( responce.data.message , {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                },);
                // console.log("afterPaymentFailed",responce.data.status)
            }
        }
    } catch (e) {
        // console.log('GET_TOKEN_WITHOUT_POTION',e)
        // yield put({ type: 'GET_TOKEN_WITHOUT_POTION', message: e.message });
    }
}

function* deleteOrderSaga({ payload }) {
    try{
        const responce = yield call(deleteOrderApi , payload)
        // console.log("deleteOrderApi" , payload)
        if(responce){
            if (responce.data.status === 200) {
                store.dispatch(deleteOrderSuccess({status: responce.data.status}))
                store.dispatch(getAllWishList())
                // console.log("deleteOrderSagaSuccess dressingChamSaga :", responce.data.response)
                toast.success( responce.data.message , {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                },);
            }else {
                store.dispatch(deleteOrderFailed(responce.data.message))
                toast.error( responce.data.message , {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                },);
                // console.log("deleteOrderFailed",responce.data.status)
            }
        }
    } catch (e) {
        // console.log('GET_TOKEN_WITHOUT_POTION',e)
        // yield put({ type: 'GET_TOKEN_WITHOUT_POTION', message: e.message });
    }
}

function* findAllTraitSaga({ payload }) {
    try{
        const responce = yield call(findAllTraitApi , payload)
        // console.log("findAllTraitApi" , payload)
        if(responce){
            if (responce.data.status === 200) {
                store.dispatch(findAllTraitSuccess({status: responce.data.status, allTraits:responce.data.response}))
                // console.log("findAllTraitSagaSuccess dressingChamSaga :", responce.data.response)
            }else {
                store.dispatch(findAllTraitFailed(responce.data.message))
                toast.error( responce.data.message , {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                },);
                // console.log("findAllTraitFailed",responce.data.status)
            }
        }
    } catch (e) {
        // console.log('GET_TOKEN_WITHOUT_POTION',e)
        // yield put({ type: 'GET_TOKEN_WITHOUT_POTION', message: e.message });
    }
}

function* findAllForDressingRoomSaga({ payload }) {
    try{
        const responce = yield call(findAllForDressingRoomApi , payload)
        // console.log("findAllForDressingRoomApi" , payload)
        if(responce){
            if (responce.data.status === 200) {
                store.dispatch(findAllForDressingRoomSuccess({status: responce.data.status, allTraitsForDressingRoom:responce.data.response}))
                // console.log("findAllForDressingRoomSagaSuccess dressingChamSaga :", responce.data.response)
            }else {
                store.dispatch(findAllForDressingRoomFailed(responce.data.message))
                toast.error( responce.data.message , {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                },);
                // console.log("findAllForDressingRoomFailed",responce.data.status)
            }
        }
    } catch (e) {
        // console.log('GET_TOKEN_WITHOUT_POTION',e)
        // yield put({ type: 'GET_TOKEN_WITHOUT_POTION', message: e.message });
    }
}

function* findAllInventoryTraitTypesSaga({ payload }) {
    try{
        const responce = yield call(findAllInventoryTraitTypesApi , payload)
        // console.log("findAllInventoryTraitTypesApi" , payload)
        if(responce){
            if (responce.data.status === 200) {
                store.dispatch(findAllInventoryTraitTypesSuccess({status: responce.data.status, allInventoryTraitsTypes:responce.data.response}))
                // console.log("findAllInventoryTraitTypesSagaSuccess dressingChamSaga :", responce.data.response)
            }else {
                store.dispatch(findAllInventoryTraitTypesFailed(responce.data.message))
                toast.error( responce.data.message , {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                },);
                // console.log("findAllInventoryTraitTypesFailed",responce.data.status)
            }
        }
    } catch (e) {
        // console.log('GET_TOKEN_WITHOUT_POTION',e)
        // yield put({ type: 'GET_TOKEN_WITHOUT_POTION', message: e.message });
    }
}

function* findAllAvailableInventorySaga({ payload }) {
    try{
        const responce = yield call(findAllAvailableInventoryApi , payload)
        // console.log("findAllAvailableInventoryApi" , payload)
        if(responce){
            if (responce.data.status === 200) {
                store.dispatch(findAllAvailableInventorySuccess({status: responce.data.status, allAvailableInventory:responce.data.response}))
                // console.log("findAllAvailableInventorySagaSuccess dressingChamSaga :", responce.data.response)
            }else {
                store.dispatch(findAllAvailableInventoryFailed(responce.data.message))
                toast.error( responce.data.message , {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                },);
                // console.log("findAllAvailableInventoryFailed",responce.data.status)
            }
        }
    } catch (e) {
        // console.log('GET_TOKEN_WITHOUT_POTION',e)
        // yield put({ type: 'GET_TOKEN_WITHOUT_POTION', message: e.message });
    }
}

function* applyInventorySaga({ payload }) {
    try{
        const responce = yield call(applyInventoryApi , payload)
        // console.log("applyInventoryApi" , payload)
        if(responce){
            if (responce.data.status === 200) {
                store.dispatch(applyInventorySuccess({status: responce.data.status}))
                // console.log("applyInventorySagaSuccess dressingChamSaga :", responce.data.response)
                toast.success( responce.data.message , {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                },);
            }else {
                store.dispatch(applyInventoryFailed({massage:responce.data.message, status: responce.data.status}))
                toast.error( responce.data.message , {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                },);
                // console.log("applyInventoryFailed",responce.data.status)
            }
        }
    } catch (e) {
        // console.log('GET_TOKEN_WITHOUT_POTION',e)
        // yield put({ type: 'GET_TOKEN_WITHOUT_POTION', message: e.message });
    }
}

function* dressingChamSaga() {
    yield takeEvery('FIND_ALL_CHAM_TRAIT_TYPES', findAllChamTraitTypesSaga );
    yield takeEvery('FIND_CHAM_TRAITS_BY_TRAIT_TYPE', findChamTraitsByTraitTypeSaga );
    yield takeEvery('SAVE_PREVIEW_NFT', savePreviewNftSaga );
    yield takeEvery('GET_ALL_PREVIEW_NFT_LIST', getAllPreviewNftListSaga );
    yield takeEvery('GET_ALL_OVERLAY_TYPE', getAllOverlayTypeSaga );
    yield takeEvery('GET_OVERLAY_BY_TYPE', getOverlayByTypeSaga );
    yield takeEvery('GET_ALL_OVERLAY', getAllOverlaySaga );
    yield takeEvery('ADD_TO_WISHLIST', addToWishlistSaga );
    yield takeEvery('GET_ALL_WISHLIST', getAllWishListSaga );
    yield takeEvery('DELETE_WISHLIST', deleteWishlistSaga );
    yield takeEvery('WISHLIST_SELECTED_FOR_PRINT', wishlistSelectedForPrintSaga );
    yield takeEvery('AFTER_PAYMENT', afterPaymentSaga );
    yield takeEvery('DELETE_ORDER', deleteOrderSaga );
    yield takeEvery('FIND_ALL_TRAITS', findAllTraitSaga );
    yield takeEvery('FIND_ALL_FOR_DRESSING_ROOM', findAllForDressingRoomSaga );
    yield takeEvery('FIND_ALL_INVENTORY_TRAIT_TYPES', findAllInventoryTraitTypesSaga );
    yield takeEvery('FIND_ALL_AVAILABLE_INVENTORY', findAllAvailableInventorySaga );
    yield takeEvery('APPLY_INVENTORY', applyInventorySaga );
}

export default dressingChamSaga;
