import React, { useEffect, useState } from 'react'
import { FaEthereum, FaHeart, FaRegHeart } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { addToWishlist, getAllWishList } from '../../redux/actions/dressingCham';
import './DressingPreviewSec.css'
import { useAccount, useDisconnect } from 'wagmi';
import { userLogout } from '../../redux/actions/users';
import { setWalletAddress, setWalletConnected } from '../../redux/actions/miscellaneous';

function DressingPreviewSec({ dressingPreview, nftType, setShowZoomNftPopup, setShowZoomNftPopupId }) {
    let totalPrice = 0;

    const dispatch = useDispatch();

    const { address, isConnected } = useAccount()

    console.log("address :", address)

    const allTraits = useSelector(state => state.dressingCham.allTraits);

    // useEffect(async() => {
    //     setAccountCode(walletAddress);
    //     dispatch(getAllWishList());
    // }, [walletAddress]);

    function contains(a, obj) {
        var i = a.length;
        while (i--) {
            if (a[i].traitId === obj) {
                return true;
            }
        }
        return false;
    }

    const handleAddToWishList = (preview) => {
        let isRelatedTraitPresent = 0; 
        let chamTraitTypeIds = [];
        chamTraitTypeIds = preview.overlays?.map((item) => {return item.chamTraitTypeId})

        let traitError = false;
        console.log("preview?.overlays :", preview?.overlays)
        for (let i = 0; i < preview?.overlays.length; i++) {
            let relatedTraits = allTraits?.filter((trait) => {
                return trait.traitId == preview?.overlays[i].traitId
            })
            console.log("relatedTraits :", relatedTraits, 'i', i)
            console.log("relatedTraits[0]?.relativeTraits :", relatedTraits[0]?.relativeTraits)
            if (traitError) {
                break;
            }
            if (relatedTraits[0]?.relativeTraits.length > 0) {
                isRelatedTraitPresent = 1;
                for (let j = 0; j < relatedTraits[0]?.relativeTraits.length; j++) {
                    if (relatedTraits[0]?.relativeTraits[j].relation == 'AND') {
                        console.log('relatedTraits[0]?.relativeTraits[j].relation :', relatedTraits[0]?.relativeTraits[j].relation)
                        if (contains(preview?.overlays, relatedTraits[0]?.relativeTraits[j].relationWith)) {
                            chamTraitTypeIds = preview.overlays?.map((item) => {
                                return item.chamTraitTypeId
                            })
                            dispatch(addToWishlist({
                                accountCode: address,
                                nftType: nftType,
                                nftId: preview.nft.tokenId,
                                chamTraitTypeIds: chamTraitTypeIds
                            }))
                            traitError = true;
                        } else {
                            let relatedName = allTraits?.filter((trait) => {
                                return trait.traitId == relatedTraits[0]?.relativeTraits[j].relationWith
                            })[0].trait
                            // Commented becase of kevin ::: remove AND on the dressing room page so it’s easier to buy traits
                          /*  toast.warning('Please select '+relatedName+" to proceed", {
                                position: "bottom-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: false,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            })*/
                            dispatch(addToWishlist({
                                accountCode: address,
                                nftType: nftType,
                                nftId: preview.nft.tokenId,
                                chamTraitTypeIds: chamTraitTypeIds
                            }))
                            traitError = true;
                            break;
                        }
                    } else
                        if (relatedTraits[0]?.relativeTraits[j].relation == 'OR') {
                        // todo check for OR
                        if (contains(preview?.overlays, relatedTraits[0]?.relativeTraits[j].relationWith)) {
                            let relatedName = relatedTraits[0].trait
                            let relatedName1 = allTraits?.filter((trait) => {
                                return trait.traitId == relatedTraits[0]?.relativeTraits[j].relationWith
                            })[0].trait
                            toast.warning('Please remove '+relatedName+" or "+relatedName1+" to proceed", {
                                position: "bottom-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: false,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            })
                            traitError = true;
                            break;
                        } else {
                            chamTraitTypeIds = preview.overlays?.map((item) => {
                                return item.chamTraitTypeId
                            })
                            dispatch(addToWishlist({
                                accountCode: address,
                                nftType: nftType,
                                nftId: preview.nft.tokenId,
                                chamTraitTypeIds: chamTraitTypeIds
                            }))
                            traitError = true;
                        }
                    }
                }
            }else {
                isRelatedTraitPresent = 0;
            }
        }
        console.log("isRelatedTraitPresent :", isRelatedTraitPresent)
        if(!isRelatedTraitPresent){
            dispatch(addToWishlist({
                accountCode: address,
                nftType: nftType,
                nftId: preview.nft.tokenId,
                chamTraitTypeIds: chamTraitTypeIds
            }))
        }
    }

    const { disconnect } = useDisconnect();
            
    const disconnectWC = () => {
        disconnect()
        dispatch(setWalletConnected({isWalletConnected: false}));
        dispatch(setWalletAddress({walletAddress: ''}));
    }

    useEffect(() => {
        if(!address){
            disconnectWC();
            dispatch(userLogout());
        }
    },[address]);

    return (
        <div className="row cham-overlay-preview px-2">
            {dressingPreview.length ? 
                dressingPreview?.map((preview, i) => {
                    return(
                        <div className="col-3 p-0" key={i}>
                            <div 
                                className="dressing-preview-section-cham mb-2 position-relative w-100 cursor-pointer px-1"
                            >
                                <div 
                                    className="dressing-cham-preview position-relative w-100" 
                                    onClick={() => {setShowZoomNftPopup(true);setShowZoomNftPopupId(i+1)}}
                                >
                                    {preview.nft.attributes.length ?
                                        preview.nft.attributes?.map((traitLayer, i) => {
                                            totalPrice = preview.overlays.reduce(function(prev, current) {
                                                return prev + +current.price
                                            }, 0);
                                            return (
                                                <>
                                                    {/* fixed bg layer for proper grid view */}
                                                    {(i === 0) ? 
                                                        <div className={`dressing-cham-preview-layered-bg`}>
                                                            <img src={traitLayer.url && traitLayer.url} alt="" className="w-100" />
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                    <div className={`dressing-cham-preview-layered position-absolute w-100 ${"dressing-cham-"+(traitLayer.traitType).split(' ').join('_')}`}>
                                                        <img src={traitLayer.url && traitLayer.url} alt="" className="w-100" />
                                                    </div>
                                                </>
                                            )
                                        })
                                        :
                                        null
                                    }
                                    
                                </div>
                                <div 
                                    className="add-to-wishlist-icon position-absolute cursor-pointer"
                                    onClick={() => {
                                        handleAddToWishList(preview)
                                    }}
                                >
                                    <FaRegHeart color='red' size={22}/>
                                    <span className="tooltiptext">Add to Wishlist</span>
                                </div>
                                {preview.overlays.length ?
                                    preview.overlays?.map((overlay, i) => {
                                        return(
                                            <div 
                                                key={i} 
                                                className={`dressing-preview-section-overlay position-absolute dressing-cham-Overlay p-2 ${"dressing-cham-"+(overlay.trait).split(' ').join('_')}`}
                                                onClick={() => {setShowZoomNftPopup(true);setShowZoomNftPopupId(i+1)}}
                                            >
                                                <img 
                                                    src={overlay.imageUrl && overlay.imageUrl} 
                                                    alt="" 
                                                    className="w-100"
                                                />
                                            </div>
                                        )
                                    })
                                    :
                                    null
                                }
                                <span className='dressing-preview-price font-sm'>
                                    <FaEthereum size={14} className='mb-1'/>
                                    {Number(totalPrice.toFixed(5))} 
                                </span>
                            </div>
                        </div>
                    )
                })
                :
                null
            }
        </div>
    )
}

export default DressingPreviewSec
