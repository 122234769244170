import React, { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import AppLayoutPrimary from '../../components/AppLayoutPrimary/AppLayoutPrimary';
import Twitter from '../../assets/Img/Twitter.png';
import Instagram from '../../assets/Img/Insta.png';
import EditIcon from '../../assets/Img/edit-icon.png';
import LinkIcon from '../../assets/Img/link-icon.png';
import { resetStatus, updateProfile, uploadBannerImage, uploadProfileImage } from '../../redux/actions/users';
import { FadeLoader, PulseLoader, } from "react-spinners";
import './EditProfile.css';

function EditProfile({ accountNo, setAccountNo, userId, setUserId, setActivePage }) {
    const [profilePic] = useState('img/profile-pic.png');
    const [BgImgUrl] = useState('img/bg-1.jpg');
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [discordName, setDiscordName] = useState('');
    const [email, setEmail] = useState('');
    const [socialMediaLinks, setSocialMediaLinks] = useState([]);
    const [ethAddresses, setEthAddresses] = useState([]);
    const [uploadImg, setUploadImg] = useState('');
    const [uploadBnrImg, setUploadBnrImg] = useState('');
    const [userNameReqWarnMsg, setUserNameReqWarnMsg] = useState('');
    const [passwordWarnMsg, setPasswordWarnMsg] = useState('');
    const [passwordReqWarnMsg, setPasswordReqWarnMsg] = useState('');
    const [emailReqWarnMsg, setEmailReqWarnMsg] = useState('');
    const [emailInvadidWarnMsg, setEmailInvadidWarnMsg] = useState('');

    const dispatch = useDispatch();
    const user = useSelector(state => state.users.user);
    const loading = useSelector(state => state.users.loading);
    const uploadingProfile = useSelector(state => state.users.uploadingProfile);
    const uploadingProfilePic = useSelector(state => state.users.uploadingProfilePic);
    const uploadProfileStatus  = useSelector(state => state.users.uploadProfileStatus);
    const error = useSelector(state => state.users.error);

    const updateFieldChanged = index => e => {
        let newArr = [...socialMediaLinks]; // copying the old datas array
        newArr[index]['socialMediaLink'] = e.target.value; // replace e.target.value with whatever you want to change it to
        setSocialMediaLinks(newArr); // ??
    }

    const updateFieldChanged2 = index => e => {
        let newArr = [...ethAddresses]; // copying the old datas array
        newArr[index]['accountCode'] = e.target.value; // replace e.target.value with whatever you want to change it to
        setEthAddresses(newArr); // ??
    }

    const history = useHistory();

    useEffect(() => {
        setUserName(user.userName ? user.userName : '');
        setDiscordName(user.discordName ? user.discordName : '');
        setEmail(user.email ? user.email : '');
        setSocialMediaLinks(user.userSocialMediaLink);
        setEthAddresses(user.accounts)
    }, [userId]);

    const [preview, setPreview] = useState(profilePic)
    useEffect(() => {
        if (!uploadImg) {
            setPreview(undefined)
            return
        }
        const objectUrl = URL.createObjectURL(uploadImg)
        setPreview(objectUrl)
        return () => URL.revokeObjectURL(objectUrl)
    }, [uploadImg]);

    useEffect(() => {
        {uploadImg && dispatch(uploadProfileImage({ file: uploadImg, userId: userId }));}
    }, [uploadImg]);


    const [previewBnr, setPreviewBnr] = useState(BgImgUrl)
    useEffect(() => {
        if (!uploadBnrImg) {
            setPreviewBnr(undefined)
            return
        }
        const objectUrl = URL.createObjectURL(uploadBnrImg)
        setPreviewBnr(objectUrl)
        return () => URL.revokeObjectURL(objectUrl)
    }, [uploadBnrImg]);

    useEffect(() => {
        {uploadBnrImg && dispatch(uploadBannerImage({ file: uploadBnrImg, userId: userId }));}
    }, [uploadBnrImg]);

    useEffect(() => {
        if(!userName) {
            setUserNameReqWarnMsg("* User Name Required")
        } else {
            setUserNameReqWarnMsg("")
        }
        if(!email) {
            setEmailReqWarnMsg("* Email Required")
        } else if (new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(email) === false) {
            setEmailReqWarnMsg('')
            setEmailInvadidWarnMsg('* Invalid email format');
        }else {
            setEmailReqWarnMsg('')
            setEmailInvadidWarnMsg('')
        }
        if(!password) {
            setPasswordReqWarnMsg("* Password Required")
        } else {
            setPasswordReqWarnMsg("")
        }
    }, [ userName, email, password ]);

    useEffect(() => {
        if(uploadProfileStatus == 200) {
            history.push('/profile')
        }
    }, [uploadProfileStatus]);

    useEffect(() => {
        dispatch(resetStatus())
        return () => {
            dispatch(resetStatus())
        }
    }, []);

    useEffect(() => {
        if(password === confirmPassword){
            setPasswordWarnMsg('')
        }else {
            setPasswordWarnMsg('* Password mismatched')
        }
    }, [password, confirmPassword]);

    useEffect(() => {
        setActivePage('edit-profile');
        return () => {
            setActivePage('');
        }
    }, []);
       
    return (
        <AppLayoutPrimary 
            setUserId={setUserId}
            setAccountNo={setAccountNo}
            canEditProfile
            setUploadBnrImg={setUploadBnrImg}
            uploadBnrImg={uploadBnrImg}
            BgImgUrl={BgImgUrl}
            previewBnr={previewBnr}
            accountNo={accountNo}
            content= {
                <Fragment>
                     {loading && <div className="p-5"><FadeLoader loading={loading} color='#01BFD9'/></div>}        
                    {(user && !loading) ?
                        <div className='edit_Profile d-flex flex-column justify-content-center position-relative text-secondary'>
                            <div className="edit_profile_img position-absolute">
                                {(uploadingProfilePic && <div className="loaderDiv position-absolute"><PulseLoader loading={uploadingProfilePic} color="#7B0083"/></div>)}
                                {uploadImg ? <img src={preview} /> : (user.profileImage) ? <img src={user.profileImage} alt="profile-img" /> : <img src={profilePic} alt="profile-img" />}
                            </div>
                            <span className="edit_userimg_pointer position-absolute">
                                <input type="file" id='profilePic' className="profile_upload" onChange={(e) => {setUploadImg(e.target.files[0])}}/>
                                <label htmlFor="profilePic">
                                    <img src={EditIcon} alt="" className="" />
                                </label>
                            </span>
                            <div className="form_1 d-flex flex-column w-100">
                                <label className='edit_profile_label' htmlFor="username">User Name</label>
                                <input type="text" name='username' className={`${userNameReqWarnMsg ? "border_warn" : ""}`} placeholder='User Name' value={userName} onChange={(e) => setUserName(e.target.value)} required/>
                                    {userNameReqWarnMsg ? <span className='required_warn d-flex justify-content-end text-danger'>{userNameReqWarnMsg}</span>:null}
                                <label className='edit_profile_label' htmlFor="password">Password</label>
                                <input type="password" name='password' placeholder='Password' value={password} onChange={(e) => setPassword(e.target.value)} />
                                    {/* {<span className='required_warn d-flex justify-content-end text-info'>* set/change password</span>} */}
                                <label className='edit_profile_label' htmlFor="confirmPassword">Confirm Password</label>
                                <input type="password" name='confirmPassword' className={`${passwordWarnMsg ? "border_warn" : ""}`} placeholder='Confirm Password' value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                                    {passwordWarnMsg ? <span className='required_warn d-flex justify-content-end text-danger'>{passwordWarnMsg}</span> : null}
                                <label className='edit_profile_label' htmlFor="discord">Discord Name</label>
                                <input type="text" className="" name='discord' placeholder='Discord Name' value={discordName} onChange={(e) => setDiscordName(e.target.value)}/>
                                <label className='edit_profile_label' htmlFor="email">Email</label>
                                <input type="email" name='email' className={`${(emailReqWarnMsg || emailInvadidWarnMsg) ? "border_warn" : ""}`} placeholder='Email' value={email} onChange={(e) => setEmail(e.target.value)} required/>
                                    {emailReqWarnMsg ? 
                                        <span className='required_warn d-flex justify-content-end text-danger'>{emailReqWarnMsg}</span> 
                                        : (emailInvadidWarnMsg) ? 
                                        <span className='required_warn d-flex justify-content-end text-danger'>{emailInvadidWarnMsg}</span> 
                                        :
                                        null
                                    }
                            </div>
                            <div className="form_2 d-flex flex-column w-100 position-relative">
                                <span className="text-start">Social Media Link</span>
                                {(socialMediaLinks && socialMediaLinks.length) ?
                                    socialMediaLinks.map((acc, index) => {
                                        return(
                                            <Fragment key={index}>
                                                <input type="text" placeholder={acc.socialMediaName.toLowerCase()} value={acc.socialMediaLink} onChange={updateFieldChanged(index)}/>
                                                <span className={`${acc.socialMediaName} ${"position-absolute"} `}>
                                                    <img src={ (acc.socialMediaName === "INSTAGRAM") ? Instagram : Twitter } alt={`${acc.socialMediaName}'-icon'`} className="" />
                                                </span>
                                            </Fragment>
                                        );
                                    })
                                    :
                                    null
                                }
                            </div>
                            <div className="form_3 d-flex flex-column w-100 ">
                                <header className="text-start">Link Ethereum Address</header>
                                {(ethAddresses && ethAddresses.length) ?
                                    ethAddresses.map((acc, index) => {
                                        return(
                                            <div key={index} className="position-relative w-100">
                                                <input type="text" className={`${(index < 1) ? "mt-4" : ""}`} placeholder='Ethereum Address' value={acc.accountCode} onChange={updateFieldChanged2(index)}/>
                                                <span className={`${(index < 1) ? "first_eth_link_icon position-absolute" : "eth_link_icon position-absolute"}`}>
                                                    <img src={LinkIcon} alt="link-icon" className="" />
                                                </span>
                                            </div>
                                        )
                                    })
                                    :
                                    null
                                }
                               {/* <span
                                    className="add_btn d-flex w-100 justify-content-end" 
                                    onClick={() => {
                                        let newArr = [...ethAddresses]; // copying the old datas array
                                        newArr[newArr.length] = { "accountCode": '' }; // replace e.target.value with whatever you want to change it to
                                        setEthAddresses(newArr);
                                    }}
                                >
                                    + Add
                                </span>*/}
                            </div>
                            {(userName && email && !uploadingProfile) ?
                                <button 
                                    className="edit_profile_btn w-100" 
                                    onClick={() => {
                                        if (new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(email)) {
                                            setEmailInvadidWarnMsg('');
                                            dispatch(
                                                updateProfile({
                                                    userNewData:{
                                                        userId: userId,
                                                        username: userName,
                                                        password: password,
                                                        discordName: discordName,
                                                        email: email,
                                                        links: socialMediaLinks,
                                                        accounts: ethAddresses,
                                                    },
                                                    accountNo: accountNo,
                                                }),
                                            );
                                        }else {
                                            setEmailInvadidWarnMsg('* Invalid email format')
                                        }
                                    }}
                                >
                                    Save
                                </button>
                                : (uploadingProfile) ?
                                <button className="edit_profile_btn w-100">
                                    <PulseLoader loading={uploadingProfile} color='#c3dd05' />
                                </button>
                                :
                                <button className="edit_profile_btn w-100">
                                    Save
                                </button>
                            }
                        </div>
                        :
                        null
                    }
                </Fragment>
            }
        />
    )
}

export default EditProfile
