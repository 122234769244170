import React from 'react';
import Opensea from '../../assets/Img/ftr_opensea.png';
import { FaDiscord, FaInstagram, FaRegCopyright, FaTwitter,  } from "react-icons/fa";
import './Footer.css';

function Footer({ fullWidth }) {
    return (
        <div className={fullWidth ? "footer w-100" : "app_container footer w-100"}>
            <div className="row mx-0 ma-sm-4" >
                <div className="col-12 col-sm-6 footer_text">
                        <span><FaRegCopyright size={30}/></span> 
                        2021   The   Chameleon   Collective
                </div>
                <div className="col-12 col-sm-6 d-flex justify-content-end">
                    <a href="https://opensea.io/collection/chameleon-collective" target="_blank">
                        <img className="footer_icon mt-2 mt-md-0" src={Opensea} />
                    </a>
                    <a href="https://t.co/7yjP8YT5Eb?amp=1" target="_blank">
                        <FaDiscord size={50} color={"#fff"} className="footer_icon"/>
                    </a>
                    <a href="https://twitter.com/Chameleon_NFT" target="_blank">
                        <FaTwitter size={50} color={"#fff"} className="footer_icon" />
                    </a>
                    <a className="last_icon" href="https://www.instagram.com/chameleon_nft/" target="_blank">
                        <FaInstagram size={50} color={"#fff"} className="footer_icon" />
                    </a> 
                </div>
                
            </div>
        </div>
    )
}

export default Footer
