import * as type from '../types';

export function setWalletConnected(payload) {
    return {
        type: type.SET_WALLET_CONNECTED,
        payload,
    }
}

export function setWalletAddress(payload) {
    return {
        type: type.SET_WALLET_ADDRESS,
        payload,
    }
}
