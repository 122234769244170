import * as type from '../types';

const initialState = {
    loginStatus: '',
    getTokenByUserStatus: '',
    loginByUserNameStatus: null,
    status: '',
    user: {},
    loading: false,
    error: null,
    chameleonNft: [],
    chameleonTraits: [],
    successMsg: '',
    uploadingProfile: false,
    uploadProfileStatus: null,
    uploadingProfilePic: false,
    uploadingBg: false,
}

export default function user(state = initialState, action) {
    switch (action.type) {
        case type.LOGIN_USER_BY_ADDRESS:
            return {
                ...state,
                loading: true,
            }
        case type.LOGIN_USER_BY_ADDRESS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
            }
        case type.LOGIN_USER_BY_ADDRESS_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        case type.LOGIN_USER_BY_USERNAME:
            return {
                ...state,
                loading: true,
            }
        case type.LOGIN_USER_BY_USERNAME_SUCCESS:
            return {
                ...state,
                loading: false,
                loginByUserNameStatus: action.payload.status,
            }
        case type.LOGIN_USER_BY_USERNAME_FAILED:
            return {
                ...state,
                loading: false,
                loginByUserNameStatus: action.payload.status,
                error: action.payload.message,
            }
        case type.GET_USER_DETAIL_BY_ADDRESS:
            return {
                ...state,
                loading: true,
            }
        case type.GET_USER_DETAIL_BY_ADDRESS_SUCCESS:
            return {
                ...state,
                loading: false,
                user: action.payload.userDetails,
                loginStatus: action.payload.status,
            }
        case type.GET_USER_DETAIL_BY_ADDRESS_FAILED:
            return {
                ...state,
                loading: false,
                loginStatus: action.payload.status,
                error: action.payload.message,
            }
        case type.GET_TOKEN_BY_USER:
            return {
                ...state,
                loading: true,
            }
        case type.GET_TOKEN_BY_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                status: action.payload.status,
                getTokenByUserStatus: action.payload.status,
                chameleonNft: action.payload.chameleonNft,
                chameleonTraits: action.payload.chameleonTraits,
            }
        case type.GET_TOKEN_BY_USER_FAILED:
            return {
                ...state,
                loading: false,
                getTokenByUserStatus: action.payload.status,
                error: action.payload.msg,
            }
        case type.UPDATE_PROFILE:
            return {
                ...state,
                uploadingProfile: true,
            }
        case type.UPDATE_PROFILE_SUCCESS:
            return {
                ...state,
                uploadingProfile: false,
                uploadProfileStatus: action.payload.status,
            }
        case type.UPDATE_PROFILE_FAILED:
            return {
                ...state,
                uploadingProfile: false,
                error: action.payload,
            }
        case type.UPLOAD_PROFILE_IMAGE:
            return {
                ...state,
                uploadingProfilePic: true,
            }
        case type.UPLOAD_PROFILE_IMAGE_SUCCESS:
            return {
                ...state,
                uploadingProfilePic: false,
                successMsg: action.payload,
            }
        case type.UPLOAD_PROFILE_IMAGE_FAILED:
            return {
                ...state,
                uploadingProfilePic: false,
                error: action.payload,
            }
        case type.UPLOAD_BANNER_IMAGE:
            return {
                ...state,
                uploadingBg: true,
            }
        case type.UPLOAD_BANNER_IMAGE_SUCCESS:
            return {
                ...state,
                uploadingBg: false,
                successMsg: action.payload,
            }
        case type.UPLOAD_BANNER_IMAGE_FAILED:
            return {
                ...state,
                uploadingBg: false,
                error: action.payload,
            }
        case type.USER_LOGOUT:
            return {
                ...state,
            }
        case type.RESET_STORE_STATUS:
            return {
                ...state,
                uploadProfileStatus: null,
                status: null,
                loginByUserNameStatus: null,
                error: '',
            }
        default:
            return state;
    }
}