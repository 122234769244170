import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FaDiscord } from 'react-icons/fa';
import { FcFaq } from "react-icons/fc";
import { HiShoppingCart } from "react-icons/hi";
import { IoMenu } from 'react-icons/io5';
import { userLogout } from '../../redux/actions/users';
import { PulseLoader, } from "react-spinners";
import { isTokenAltered, resetPotion } from '../../redux/actions/potion';
import OpenseaIconDark from '../../assets/Img/opensea-icon-dark.png';
import Logo from '../../assets/Img/logo-cham.png';
import SideNav from './SideNav/SideNav';
import './Navbar.css';
import { setWalletAddress, setWalletConnected } from '../../redux/actions/miscellaneous';
import { useAccount, useDisconnect } from 'wagmi';

function Navbar({ isSignedUp, setAccountNo, setUserId, activePage, setActivePage, setIsAGuestUser }) {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [isTokenStatusModalOpen, setIsTokenStatusModalOpen] = useState(false);
    const [tokenForStatusSearch, setTokenForStatusSearch] = useState();
    const [profilePic] = useState('img/profile-pic.png');

    const history = useHistory();
    const dispatch = useDispatch();

    const nodeRefs = {node: useRef(null), SideNavNode: useRef(null)}

    const onClickAway = (e) => {
        if(nodeRefs.node.current.contains(e.target)){
            return;
        }else {
            setIsDropdownOpen(false);
        }
        if(nodeRefs.SideNavNode.current.contains(e.target)){
            return ;
        }else {
            setIsSidebarOpen(false); 
        }
    } 
  
    useEffect(() => {
        document.addEventListener("mousedown", onClickAway);
        return () => {
            document.removeEventListener("mousedown", onClickAway);
        };
    }, []);
    
    const status = useSelector(state => state.users.loginStatus);
    const user = useSelector(state => state.users.user);
    const TokenStatusMsgLoading = useSelector(state => state.potion.TokenStatusMsgLoading);
    const TokenStatusMsg = useSelector(state => state.potion.TokenStatusMsg);

    // useEffect(() => {
    //     if(status !== 200) {
    //         history.push('/signup');
    //     }
    // }, [status]);

    function openInNewTab(url) {
        const win = window.open(url, '_blank');
        if (win != null) {
          win.focus();
        }
    }

    const { address, isConnected } = useAccount();
    const { disconnect } = useDisconnect();
            
    const disconnectWC = () => {
        disconnect()
        dispatch(setWalletConnected({isWalletConnected: false}));
        dispatch(setWalletAddress({walletAddress: ''}));
    }

    return (
        <Fragment>
            <div className="navbar position-sticky top-0">
                <div className="w-100 d-flex justify-content-between align-items-center position-relative">
                    <div className="nav_logo" onClick={() => {history.push('/');setActivePage('')}}>
                        <img src={Logo} alt="" />
                    </div>
                    <div className="d-flex justify-content-center align-items-center">
                        <div className="d-flex justify-content-center align-items-center">
                            {/*<div
                                className={(activePage === 'cham-creation') ? "active_nav nav_link cursor-pointer mx-3 d-none d-xl-flex" : "cursor-pointer mx-3 d-none d-xl-flex nav_link"} 
                                onClick={() => {
                                    history.push('/cham-creation');
                                    setActivePage('cham-creation');
                                }}
                            >
                                <b>Create Chameleon</b>
                            </div>*/}
                            <div
                                className={(activePage === 'dressing-room-cham') ? "active_nav nav_link cursor-pointer mx-3 d-none d-xl-flex" : "cursor-pointer mx-3 d-none d-xl-flex nav_link"}
                                onClick={() => {
                                    history.push('/dressing-room-cham');
                                    setActivePage('dressing-room-cham');
                                }}
                            >
                                <b>Dressing Room</b>
                            </div>
                            <div
                                className={(activePage === 'inventory') ? "active_nav nav_link cursor-pointer mx-3 d-none d-xl-flex" : "cursor-pointer mx-3 d-none d-xl-flex nav_link"}
                                onClick={() => {
                                    history.push('/inventory');
                                    setActivePage('inventory');
                                }}
                            >
                                <b>Inventory</b>
                            </div>
                            {/* <a 
                                className={(activePage === 'merch') ? "active_nav nav_link cursor-pointer mx-3 d-none d-lg-flex" : "cursor-pointer mx-3 d-none d-lg-flex nav_link"} 
                                onClick={() => {setActivePage('merch')}}
                                href='https://chameleon-collective.square.site/' 
                                target='_blank'
                            >
                                <b>Merch</b>
                            </a> */}
                            <div 
                                className={(activePage === 'chamagotchi') ? "active_nav nav_link cursor-pointer mx-3 d-none d-lg-flex" : "cursor-pointer mx-3 d-none d-lg-flex nav_link"} 
                                onClick={() => {
                                    history.push('/chamagotchi');
                                    setActivePage('chamagotchi');
                                }}
                            >
                                <b>Chamagotchi</b>
                            </div>
                            {/* <div 
                                className={(activePage === 'Token status') ? "active_nav nav_link cursor-pointer mx-3 d-none d-md-flex" : "cursor-pointer mx-3 d-none d-md-flex nav_link"} 
                                onClick={() => {
                                    // history.push('/Token status');
                                    setIsTokenStatusModalOpen(true);
                                    setActivePage('Token status');
                                    dispatch(resetPotion());
                                }}
                            >
                                <b>Token status</b>
                            </div> */}
                            {/* create Chamagotchi navbar btn */}
                            {/* <div 
                                className={(activePage === 'design-your-pet') ? "active_nav nav_link cursor-pointer mx-3 d-none d-md-flex" : "cursor-pointer mx-3 d-none d-md-flex nav_link"}  
                                onClick={() => {
                                    history.push('/design-your-pet');
                                    setActivePage('design-your-pet');
                                }}
                            >
                                <b>Design</b>
                            </div> */}
                            {/* <div 
                                className={(activePage === 'faq') ? "active_nav nav_link cursor-pointer mx-3 d-none d-md-flex mr-5" : "cursor-pointer mx-3 d-none d-md-flex mr-5 nav_link"}  
                                onClick={() => {
                                    history.push('/faq');
                                    setActivePage('faq');
                                }}
                            >
                                <b>FAQ</b>
                            </div> */}
                            <div 
                                className={(activePage === 'potions') ? "active_nav nav_link cursor-pointer mx-3 d-none d-md-flex mr-5" : "cursor-pointer mx-3 d-none d-md-flex mr-5 nav_link"}  
                                onClick={() => {
                                    history.push('/potions-cham');
                                    setActivePage('potions');
                                }}
                            >
                                <b>Potions</b>
                            </div>
                            <a href="/wishlist" className="nav_icons d-none d-md-flex">
                                <HiShoppingCart size={28} color='#000'/>
                            </a>
                            <a href="https://t.co/7yjP8YT5Eb?amp=1" title="Discord" target="_blank" className="nav_icons d-none d-md-flex">
                                <FaDiscord size={30} color="#000"/>
                            </a>
                            <a href="https://opensea.io/collection/chameleon-collective" target="_blank" className="nav_icons d-none d-md-flex mr-4">
                                <img src={OpenseaIconDark} alt="" />
                            </a>
                        </div>
                        {(isSignedUp) ? 
                            <div ref={nodeRefs.node} className="profile_icon position-relative">
                                {(user.profileImage) ?
                                    <img src={user.profileImage} alt="" className="" onClick={() => setIsDropdownOpen(!isDropdownOpen)}/>
                                    :
                                    <img src={profilePic} alt="" className="" onClick={() => setIsDropdownOpen(!isDropdownOpen)}/>
                                }
                                <div className={`${(isDropdownOpen) ? "profile_icon_dropdown bg-light mt-2 rounded-3 d-flex flex-column align-items-start justify-content-center position-absolute" : "d-none"}`}>
                                    <div className="profile_icon_dropdown_icon  m-1" onClick={() => {history.push('/dashboard');setIsDropdownOpen(false);setActivePage('dashboard')}}>
                                        <img src='img/dashboard-icon.png' alt="dashboard-icon.png" className='dropdown_svg_icon'/>
                                        <span>Dashboard</span>
                                    </div>
                                    {/* <div className="profile_icon_dropdown_icon d-flex d-md-none m-1" onClick={() => {history.push('design-your-pet/');setIsDropdownOpen(false);setActivePage('design-your-pet')}}>
                                        <img src='img/create-icon.png' alt="create-icon.png" className='dropdown_svg_icon'/>
                                        <span>Design</span>
                                    </div> */}
                                    <div className="profile_icon_dropdown_icon d-flex d-md-none m-1" onClick={() => {history.push('/wishlist');setIsDropdownOpen(false);setActivePage('wishlist')}}>
                                        <img src='img/wishlist-icon.png' alt="wishlist-icon.png" className='dropdown_svg_icon'/>
                                        <span>Wishlist</span>
                                    </div>
                                    <div className="profile_icon_dropdown_icon m-1" onClick={() => {history.push('/inventory');setIsDropdownOpen(false);setActivePage('inventory')}}>
                                        <img src='img/packing-icon.png' alt="packing-icon.png" className='dropdown_svg_icon'/>
                                        <span>Inventory</span>
                                    </div>
                                    <div className="profile_icon_dropdown_icon d-flex d-lg-none m-1" onClick={() => {history.push('/chamagotchi');setIsDropdownOpen(false);setActivePage('chamagotchi')}}>
                                        <img src='img/nft-icon.png' alt="nft-icon.png" className='dropdown_svg_icon'/>
                                        <span>Chamagotchi</span>
                                    </div>
                                    {/* <a href='https://chameleon-collective.square.site/' target='_blank' className="profile_icon_dropdown_icon d-flex d-lg-none m-1" onClick={() => {setIsDropdownOpen(false);setActivePage('merch')}}>
                                        <img src='img/merch-icon.png' alt="merch-icon.png" className='dropdown_svg_icon'/>
                                        <span>Merch</span>
                                    </a> */}
                                    <div className="profile_icon_dropdown_icon d-flex m-1" onClick={() => {history.push('/potions-cham');setIsDropdownOpen(false);setActivePage('potions')}}>
                                        <img src='img/potion-icon.png' alt="potion-icon.png" className='dropdown_svg_icon'/>
                                        <span>Potions</span>
                                    </div>
                                    <div className="profile_icon_dropdown_icon d-flex d-xl-none m-1" onClick={() => {history.push('/cham-creation');setIsDropdownOpen(false);setActivePage('cham-creation')}}>
                                        <img src='img/create-icon.png' alt="potion-icon.png" className='dropdown_svg_icon'/>
                                        <span>Create Cham</span>
                                    </div>
                                    <div className="profile_icon_dropdown_icon m-1" onClick={() => {setIsTokenStatusModalOpen(true);dispatch(resetPotion());setIsDropdownOpen(false);setActivePage('Token status')}}>
                                        <img src='img/leaderboard-icon.png' alt="leaderboard-icon.png" className='dropdown_svg_icon'/>
                                        <span>Token status</span>
                                    </div>
                                    <hr className="dropdown_hr m-1" />
                                    <div className="profile_icon_dropdown_icon d-flex m-1" onClick={() => {history.push('/profile');setIsDropdownOpen(false);setActivePage('profile')}}>
                                        <img src='img/chameleon-icon.png' alt="chameleon-icon.png" className='dropdown_svg_icon'/>
                                        <span>Profile</span>
                                    </div>
                                    <div className="profile_icon_dropdown_icon d-flex m-1" onClick={() => {history.push('/edit-profile');setIsDropdownOpen(false);setActivePage('edit-profile')}}>
                                        <img src='img/edit-profile-icon.png' alt="edit-profile-icon.png" className='dropdown_svg_icon'/>
                                        <span>Edit Profile</span>
                                    </div>
                                    <div 
                                        className="profile_icon_dropdown_icon d-flex m-1" 
                                        onClick={() => {
                                            disconnectWC();
                                            setAccountNo(null);
                                            setUserId(null);
                                            setIsDropdownOpen(false);
                                            setActivePage('');
                                            dispatch(userLogout());
                                        }}
                                    >
                                        <img src='img/signout-icon.png' alt="signout-icon.png" className='dropdown_svg_icon'/>
                                        <span>Sign Out</span>
                                    </div>
                                    <hr className="dropdown_hr m-1 d-flex d-md-none" />
                                    <div className="profile_icon_dropdown_icon d-flex d-md-none m-1" onClick={() => {openInNewTab('https://t.co/7yjP8YT5Eb?amp=1');setIsDropdownOpen(false);setActivePage('')}}>
                                        <img src='img/discord-icon.svg' alt="discord-icon.svg" className='dropdown_svg_icon'/>
                                        <span>Discord</span>
                                    </div>
                                    <div className="profile_icon_dropdown_icon d-flex d-md-none m-1" onClick={() => {openInNewTab('https://opensea.io/collection/chameleon-collective');setIsDropdownOpen(false);setActivePage('')}}>
                                        <img src='img/opensea-icon.svg' alt="opensea-icon.svg" className='dropdown_svg_icon'/>
                                        <span>Opensea</span>
                                    </div>
                                    <div className="profile_icon_dropdown_icon d-flex d-md-none m-1" onClick={() => {history.push('/wishlist');setIsDropdownOpen(false);setActivePage('wishlist')}} >
                                        <HiShoppingCart size={20}/>
                                        <span>WishList</span>
                                    </div>
                                    <div className="profile_icon_dropdown_icon d-flex m-1" onClick={() => {history.push('/faq');setIsDropdownOpen(false);setActivePage('faq')}}>
                                        <FcFaq size={20}/>
                                        <span>FAQ</span>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="hamburger_menu" onClick={() => setIsSidebarOpen(true)}>
                                <IoMenu size={50} color={"#000"}/>
                            </div>
                        }
                        <div ref={nodeRefs.SideNavNode} className={`${isSidebarOpen ? "side_nav position-fixed" : "d-none"}`}>
                            <SideNav 
                                setIsSidebarOpen={setIsSidebarOpen} 
                                setIsAGuestUser={setIsAGuestUser}
                                setIsTokenStatusModalOpen={setIsTokenStatusModalOpen}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${isTokenStatusModalOpen ? "potion-mint-modal-wrapper d-flex justify-content-center align-items-center position-fixed" : "d-none"}`}>
            <div className="potion-mint-modal">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLongTitle">Search Token Status</h5>
                        <button 
                            type="button" 
                            className="close" 
                            onClick={() => {
                                dispatch(resetPotion());
                                setTokenForStatusSearch('');
                                setIsTokenStatusModalOpen(false);
                            }}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body p-5">
                        <div className="form-group">
                            <label htmlFor="exampleInput">Token no.</label>
                            <input 
                                type="text" 
                                className="form-control" 
                                id="exampleInput" 
                                placeholder="Enter Token No." 
                                value={tokenForStatusSearch}
                                onChange={(e) => {setTokenForStatusSearch(e.target.value); dispatch(resetPotion());}}
                            />
                            <small id="textHelp" className="form-text text-muted">
                                Verify chameleon alter status
                            </small>
                            {TokenStatusMsg && <h2 className="text-primary">{TokenStatusMsg}</h2>}
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button 
                            type="button" 
                            className="btn btn-secondary" 
                            onClick={() => {
                                dispatch(resetPotion());
                                setTokenForStatusSearch('');
                                setIsTokenStatusModalOpen(false);
                            }}
                        >
                            Close
                        </button>
                        <button 
                            type="button" 
                            className="btn btn-primary" 
                            disabled={!tokenForStatusSearch}
                            onClick={() => {
                                dispatch(isTokenAltered({ token: tokenForStatusSearch}))
                            }}
                        >
                            {TokenStatusMsgLoading ? <PulseLoader loading={TokenStatusMsgLoading} color='#FFF' /> : "Search" }
                        </button>
                    </div>
                </div>
            </div>
        </div>
      </Fragment>
    )
}

export default Navbar
