import * as type from '../types';

export function findByTraitTypeSuccess(payload) {
    return {
        type: type.FIND_BY_TRAITS_TYPE_SUCCESS,
        payload,
    }
}

export function findByTraitType(payload) {
    return {
        type: type.FIND_BY_TRAITS_TYPE,
        payload,
    }
}

export function findByTraitTypeFailed(payload) {
    return {
        type: type.FIND_BY_TRAITS_TYPE_FAILED,
        payload,
    }
}

export function createChamagotchiSuccess(payload) {
    return {
        type: type.CREATE_CHAMAGOTCHI_SUCCESS,
        payload,
    }
}

export function createChamagotchi(payload) {
    return {
        type: type.CREATE_CHAMAGOTCHI,
        payload,
    }
}

export function createChamagotchiFailed(payload) {
    return {
        type: type.CREATE_CHAMAGOTCHI_FAILED,
        payload,
    }
}

export function findChamagotchiByUserSuccess(payload) {
    return {
        type: type.FIND_CHAMAGOTCHI_BY_USER_SUCCESS,
        payload,
    }
}

export function findChamagotchiByUser(payload) {
    return {
        type: type.FIND_CHAMAGOTCHI_BY_USER,
        payload,
    }
}

export function findChamagotchiByUserFailed(payload) {
    return {
        type: type.FIND_CHAMAGOTCHI_BY_USER_FAILED,
        payload,
    }
}
export function createChamagotchiFeedSuccess(payload) {
    return {
        type: type.CREATE_CHAMAGOTCHI_FEED_SUCCESS,
        payload,
    }
}

export function createChamagotchiFeed(payload) {
    return {
        type: type.CREATE_CHAMAGOTCHI_FEED,
        payload,
    }
}

export function createChamagotchiFeedFailed(payload) {
    return {
        type: type.CREATE_CHAMAGOTCHI_FEED_FAILED,
        payload,
    }
}

export function onAfterMintSuccess(payload) {
    return {
        type: type.ON_AFTER_MINT_SUCCESS,
        payload,
    }
}

export function onAfterMint(payload) {
    return {
        type: type.ON_AFTER_MINT,
        payload,
    }
}

export function onAfterMintFailed(payload) {
    return {
        type: type.ON_AFTER_MINT_FAILED,
        payload,
    }
}

export function onAfterairdropSuccess(payload) {
    return {
        type: type.ON_AFTER_AIRDROP_SUCCESS,
        payload,
    }
}

export function onAfterairdrop(payload) {
    return {
        type: type.ON_AFTER_AIRDROP,
        payload,
    }
}

export function onAfterairdropFailed(payload) {
    return {
        type: type.ON_AFTER_AIRDROP_FAILED,
        payload,
    }
}

export function getChamagotchiDetailsByIdSuccess(payload) {
    return {
        type: type.GET_CHAMAGOTCHI_DETAILS_BY_ID_SUCCESS,
        payload,
    }
}

export function getChamagotchiDetailsById(payload) {
    return {
        type: type.GET_CHAMAGOTCHI_DETAILS_BY_ID,
        payload,
    }
}

export function getChamagotchiDetailsByIdFailed(payload) {
    return {
        type: type.GET_CHAMAGOTCHI_DETAILS_BY_ID_FAILED,
        payload,
    }
}

export function getLeaderBoardDataSuccess(payload) {
    return {
        type: type.GET_LEADER_BOARD_DATA_SUCCESS,
        payload,
    }
}

export function getLeaderBoardData(payload) {
    return {
        type: type.GET_LEADER_BOARD_DATA,
        payload,
    }
}

export function getLeaderBoardDataFailed(payload) {
    return {
        type: type.GET_LEADER_BOARD_DATA_FAILED,
        payload,
    }
}

export function getCaptchaSuccess(payload) {
    return {
        type: type.GET_CAPTCHA_SUCCESS,
        payload,
    }
}

export function getCaptcha(payload) {
    return {
        type: type.GET_CAPTCHA,
        payload,
    }
}

export function getCaptchaFailed(payload) {
    return {
        type: type.GET_CAPTCHA_FAILED,
        payload,
    }
}

// For logut
export function resetChamagotchi(payload) {
    return {
        type: type.RESET_CHAMAGOTCHI,
        payload,
    }
}